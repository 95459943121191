import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import DatePicker from "components/form/DatePicker";
import TextInput from "components/form/TextInput";
import { isEqual } from "lodash";
import React from "react";

const AcceptanceTemplateForm = ({
  props,
  initialValues,
  btnLoader,
  paperData,
}) => {
  const { values, errors, handleChange, setFieldValue, handleSubmit } = props;
  const {
    client_name,
    conference_organizer,
    paper_title,
    presentation_type,
    conference_name,
    date,
    location,
    paper_id,
    coAuthor_name,
    session_name,
    relevant_field,
    video_link,
    registration_deadline,
    complete_registration,
    // support_email,
    conference_theme,
    your_organization_name,
    your_contact_information,
    your_website,
    preferences,
  } = values;

  return (
    <div className="row">
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
            error={errors?.client_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Organizer
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_organizer"
            value={conference_organizer}
            onChange={handleChange}
            error={errors?.conference_organizer}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Paper Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="paper_title"
            onChange={handleChange}
            value={paper_title}
            error={errors?.paper_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Presentation Type
        </div>
        <div className="col-lg-6">
          <TextInput
            id="presentation_type"
            onChange={handleChange}
            value={presentation_type}
            error={errors?.presentation_type}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
            error={errors?.conference_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">Date</div>
        <div className="col-lg-6">
          <DatePicker
            id="date"
            onChange={handleChange}
            value={date}
            error={errors?.date}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Location
        </div>
        <div className="col-lg-6">
          <TextInput
            id="location"
            onChange={handleChange}
            value={location}
            error={errors?.location}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Paper ID
        </div>
        <div className="col-lg-6">
          <TextInput
            id="paper_id"
            onChange={handleChange}
            value={paper_id}
            error={errors?.paper_id}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          CoAuthor Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="coAuthor_name"
            onChange={handleChange}
            value={coAuthor_name}
            error={errors?.coAuthor_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Session Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="session_name"
            onChange={handleChange}
            value={session_name}
            error={errors?.session_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Relevant Field
        </div>
        <div className="col-lg-6">
          <TextInput
            id="relevant_field"
            onChange={handleChange}
            value={relevant_field}
            error={errors?.relevant_field}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Video Link & Infographics
        </div>
        <div className="col-lg-6">
          <TextInput
            id="video_link"
            onChange={handleChange}
            value={video_link}
            error={errors?.video_link}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Registration Deadline
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="registration_deadline"
            onChange={handleChange}
            value={registration_deadline}
            error={errors?.registration_deadline}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Complete Registration
        </div>
        <div className="col-lg-6">
          <TextInput
            id="complete_registration"
            onChange={handleChange}
            value={complete_registration}
            error={errors?.complete_registration}
          />
        </div>
      </div>

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Support Email
        </div>
        <div className="col-lg-6">
          <TextInput
            id="support_email"
            onChange={handleChange}
            value={support_email}
            error={errors?.support_email}
          />
        </div>
      </div> */}

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Theme
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_theme"
            onChange={handleChange}
            value={conference_theme}
            error={errors?.conference_theme}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Organization’s Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_organization_name"
            onChange={handleChange}
            value={your_organization_name}
            error={errors?.your_organization_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
            error={errors?.your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
            error={errors?.your_website}
          />
        </div>
      </div>

      <div className="color-1717 text-14-400-18 cmb-16">Send Via :</div>
      <div className={`${errors?.preferences ? "" : "cmb-24"} d-flex gap-3`}>
        <div className="d-flex align-items-center gap-2">
          <CheckBox
            type={"ACTIVE"}
            isChecked={preferences?.whatsapp}
            onClick={() => {
              if (preferences?.whatsapp) {
                setFieldValue("preferences.whatsapp", "");
              } else {
                setFieldValue("preferences.whatsapp", "Whatsapp");
              }
            }}
          />
          <span className="text-black-olive text-14-300-18">Whats App</span>
        </div>
        {/* <div className="d-flex align-items-center gap-2">
          <CheckBox
            type={"ACTIVE"}
            isChecked={preferences?.sms}
            onClick={() => {
              if (preferences?.sms) {
                setFieldValue("preferences.sms", "");
              } else {
                setFieldValue("preferences.sms", "Sms");
              }
            }}
          />
          <span className="text-black-olive text-14-300-18">SMS</span>
        </div> */}
        <div className="d-flex align-items-center gap-2">
          <CheckBox
            type={"ACTIVE"}
            isChecked={preferences?.email}
            onClick={() => {
              if (preferences?.email) {
                setFieldValue("preferences.email", "");
              } else {
                setFieldValue("preferences.email", "Email");
              }
            }}
          />
          <span className="text-black-olive text-14-300-18">E-Mail</span>
        </div>
      </div>
      {errors?.preferences && (
        <span className="text-13-400 cmb-24 pt-1" style={{ color: "red" }}>
          {errors?.preferences}
        </span>
      )}

      <div className="d-flex">
        <Button
          text="Proceed to Next"
          btnStyle="primary-dark"
          className="h-35 cps-20 cpe-20"
          onClick={handleSubmit}
          btnLoading={btnLoader}
          disabled={isEqual(initialValues, values)}
        />
      </div>
    </div>
  );
};

export default AcceptanceTemplateForm;
