import React, { useEffect, useState } from "react";
import { registrationPass } from "utils/constants";
import { generatePreSignedUrl } from "utils/helpers";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import axios from "axios";
import { throwError, throwSuccess } from "store/slices";
import Loader from "components/Layout/Loader";
import "./AbstractRegistrationPass.scss";

const AbstractRegistrationPass = ({ paperData }) => {
  const dispatch = useDispatch();
  const [passURL, setPassURL] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const fetchURl = async (url) => {
    let returnURL = "";
    const response = await generatePreSignedUrl(url, registrationPass);
    returnURL = response;
    setPassURL(returnURL);
  };

  useEffect(() => {
    if (paperData?.registration_pass) {
      fetchURl(paperData?.registration_pass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.registration_pass]);

  const handleDownloadPass = async (downloadPassURL) => {
    setIsLoader(true);
    const res = await generatePreSignedUrl(downloadPassURL, registrationPass);
    try {
      const passResponse = await axios.get(res, { responseType: "blob" });
      saveAs(
        passResponse?.data,
        `RegistrationPass.${downloadPassURL?.split(".").pop()}`
      );
      dispatch(throwSuccess("Pass download successfully"));
    } catch (error) {
      dispatch(throwError("Error downloading the file"));
    }

    setIsLoader(false);
  };

  return (
    <>
      <div className="pass-container">
        <img src={passURL} alt="pass" className="user-pass" />
      </div>
      <div className="d-flex gap-2 cmt-15 cmb-30">
        <span
          className="pointer color-7cff text-16-500-20"
          onClick={() => {
            handleDownloadPass(paperData?.registration_pass);
          }}
        >
          Download Pass
        </span>
        {isLoader && <Loader />}
      </div>
    </>
  );
};

export default AbstractRegistrationPass;
