import { upperCase } from "lodash";
import React from "react";

const EducationDetails = ({ educationDetails }) => {
  return (
    <>
      {educationDetails?.map((item, index) => {
        const {
          courseType,
          courseName,
          department,
          university,
          institution,
          yearOfCompletion,
        } = item;
        return (
          <React.Fragment key={item?.courseName}>
            <div
              className={`${
                index < educationDetails?.length - 1 ? "cmb-20" : ""
              } row`}
            >
              <div className="col-lg-4 cmb-20">
                <div className="d-flex flex-column">
                  <span className="text-15-600">
                    Course Name ({upperCase(courseType)})
                  </span>
                  <span>{courseName ? courseName : "-"}</span>
                </div>
              </div>
              <div className="col-md-4 cmb-20">
                <div className="d-flex flex-column">
                  <span className="text-15-600">Department</span>
                  <span>{department ? department : "-"}</span>
                </div>
              </div>
              <div className="col-md-4 cmb-20">
                <div className="d-flex flex-column">
                  <span className="text-15-600">University</span>
                  <span>{university ? university : "-"}</span>
                </div>
              </div>
              <div className="col-md-8 mb-4 mb-lg-0">
                <div className="d-flex flex-column">
                  <span className="text-15-600">Institution</span>
                  <span>{institution ? institution : "-"}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-column">
                  <span className="text-15-600">Year of Completion</span>
                  <span>{yearOfCompletion ? yearOfCompletion : "-"}</span>
                </div>
              </div>
            </div>
            {educationDetails?.length > 0 &&
              index < educationDetails?.length - 1 && (
                <div className="border-bottom cmb-20"></div>
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default EducationDetails;
