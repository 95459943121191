import Modal from "components/Layout/Modal";
import Button from "components/form/Button";
import Label from "components/form/Label";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { addUpdateCV, throwError, throwSuccess } from "store/slices";
import { icons } from "utils/constants";
import { bytesToSize, objectToFormData, replaceFileName } from "utils/helpers";

const MentorUploadCVPopup = ({
  onHide,
  setIsGuidLinePopup,
  authUserDetails,
  isEdit,
  isCVPopup,
  fetchDetails,
}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(isCVPopup || "");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    let fileValue = e;
    if (fileValue) {
      setFileName(fileValue?.name);
      setFile(fileValue);
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadMentorCV = async (file) => {
    setIsLoading(true);
    try {
      const base64File = await getBase64(file);
      const payload = {
        id: authUserDetails?.id,
        cv: base64File,
      };
      const response = await dispatch(addUpdateCV(objectToFormData(payload)));
      if (response?.status === 200) {
        dispatch(throwSuccess("CV is uploaded successfully."));
        if (isEdit) {
          fetchDetails();
        }
        onHide();
        if (!isEdit) {
          setIsGuidLinePopup(true);
        }
      } else {
        dispatch(throwError(response?.message));
      }
    } catch (error) {
      dispatch(throwError("Error uploading file."));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (typeof isCVPopup === "string") {
      setFileName(isCVPopup);
    } else {
      setFileName("");
    }
  }, [isCVPopup]);

  const popupTitle =
    isEdit && typeof isCVPopup === "string" ? "Update CV" : "Upload CV";
  return (
    <Modal onHide={onHide}>
      <Label
        label={popupTitle}
        className="cmb-10 cmt-20 text-16-600 color-black"
      />
      <div
        id="mentor-cv"
        className="cpt-50 cpb-50 cmb-30 cps-30 cpe-30 text-center"
      >
        <FileUploader
          handleChange={handleChange}
          name="file"
          //   types={fileTypes}
          multiple={false}
        >
          {fileName && !file ? (
            <div className="d-flex align-items-center gap-3">
              <div className="image-type">
                <img src={icons.file} alt="type" />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="text-13-500 color-raisin-black">
                  {replaceFileName(fileName, authUserDetails?.first_name)}
                </div>
              </div>
            </div>
          ) : file ? (
            <div className="">
              <div className="d-flex align-items-center gap-3">
                <div className="image-type">
                  <img src={icons.file} alt="type" />
                </div>
                <div className="d-flex align-items-center gap-3">
                  <div className="text-13-500 color-raisin-black">
                    {file?.name}
                  </div>

                  <div className="text-12-400 color-davys-gray">
                    {bytesToSize(file?.size)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div id="drop-area-box">
              <div>
                <img src={icons.imageUpload} alt="img" />
              </div>
              <div className="text-15-400 color-dark-silver cmt-20">
                Choose the files you want to upload from your computer{" "}
                <span className="color-new-car pointer">Browse</span>
              </div>
            </div>
          )}
        </FileUploader>
      </div>

      <div className="d-flex align-items-center justify-content-start cmb-10">
        <Button
          text={isEdit && typeof isCVPopup === "string" ? "Update" : "Next"}
          btnStyle="primary-dark"
          className="text-12-500 h-auto text-nowrap"
          onClick={() => {
            uploadMentorCV(file);
          }}
          btnLoading={isLoading}
          disabled={isEdit ? !file : !fileName}
        />
      </div>

      {!isCVPopup && (
        <div className="d-flex align-items-center justify-content-center cmb-10">
          <span
            className="text-16-400 color-black-olive pointer"
            onClick={() => {
              onHide();
              setIsGuidLinePopup(true);
            }}
          >
            Skip I'll do later
          </span>
        </div>
      )}
    </Modal>
  );
};

export default MentorUploadCVPopup;
