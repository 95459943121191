import ToggleContainer from "components/Layout/ToggleContainer";
import EventAcceptanceLetterheadForm from "components/ReusableForms/EventAcceptanceLetterheadForm";
import React from "react";

const LetterTemplate = ({ eventId, fetchEventDetails }) => {
  return (
    <ToggleContainer
      title="Acceptance Letterhead"
      isError={eventId === "add-event"}
      errorMessage="Event is required."
    >
      <div className="cmb-20">
        <EventAcceptanceLetterheadForm
          eventId={eventId}
          fetchEventDetails={fetchEventDetails}
        />
      </div>
    </ToggleContainer>
  );
};

export default LetterTemplate;
