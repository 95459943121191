import React, { useEffect, useState } from "react";
import RevisionPaperForm from "../RevisionPaperForm";
import {
  downloadFile,
  formatDate,
  generatePreSignedUrl,
  // titleCaseString,
} from "utils/helpers";
import { eventAbstarctPath, icons } from "utils/constants";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const UserAbstractState = ({
  ref,
  data,
  handleSuccess,
  isAddRevisionAbstract,
  setIsAddRevisionAbstract,
  paperData,
  revisionFormRef,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { paperType, paperId } = params;
  const [isSubmittedPaperDetail, setIsSubmittedPaperDetail] = useState(false);
  const [isRevisionDetails, setIsRevisionDetails] = useState(null);
  const [expandedIndexes, setExpandedIndexes] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    // paperTitleName,
    paperAbstractDetail,
    paper,
    paperDate,
    revisionDetails,
  } = data;

  const abstractText = paperAbstractDetail
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;
  const toggleRevisionReadMore = (index) => {
    setExpandedIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (revisionDetails?.length > 0) {
      setIsRevisionDetails(revisionDetails?.length - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.revisionDetails]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className="steps-block active">
          <img src={icons?.RightIcon} alt="right" />
        </div>
        <div>
          <div className="text-16-500-20 color-new-car d-flex align-items-center gap-2">
            Abstract
            <i className="bi bi-chevron-down" style={{ fontSize: "15px" }} />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start" ref={ref}>
        <div className="sparator-block">
          <span className="hr-line" />
        </div>

        {revisionDetails?.length > 0 ? (
          <>
            <div className="w-100" ref={revisionFormRef}>
              <div className="submitted-paper-block border br-5 w-100 cmb-8">
                <div
                  className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                  onClick={() => {
                    setIsSubmittedPaperDetail(!isSubmittedPaperDetail);
                  }}
                >
                  <span>Submitted Paper</span>
                  <i
                    className="bi bi-chevron-down"
                    style={{ fontSize: "15px" }}
                  />
                </div>
                {isSubmittedPaperDetail && (
                  <div className="cps-24 cpt-12 cpe-24 cpb-12">
                    {/* {paperTitleName && (
                      <div className="cmb-14">
                        {titleCaseString(paperTitleName)}
                      </div>
                    )} */}
                    {paperAbstractDetail && (
                      <div className="cmb-16 text-14-300-24 color-black-olive">
                        <div>
                          {isExpanded ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: paperAbstractDetail,
                              }}
                            />
                          ) : (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: paperAbstractDetail
                                    ?.replace(/<\/?p>/g, "")
                                    ?.replace(/<\/?span[^>]*>/g, "")
                                    ?.split(" ")
                                    ?.slice(0, maxWords)
                                    ?.join(" "),
                                }}
                              />

                              {abstractWords?.length > maxWords && (
                                <>
                                  {"... "}
                                  <span
                                    onClick={toggleReadMore}
                                    className="text-14-500-23 color-new-car pointer"
                                  >
                                    Read More
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        {isExpanded && (
                          <span
                            onClick={toggleReadMore}
                            className="text-14-500-23 color-new-car pointer"
                          >
                            Read Less
                          </span>
                        )}

                        <div
                          className="cmb-16 cmt-16 text-16-500-20 color-7cff pointer"
                          onClick={async () => {
                            if (paper) {
                              let downloadContent = "";
                              if (paperType === "abstract") {
                                downloadContent = await generatePreSignedUrl(
                                  paper,
                                  eventAbstarctPath
                                );
                              }
                              dispatch(downloadFile(downloadContent));
                            }
                          }}
                        >
                          Download Abstract File
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {revisionDetails?.map((details, index) => {
                const {
                  abstract_path,
                  revision_date,
                  paper_abstract,
                  /* paper_title, */
                  revision_status,
                } = details;
                const isOpen = isRevisionDetails === index;
                const isExpanded = expandedIndexes[index];
                return (
                  <React.Fragment key={index}>
                    {revision_status === "0" ? (
                      isAddRevisionAbstract && (
                        <div className="revision-block border br-5 w-100 cmb-8">
                          <div className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12">
                            <span>{`Revision ${index + 1}`}</span>
                            <i
                              className={`${"bi bi-chevron-up"}`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>

                          <RevisionPaperForm
                            paperData={paperData}
                            details={details}
                            ref={ref}
                            abstractId={paperId}
                            onHide={() => {
                              setIsAddRevisionAbstract(false);
                            }}
                            handleSuccess={() => {
                              handleSuccess();
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="revision-block border br-5 w-100 cmb-8"
                        key={index + 1}
                        re
                      >
                        <div
                          className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                          onClick={() => {
                            setIsRevisionDetails(isOpen ? null : index);
                          }}
                        >
                          <span>Revision {index + 1}</span>
                          <i
                            className={`${
                              isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>

                        {isOpen && (
                          <div className="cps-24 cpt-12 cpe-24 cpb-12">
                            {/* for test */}
                            {/* {paper_title && (
                              <div className="cmb-14 text-14-400-18 color-black-olive">
                                {paper_title}
                              </div>
                            )} */}
                            {revision_date && (
                              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 cmb-14">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(revision_date)}
                              </div>
                            )}
                            {paper_abstract && (
                              <div className="cmb-16 text-14-300-24 color-black-olive">
                                <div>
                                  {isExpanded ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: paper_abstract,
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: paper_abstract
                                            ?.replace(/<\/?p>/g, "")
                                            ?.replace(/<\/?span[^>]*>/g, "")
                                            ?.split(" ")
                                            ?.slice(0, maxWords)
                                            ?.join(" "),
                                        }}
                                      />
                                      {paper_abstract?.split(" ")?.length >
                                        maxWords && (
                                        <>
                                          {"... "}
                                          <span
                                            onClick={() =>
                                              toggleRevisionReadMore(index)
                                            }
                                            className="text-14-500-23 color-new-car pointer"
                                          >
                                            Read More
                                          </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>

                                {isExpanded && (
                                  <span
                                    onClick={() =>
                                      toggleRevisionReadMore(index)
                                    }
                                    className="text-14-500-23 color-new-car pointer"
                                  >
                                    Read Less
                                  </span>
                                )}
                              </div>
                            )}
                            <div
                              className="cmb-16 text-16-500-20 color-7cff pointer"
                              onClick={async () => {
                                if (abstract_path) {
                                  let downloadContent = "";
                                  if (paperType === "abstract") {
                                    downloadContent =
                                      await generatePreSignedUrl(
                                        abstract_path,
                                        eventAbstarctPath
                                      );
                                  }
                                  dispatch(downloadFile(downloadContent));
                                }
                              }}
                            >
                              Download Abstract File
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </>
        ) : isAddRevisionAbstract ? (
          <>
            <div className="w-100">
              <div className="revision-block border br-5  w-100 cmb-8">
                <div className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12">
                  <span>Revision 1</span>
                  <i
                    className={`${"bi bi-chevron-down"}`}
                    style={{ fontSize: "15px" }}
                  />
                </div>
                <RevisionPaperForm
                  abstractId={paperId}
                  onHide={() => {
                    setIsAddRevisionAbstract(false);
                  }}
                  handleSuccess={() => {
                    handleSuccess();
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cmt-14 cmb-19">
              {paperDate && (
                <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300">
                  <img src={icons.calendarCheck} alt="calendar" />
                  {formatDate(paperDate)}
                </div>
              )}
              <div className="cmb-16 text-14-300-24 color-black-olive">
                You have successfully submitted abstract !!
              </div>
              <div
                className="cmb-16 text-16-500-20 color-7cff pointer"
                onClick={async () => {
                  if (paper) {
                    let downloadContent = "";
                    if (paperType === "abstract") {
                      downloadContent = await generatePreSignedUrl(
                        paper,
                        eventAbstarctPath
                      );
                    }
                    dispatch(downloadFile(downloadContent));
                  }
                }}
              >
                Download Abstract File
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserAbstractState;
