import Card from "components/Layout/Card";
import React, { useEffect, useState } from "react";
import Button from "components/form/Button";
import { useNavigate } from "react-router-dom";
import { getUserType } from "utils/helpers";
import { icons } from "utils/constants";
import "./DashboardPapersInfo.scss";
const steps = [
  "Paper Verification",
  "Plagiarism",
  "Review",
  "Acceptance",
  "Registration",
];
const DashboardPapersInfo = ({ label, data }) => {
  const navigate = useNavigate();
  const memberType = getUserType();
  const [selectedPaper, setSelectedPaper] = useState(null);

  useEffect(()=>{
    if(data?.length>0){
      setSelectedPaper(data?.[0])
    }
  },[data])
  return (
    <Card className={"cps-24 cpe-24 cpt-24 cpb-45 submitted-paper-status-id"}>
      <div className="d-flex justify-content-between align-items-center mb-3 w-fit">
        <div className="text-16-500-20 color-7490 font-poppins">{label}</div>
      </div>
      {data?.map((elem, index) => {
        const { abstract_id, paper_title, event_id, id, stage } = elem;
        const isOpen = selectedPaper === elem;
        return (
          <div className="border" key={index}>
            <div
              className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-18 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f6ff"
              onClick={() => {
                setSelectedPaper(isOpen ? null : elem);
              }}
            >
              <span>{`${abstract_id} - ${paper_title}`}</span>

              <i
                className={`${
                  isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                }`}
                style={{ fontSize: "15px" }}
              />
            </div>
            {isOpen && (
              <div
                className={`${
                  data ? "cpb-20" : "cpb-60"
                } d-flex flex-column cpt-22 cps-24 cpe-24 w-100`}
              >
                <div
                  className={`${
                    data ? "cmb-60" : ""
                  } d-flex align-items-center justify-content-center w-100`}
                >
                  {steps.map((o, stepIndex) => {
                    const currentStage = +stage;
                    const lastIndex = steps?.length - 1;
                    const isCompleted = stepIndex < currentStage;
                    const isActive = stepIndex + 1 === currentStage + 1;

                    return (
                      <React.Fragment key={stepIndex}>
                        <div className="d-flex justify-content-start align-items-center position-relative">
                          <div
                            className={`steps-block ${
                              isActive || isCompleted ? "active" : "not-active"
                            }`}
                          >
                            {isCompleted ? (
                              <img src={icons?.RightIcon} alt="right" />
                            ) : (
                              stepIndex + 1
                            )}
                          </div>
                          <div
                            className={`step-label ${
                              isActive || isCompleted
                                ? "active-label"
                                : "in-active-label"
                            }`}
                          >
                            {o}
                          </div>
                        </div>
                        {lastIndex !== stepIndex && (
                          <div className="sparator-block">
                            <span
                              className={`hr-line ${
                                isCompleted ? "active-line" : "in-active-line"
                              }`}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="d-flex">
                  <Button
                    text={"View Full Details"}
                    btnStyle={"primary-dark"}
                    className={"h-35 cps-20 cpe-20"}
                    onClick={() => {
                      navigate(
                        `/${memberType}/my-profile/my-events/abstract/tracking/${event_id}/${id}`
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </Card>
  );
};

export default DashboardPapersInfo;
