import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import Table from "components/Layout/Table";
import ExportButton from "components/Layout/ExportButton";
import CommentAddView from "components/V2ForNewAbstractFlow/ResourceSide/CommentAddView";
import { useNavigate } from "react-router-dom";
import { objectToFormData, titleCaseString } from "utils/helpers";
import {
  exportPaymentVerificationList,
  fetchPaymentsList,
} from "store/slices/accountsSlice";
import { useDispatch, useSelector } from "react-redux";
import "./PaymentVerification.scss";

const getStatusName = (val) => {
  if (val) {
    if (val === "0") {
      return "Pending";
    } else if (val === "1") {
      return "Success";
    } else {
      return "Verifying";
    }
  }
};

const paymentType = [
  { name: "UPI", value: "UPI" },
  { name: "NEFT", value: "NEFT" },
  { name: "Offline", value: "Offline" },
];
const paymentStatus = [
  { value: "Pending", name: "0" },
  { value: "Success", name: "1" },
  { value: "Verifying", name: "2" },
];

const PaymentVerification = () => {
  const { membershipList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
  });
  const [searchPayload, setSearchPayload] = useState({
    ref_no: "",
    membership_id: "",
    membership_type: "",
    user_name: "",
    user_email: "",
    paper_id: "",
    paper_title: "",
    confer_name: "",
    confer_type: "",
    date: "",
    payment_type: "",
    payment_status: "",
  });

  const getAccountsList = async (obj) => {
    const response = await dispatch(fetchPaymentsList(objectToFormData(obj)));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.abstractpaperpayments) {
      resList = response?.data?.abstractpaperpayments || [];
      resResultCount = response?.data?.result_count || 0;
      setList(resList);
      setFilterData({
        ...obj,
        total: resResultCount,
      });
    }
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getAccountsList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = {
      ...filterData,
      ...searchPayload,
    };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getAccountsList(newData);
  };

  useEffect(() => {
    getAccountsList({ ...searchPayload, ...filterData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (id) => {
    navigate(`/accounts/payment-verification-details/${id}`);
  };

  const header = [
    {
      isSearch: true,
      searchInputName: "ref_no",
      title: <span className="text-nowrap">Reference No</span>,
    },
    {
      isSearch: true,
      searchInputName: "membership_id",
      title: <span className="text-nowrap">Membership Id</span>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      searchInputName: "membership_type",
      dropdownOptions: {
        options: membershipList,
        key: "name",
        value: "name",
      },
      title: <span className="text-nowrap">Membership Type</span>,
    },
    {
      isSearch: true,
      searchInputName: "user_name",
      title: <span className="text-nowrap">User Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "user_email",
      title: <span className="text-nowrap">Email ID</span>,
    },
    {
      isSearch: true,
      searchInputName: "paper_id",
      title: <span className="text-nowrap">Paper Id</span>,
    },
    {
      isSearch: true,
      searchInputName: "paper_title",
      title: <span className="text-nowrap">Paper Title</span>,
    },
    {
      isSearch: true,
      searchInputName: "confer_name",
      title: <span className="text-nowrap">Conference Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "confer_type",
      title: <span className="text-nowrap">Conference Type</span>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "date",
      title: <span className="text-nowrap">Date</span>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      isMyTaskFilter: true,
      myTaskDropdownOptions: {
        options: paymentType,
        key: "name",
        value: "name",
      },
      handleStatus: (e) => {
        setSearchPayload((prev) => {
          return { ...prev, payment_type: e.target.value };
        });
      },
      searchInputName: "payment_type",
      title: <span className="text-nowrap">Payment Type</span>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      isMyTaskFilter: true,
      myTaskDropdownOptions: {
        options: paymentStatus,
        key: "name",
        value: "value",
      },
      handleStatus: (e) => {
        setSearchPayload((prev) => {
          return { ...prev, payment_status: e.target.value };
        });
      },
      searchInputName: "payment_status",
      title: <span className="text-nowrap">Payment Status</span>,
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];
  const rowData = [];
  list?.forEach((elem) => {
    const {
      id,
      ref_no,
      membership_id,
      membership_type,
      user_name,
      user_email,
      paper_id,
      paper_title,
      confer_name,
      confer_type,
      date,
      payment_type,
      payment_status,
    } = elem || {};
    let obj = [
      {
        value: <span className="text-nowrap">{ref_no}</span>,
      },
      {
        value: <span className="text-nowrap">{membership_id}</span>,
      },
      {
        value: <span className="text-nowrap">{membership_type}</span>,
      },
      {
        value: (
          <span
            className="text-nowrap pointer color-new-car"
            onClick={() => {
              handleRedirect(id);
            }}
          >
            {user_name}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{user_email}</span>,
      },
      {
        value: <span className="text-nowrap color-new-car">{paper_id}</span>,
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(paper_title)}</span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(confer_name)}</span>
        ),
      },
      {
        value: <span className="text-nowrap">{confer_type}</span>,
      },
      {
        value: <span className="text-nowrap">{date}</span>,
      },
      {
        value: <span className="text-nowrap">{payment_type}</span>,
      },
      {
        value: (
          <span
            className={`${
              payment_status === "0"
                ? "color-5110"
                : payment_status === "1"
                ? "color-a35b"
                : payment_status === "2"
                ? "color-6866"
                : ""
            } text-nowrap`}
          >
            {getStatusName(payment_status)}
          </span>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            <>
              <i
                className="bi bi-eye pointer color-new-car"
                onClick={() => {
                  handleRedirect(id);
                }}
              />

              <CommentAddView
                isPayment
                icon={<i className="bi bi-chat-right-text position-relative" />}
                elem={elem}
                handelSuccess={() => {
                  getAccountsList({ ...filterData, ...searchPayload });
                }}
              />
            </>
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <Card className="cps-20 cpe-20 cpb-20 unset-br">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28 flex-wrap gap-2">
        <div className="table-title">
          All Payments ({filterData?.total || 0})
        </div>
        <div className="d-flex align-items-center flex-wrap gap-3">
          {/* <div className="d-flex">
            <SeachInput placeholder={"Search here"} onChange={() => {}} />
          </div> */}
          {/* <div className="d-flex">
            <FilterDropdown list={[]} handelChangeFilter={() => {}} />
          </div> */}
          <ExportButton
            exportAPI={exportPaymentVerificationList}
            payload={objectToFormData({ ...searchPayload, ...filterData })}
          />
        </div>
      </div>
      <div className="overflow-auto">
        <Table
          header={header}
          rowData={rowData}
          isOtherPagination
          isLoading={isLoading}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </div>
    </Card>
  );
};

export default PaymentVerification;
