import React, { useEffect, useState } from "react";
import BootrsapTable from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Dropdown from "components/form/Dropdown";
import Button from "../../form/Button";
import Loader from "../Loader";
import { icons } from "utils/constants";
import PaperStatusFilter from "./PaperStatusFilter";
import DatePicker from "components/form/DatePicker";
import CalendarFilterLayout from "components/Layout/CalendarFilterLayout/CalendarFilterLayout";
// import TableSearch from "./TableSearch";
import ReactPaginate from "react-paginate";
import MultipleSelect from "components/form/MultipleSelect";
import ReviewerPaperStatusFilter from "components/V2ForNewAbstractFlow/ReviewerSide/ReviewerPaperStatusFilter/ReviewerPaperStatusFilter";
import "./Table.scss";

const Table = ({
  header,
  rowData,
  searchInputChange,
  filterData,
  searchPayload,
  changeOffset,
  isLoading,
  hidePagination,
  isOtherPagination,
}) => {
  const [isPicker, setIsPicker] = useState(false);
  const [timer, setTimer] = useState("");
  const [searchObject, setSearchObject] = useState(searchPayload || {});
  const [displayedPages, setDisplayedPages] = useState(20);
  const [newPaginateActivePage, setNewPaginateActivePage] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState("");

  const handelChangeInput = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    let newPayload = "";
    if (e.target.value === "custom") {
      newPayload = {
        ...searchObject,
        [name]: value,
        start_date: e.target.start_date,
        end_date: e.target.end_date,
      };
      setSearchObject(newPayload);
    } else {
      if (searchObject?.start_date && searchObject?.end_date) {
        const { start_date, end_date, ...newSearchObject } = searchObject;
        setSearchObject({ ...newSearchObject, [name]: value });
      } else {
        setSearchObject({ ...searchObject, [name]: value });
      }
    }

    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      if (e.target.value === "custom") {
        searchInputChange(newPayload);
      } else {
        if (searchObject?.start_date && searchObject?.end_date) {
          const { start_date, end_date, ...newSearchObject } = searchObject;
          searchInputChange({ ...newSearchObject, [name]: value });
        } else {
          searchInputChange({ ...searchObject, [name]: value });
        }
      }
    }, 800);
    setTimer(time);
  };

  const handleCountryChange = (e) => {
    setSelectedCountries(e?.target?.value);
  };

  useEffect(() => {
    setSearchObject(searchPayload || {});
  }, [searchPayload]);

  useEffect(() => {
    if (filterData?.offset === 0) {
      setNewPaginateActivePage(null);
    }
  }, [filterData]);

  const totalPage = Math.ceil(filterData?.total / filterData?.limit);
  const activePage = filterData?.offset / filterData?.limit + 1;

  const handlePageChange = (selected) => {
    const newOffset =
      (selected?.selected * filterData?.limit) % filterData?.total;
    setNewPaginateActivePage(selected?.selected);
    if (selected?.selected + 1 >= displayedPages) {
      setDisplayedPages((prev) => prev + 15);
    }
    changeOffset(newOffset);
  };

  useEffect(() => {
    if (isOtherPagination && totalPage) {
      setDisplayedPages(totalPage || 0);
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [isOtherPagination && totalPage]);

  return (
    <div id="table-container" className="iferp-scroll">
      {isPicker && (
        <CalendarFilterLayout
          onHide={() => {
            setIsPicker(false);
          }}
          elem={isPicker}
          onChange={handelChangeInput}
        />
      )}
      <BootrsapTable>
        <thead className="table-header-container">
          <tr className="header-container">
            {header.map((elem, index) => {
              return (
                <th key={index} className="column-block">
                  {elem.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-body-container">
          {isLoading && (
            <tr className="loader-row">
              <td
                colSpan={header.length}
                className="loader-cell text-center color-gray"
              >
                <Loader />
              </td>
            </tr>
          )}
          {/* skeleton code */}
          {/* {isLoading && (
            <>
              {[...Array(5)].map((_, rowIndex) => (
                <SkeletonLoader
                  key={rowIndex}
                  type="tableRow"
                  count={header.length}
                />
              ))}
            </>
          )} */}
          {!isLoading && (
            <>
              {!hidePagination && (
                <tr className="filter-row-container">
                  {header.map((elem, index) => {
                    const {
                      isSearch,
                      isSearchDropdown,
                      isPaperStatusFilter,
                      isResourceCountryFilter,
                      isDatePicker,
                      searchInputName,
                      dropdownOptions,
                      handlePaperStatusChange,
                      handleApplyClick,
                      isProfileManagement,

                      myTaskDropdownOptions,
                      isMyTaskFilter,
                    } = elem;

                    return (
                      <td key={index} className="filter-row-block col-sm-auto">
                        {isSearch ? (
                          isMyTaskFilter ? (
                            <ReviewerPaperStatusFilter
                              id={searchInputName}
                              options={myTaskDropdownOptions.options}
                              optionKey={myTaskDropdownOptions?.key}
                              optionValue={myTaskDropdownOptions?.value}
                              // optionValue={myTaskDropdownOptions?.key}
                              handleStatus={handelChangeInput}
                              values={searchObject[`${searchInputName}`]}
                            />
                          ) : isResourceCountryFilter ? (
                            <>
                              <div style={{ width: "170px" }}>
                                <MultipleSelect
                                  options={dropdownOptions.options}
                                  optionValue={dropdownOptions?.value}
                                  value={selectedCountries}
                                  placeholder="Select"
                                  onChange={handleCountryChange}
                                  isApplyButton
                                  onApplyClick={handleApplyClick}
                                />
                              </div>

                              {/* <CountryFilter searchObject={searchObject} /> */}
                            </>
                          ) : isPaperStatusFilter ? (
                            <PaperStatusFilter
                              handlePaperStatusChange={handlePaperStatusChange}
                              searchObject={searchObject}
                            />
                          ) : isSearchDropdown ? (
                            <Dropdown
                              placeholder="Select"
                              id={searchInputName}
                              value={searchObject[`${searchInputName}`]}
                              options={dropdownOptions.options}
                              optionKey={dropdownOptions?.key}
                              optionValue={dropdownOptions?.value}
                              onChange={handelChangeInput}
                              isSearchable={false}
                              isClearable
                            />
                          ) : isDatePicker ? (
                            isProfileManagement ? (
                              <>
                                <div className="d-flex align-items-center gap-2 cps-8 cpe-8">
                                  <div
                                    className="d-flex align-items-center gap-2 pointer"
                                    onClick={() => {
                                      setIsPicker(elem);
                                    }}
                                  >
                                    <span
                                      className={`calender-icon w-fit left-calender-icon`}
                                    >
                                      <img
                                        src={icons.calendar}
                                        alt="calender"
                                      />
                                    </span>
                                    <span className="text-nowrap color-raisin-black">
                                      {searchObject[
                                        `${elem.searchInputName}`
                                      ] || "Select Date"}
                                    </span>
                                  </div>
                                  {searchObject[`${elem.searchInputName}`] && (
                                    <span
                                      className="pointer"
                                      onClick={() => {
                                        handelChangeInput({
                                          target: {
                                            id: searchInputName,
                                            value: "",
                                          },
                                        });
                                      }}
                                    >
                                      <i className="bi bi-x text-24-500 color-gray" />
                                    </span>
                                  )}
                                </div>
                              </>
                            ) : (
                              <DatePicker
                                isTableClass
                                placeholder="Select Date"
                                id={elem.searchInputName}
                                onChange={handelChangeInput}
                                value={searchObject[`${elem.searchInputName}`]}
                                isLeftIcon
                                isClearable
                              />
                            )
                          ) : (
                            <>
                              <input
                                className="filter-input"
                                style={{ width: "100%" }}
                                placeholder="Search"
                                onChange={handelChangeInput}
                                id={elem.searchInputName}
                                value={searchObject[`${elem.searchInputName}`]}
                              />
                              <img
                                src={icons.search}
                                alt="search"
                                className="icon-block"
                              />
                            </>
                          )
                        ) : (
                          elem.searchLable
                        )}
                        {/* {elem.isSearch ? (
                          <TableSearch
                            id={searchInputName}
                            isDatePicker={isDatePicker}
                            optionKey={dropdownOptions?.key}
                            options={dropdownOptions?.options}
                            isSearchDropdown={isSearchDropdown}
                            optionValue={dropdownOptions?.value}
                            value={searchObject[`${searchInputName}`]}
                            onChange={handelChangeInput}
                            placeholder={
                              dropdownOptions?.placeholder || isDatePicker
                                ? "Select Date"
                                : isSearchDropdown
                                ? "Select Option"
                                : "Search"
                            }
                          />
                        ) : (
                          elem.searchLable
                        )} */}
                      </td>
                    );
                  })}
                </tr>
              )}

              {rowData.length === 0 ? (
                <tr className="no-record-found-row">
                  <td
                    colSpan={header.length}
                    className="no-record-found-cell text-center color-gray"
                  >
                    No Records Found.
                  </td>
                </tr>
              ) : (
                rowData.map((elem, index) => {
                  return (
                    <tr key={index} className="row-container">
                      {elem.data.map((cell, cellIndex) => {
                        return (
                          <td className="row-block" key={cellIndex}>
                            {cell.value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </>
          )}
        </tbody>
      </BootrsapTable>
      {totalPage > 1 &&
        !isLoading &&
        (isOtherPagination ? (
          <div className="d-flex justify-content-center react-pagination cmt-50">
            <ReactPaginate
              pageCount={Math.min(displayedPages, totalPage)}
              breakLabel="..."
              previousLabel="Prev"
              nextLabel="Next"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              disabledLinkClassName="disable-page-link"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              forcePage={
                newPaginateActivePage !== null
                  ? newPaginateActivePage
                  : undefined
              }
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-4 center-flex">
              <div className="d-flex gap-4 cmt-30 cpb-30">
                <Button
                  icon={<i className="bi bi-chevron-left me-1" />}
                  text="Previous"
                  btnStyle={activePage === 1 ? "primary-gray" : "primary-dark"}
                  className="cps-20 cpe-20"
                  onClick={() => {
                    if (activePage !== 1) {
                      changeOffset(filterData?.offset - filterData?.limit);
                    }
                  }}
                  isRounded
                />
                <Button
                  rightIcon={<i className="bi bi-chevron-right ms-2" />}
                  text="Next"
                  btnStyle={
                    activePage === totalPage ? "primary-gray" : "primary-dark"
                  }
                  // btnStyle="primary-dark"
                  className="cps-40 cpe-30"
                  onClick={() => {
                    if (activePage !== totalPage) {
                      changeOffset(filterData?.offset + filterData?.limit);
                    }
                  }}
                  isRounded
                />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <Pagination className="d-flex align-items-center unset-m">
                <div className="text-16-400 color-davys-gray me-1">Page</div>
                <Pagination.Item disabled>{activePage}</Pagination.Item>
                <div className="text-16-400 color-davys-gray ms-1 me-2">of</div>
                <Pagination.Item disabled>{totalPage}</Pagination.Item>
                {/* <Pagination.Item
                disabled={activePage === 1}
                onClick={() => {
                  changeOffset(filterData?.offset - filterData?.limit);
                }}
              >
                <i className="bi bi-chevron-left" />
              </Pagination.Item>
              <Pagination.Item
                disabled={activePage === totalPage}
                onClick={() => {
                  changeOffset(filterData?.offset + filterData?.limit);
                }}
              >
                <i className="bi bi-chevron-right" />
              </Pagination.Item> */}
              </Pagination>
            </div>
          </div>
        ))}
    </div>
  );
};
export default Table;
