import React from "react";
// Keynote speaker, session speaker notification
const FifthStepTemplate = ({ values }) => {
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    featured_speakers,
    pre_conference_speaker_name,
    date_time,
    register_webinar,
    view_speaker_profile,
    register_conference,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `Dear ${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        Greetings from <strong>IFERP!</strong>
      </p>

      <p>
        We are honored to present the distinguished speakers for our upcoming
        conference,{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}{" "}
        </strong>
        accredited by Continuous Professional Development (CPD), taking place on{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Dates]"}
        </strong>{" "}
        at{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        . These highly respected experts, with their profound expertise, notable
        achievements, and invaluable perspectives, will undoubtedly enhance the
        quality and depth of your conference experience.
      </p>

      <h6>
        <strong>Featured Speakers:</strong>
      </h6>
      {featured_speakers?.length > 0 ? (
        featured_speakers?.map((elem, index) => {
          const {
            speaker_name: f_speaker_name,
            speaker_credentials,
            speaker_achievements,
            speaker_sessionTopic,
          } = elem;
          return (
            <React.Fragment key={index}>
              <div>
                <strong>
                  Dr. {f_speaker_name ? f_speaker_name : "[Speaker's Name]"}
                </strong>
              </div>
              <div>
                Credentials:
                <strong>
                  {" "}
                  {speaker_credentials
                    ? speaker_credentials
                    : "[Academic degrees, professional titles]"}
                </strong>
              </div>
              <div>
                Notable Achievements:
                <strong>
                  Notable Achievements:{" "}
                  {speaker_achievements
                    ? speaker_achievements
                    : "[Awards, significant research contributions, industry impact]"}
                </strong>
              </div>
              <div>
                Session Topic:
                <strong>
                  {" "}
                  {speaker_sessionTopic
                    ? speaker_sessionTopic
                    : "[Brief description of their presentation topic]"}
                </strong>
              </div>
              <br />
            </React.Fragment>
          );
        })
      ) : (
        <>
          <div>
            <strong>Dr. [Speaker's Name]</strong>
          </div>
          <div>
            Credentials:
            <strong> [Academic degrees, professional titles]</strong>
          </div>
          <div>
            Notable Achievements:
            <strong>
              Notable Achievements: [Awards, significant research contributions,
              industry impact]
            </strong>
          </div>
          <div>
            Session Topic:
            <strong> [Brief description of their presentation topic]</strong>
          </div>

          <div>
            <strong>Dr. [Speaker's Name]</strong>
          </div>
          <div>
            Credentials:
            <strong> [Academic degrees, professional titles]</strong>
          </div>
          <div>
            Notable Achievements:
            <strong>
              Notable Achievements: [Awards, significant research contributions,
              industry impact]
            </strong>
          </div>
          <div>
            Session Topic:
            <strong> [Brief description of their presentation topic]</strong>
          </div>
        </>
      )}

      <h6>
        <strong>Pre-Conference Webinar:</strong>
      </h6>
      <p>
        To give you a sneak peek of the incredible insights you can expect, we
        are hosting a pre-conference webinar featuring{" "}
        <strong>
          {pre_conference_speaker_name
            ? pre_conference_speaker_name
            : "[Speaker's Name]"}
        </strong>
        . Join us for a <strong>live Q&A session</strong> on{" "}
        <strong>{date_time ? date_time : "[Date and Time]"}</strong> to engage
        with one of our esteemed speakers and get a preview of their session.
      </p>
      <p>
        {register_webinar ? (
          <a href={register_webinar}>Click Here</a>
        ) : (
          "[Register for the Webinar]"
        )}
      </p>

      <h6>
        <strong>View Speaker Profiles:</strong>
      </h6>
      <p>
        For a deeper look at the full lineup of speakers and session highlights,
        click below:
      </p>
      <p>
        {view_speaker_profile ? (
          <a href={view_speaker_profile}>Click Here</a>
        ) : (
          "[View Speaker Profiles]"
        )}
      </p>

      <h6>
        <strong>Register Now:</strong>
      </h6>
      <p>
        Don’t miss the opportunity to learn from and network with leading
        experts in the field. Register today to secure your spot at the
        conference:
      </p>
      <p>
        {register_conference ? (
          <a href={register_conference}>Click Here</a>
        ) : (
          "[Register for the Conference]"
        )}
      </p>

      <p>
        We look forward to welcoming you to an event filled with groundbreaking
        research, innovative ideas, and meaningful discussions.
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>Click Here</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default FifthStepTemplate;
