import Button from "components/form/Button";
import Card from "components/Layout/Card";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "utils/constants";

const AbstractSubmitSuccessPopup = ({ onHide, isSuccess }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { memberType, eventId } = params;

  return (
    <Card className="abstract-submit-success-popup-container cpt-60 h-100">
      <div className=" d-flex flex-column align-items-center ">
        <div className="cmb-27">
          <img src={icons?.abstractDone} alt="logo" />
        </div>
        <div className="color-a35b text-24-700-30 cmb-27">
          Abstract Submitted Successfully
        </div>
        <p className="text-16-300-20 color-black cmb-27">
          Your abstract has been submitted successfully.
        </p>
        <div className="d-flex align-items-center gap-3">
          <Button
            text="View Track"
            btnStyle="primary-dark"
            className=""
            onClick={() => {
              navigate(
                `/${memberType}/my-profile/my-events/abstract/tracking/${eventId}/${isSuccess}`
              );
              // navigate(`/${memberType}/my-profile/my-events/${eventId}`);
            }}
          />
          <Button
            text="Close"
            btnStyle="light-outline"
            className=""
            onClick={onHide}
          />
        </div>
      </div>
    </Card>
  );
};

export default AbstractSubmitSuccessPopup;
