import React, { useEffect, useState } from "react";
import TableV2 from "components/Layout/TableV2";
import { objectToFormData, titleCaseString } from "utils/helpers";
import Button from "components/form/Button";
import {
  getmentorApprovalsList,
  mentorAcceptReject,
  throwError,
  throwSuccess,
} from "store/slices";
import { useDispatch } from "react-redux";
import "./RejectionRequests.scss";

const RejectionRequests = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchPayload, setSearchPayload] = useState({
    mentor_name: "",
    mentor_id: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    reject_status: "rejected",
  });
  const fetchMentorRejectionList = async (obj) => {
    setIsLoading(true);
    let formData = objectToFormData(obj);
    const response = await dispatch(getmentorApprovalsList(formData));

    let resList = [];
    let resResultCount = 0;
    if (response?.data?.session_list) {
      resList = response?.data?.session_list || [];
      resResultCount = response?.data?.result_count || 0;
    }
    setList(resList);
    setFilterData({
      ...obj,
      total: resResultCount,
    });
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  const handelChangeSearch = (search) => {
    setIsLoading(true);
    setSearchPayload(search);
    let newData = filterData;
    newData = { ...newData, ...search, offset: 0 };
    setFilterData(newData);
    fetchMentorRejectionList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchMentorRejectionList(newData);
  };

  const handleRejectionRequest = async (payload) => {
    setBtnLoading(payload?.mentor_id);
    const response = await dispatch(
      mentorAcceptReject(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      fetchMentorRejectionList({ ...searchPayload, ...filterData });
    } else {
      dispatch(throwError(response?.message));
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    fetchMentorRejectionList({ ...searchPayload, ...filterData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: true,
      searchInputName: "mentor_name",
      title: <div className="text-nowrap">Mentor Name</div>,
    },
    {
      isSearch: true,
      searchInputName: "mentor_id",
      title: <div className="text-nowrap">Mentor ID</div>,
    },

    {
      isSearch: false,
      searchLable: "",
      title: "Rejection Reason",
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Rejection Status",
    },
  ];

  const rowData = [];
  list?.forEach((elem) => {
    const { name, mentor_id, reason, status, resource_id, id } = elem;
    let obj = [
      {
        value: (
          <div className="text-14-500 text-nowrap">{titleCaseString(name)}</div>
        ),
      },
      {
        value: mentor_id,
      },
      {
        value: (
          <div className="text-nowrap">
            {reason ? titleCaseString(reason) : "-"}
          </div>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            {status === "Pending" ? (
              <>
                <Button
                  text="Approve"
                  btnStyle="mentor-approve-btn"
                  className="text-14-500 mw-70 me-2 "
                  isSquare
                  onClick={() => {
                    const payload = {
                      mentor_id: mentor_id,
                      status: 2,
                      resource_id: resource_id,
                      id: id,
                    };
                    handleRejectionRequest(payload);
                  }}
                  btnLoading={btnLoading === mentor_id}
                />
                <Button
                  text="Ignore"
                  btnStyle="reject-mentor-approve-btn"
                  className="text-14-500 mw-70 me-2"
                  isSquare
                  onClick={() => {
                    const payload = {
                      mentor_id: mentor_id,
                      status: 3,
                      resource_id: resource_id,
                      id: id,
                    };
                    handleRejectionRequest(payload);
                  }}
                />
              </>
            ) : (
              "-"
            )}
          </span>
        ),
      },
    ];
    rowData?.push({ data: obj });
  });

  return (
    <div id="all-mentors-container">
      <div className="overflow-auto">
        <TableV2
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </div>
    </div>
  );
};

export default RejectionRequests;
