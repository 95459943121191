import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import TextArea from "components/form/TextArea";
import Button from "components/form/Button";
import { useDispatch, useSelector } from "react-redux";
import { decrypt, encrypt, objectToFormData } from "utils/helpers";
import { editEvent, showSuccess } from "store/slices";

const EventAcceptanceLetterheadForm = ({ eventId, fetchEventDetails }) => {
  const dispatch = useDispatch();
  const { eventData } = useSelector((state) => ({
    eventData: state.global.eventData,
  }));
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPreview, setShowPreview] = useState("");
  const isEdit = eventData?.acceptance_letter_html;
  const previewLetterhead = decrypt(eventData?.acceptance_letter_html);

  const initialValues = { htmlLetter: isEdit ? previewLetterhead : "" };
  const validationSchema = Yup.object().shape({
    htmlLetter: Yup.string().required("HTML code is required."),
  });

  const handleSave = async (values) => {
    setBtnLoading(true);

    const payloadData = {
      html_letter: encrypt(values?.htmlLetter),
      type: 6,
      id: eventId,
    };
    const payload = objectToFormData(payloadData);
    const response = await dispatch(editEvent(payload));
    if (response?.status === 200) {
      fetchEventDetails();
      dispatch(showSuccess(`Acceptance format uploaded successfully.`));
    }
    setBtnLoading(false);
  };
  
  return (
    <div className="acceptance-letterhead-container row">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => {
          const { values, errors, handleChange, handleSubmit, resetForm } =
            props;
          return (
            <form className="col-md-6">
              <div className="cmb-15">
                <TextArea
                  label={"HTML Content"}
                  isRequired
                  id="htmlLetter"
                  value={values?.htmlLetter}
                  onChange={handleChange}
                  error={errors?.htmlLetter}
                  rows={10}
                  placeholder="Write your HTML code here..."
                />
              </div>

              <div className="d-flex gap-2">
                <Button
                  isRounded
                  text={"Preview"}
                  btnStyle={"primary-dark"}
                  className={"cps-20 cpe-20 h-35"}
                  disabled={!values?.htmlLetter}
                  onClick={() => {
                    setShowPreview(values?.htmlLetter);
                  }}
                />

                <Button
                  isRounded
                  text={"Save"}
                  btnStyle={"primary-dark"}
                  className={"cps-20 cpe-20 h-35"}
                  onClick={handleSubmit}
                  disabled={!showPreview}
                  btnLoading={btnLoading}
                />

                <Button
                  isRounded
                  text={"Reset"}
                  btnStyle="light-outline"
                  className={"cps-20 cpe-20 h-35"}
                  onClick={() => {
                    resetForm();
                    setShowPreview("");
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
      <div className={`cpt-20 col-md-6 cmb-15`}>
        {isEdit ? (
          <div
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              backgroundColor: "#f8f9fa",
            }}
            dangerouslySetInnerHTML={{
              __html:  previewLetterhead
            }}
          />
        ) : showPreview ? (
          <div
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              backgroundColor: "#f8f9fa",
            }}
            dangerouslySetInnerHTML={{
              __html: showPreview,
            }}
          />
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              maxHeight: "500px",
              height: "225px",
              overflowY: "auto",
              border: "1px solid #ccc",
              backgroundColor: "#f8f9fa",
            }}
          >
            No preview available
          </div>
        )}
      </div>
    </div>
  );
};

export default EventAcceptanceLetterheadForm;
