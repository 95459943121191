import { useState } from "react";
import Card from "components/Layout/Card";
import { getDataFromLocalStorage } from "utils/helpers";
import AllProfiles from "./AllProfiles";
import CampaignManagement from "./CampaignManagement";

const ProfileManagement = () => {
  const [tabType, setTabType] = useState("0");
  const userType = getDataFromLocalStorage("user_type");

  const activeClass = "p-2 bg-new-car color-white text-16-500";
  const inActiveClass = "p-2 color-dark-blue text-16-500 pointer";
  return (
    <>
      {userType === "0" ? (
        <>
          <Card className="d-flex align-items-center p-1 unset-br mb-3">
            <div className="d-flex align-items-center flex-wrap gap-2">
              <div
                className={tabType === "0" ? activeClass : inActiveClass}
                onClick={() => {
                  setTabType("0");
                }}
              >
                All Profiles
              </div>
              <div
                className={tabType === "1" ? activeClass : inActiveClass}
                onClick={() => {
                  setTabType("1");
                }}
              >
                Pending Requests
              </div>
              <div
                className={tabType === "2" ? activeClass : inActiveClass}
                onClick={() => {
                  setTabType("2");
                }}
              >
                Campaign Management
              </div>
            </div>
          </Card>
          {tabType === "0" && <AllProfiles tabType={tabType} />}
          {tabType === "1" && <AllProfiles tabType={tabType} />}
          {tabType === "2" && <CampaignManagement />}
        </>
      ) : (
        <AllProfiles tabType={tabType} />
      )}
    </>
  );
};
export default ProfileManagement;
