import Button from "components/form/Button";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserType } from "utils/helpers";

const FollowUpsDetails = ({ steps, paperData }) => {
  const navigate = useNavigate();
  const memberType = getUserType();
  const { event_id } = paperData || {};
  const searchParams = useParams();
  let newSteps = steps?.filter((o) => o?.is_active === "1");
  return (
    <div className="cps-24 cpt-12 cpe-24 cpb-12 follow-up-details-block">
      {newSteps?.map((elem, index) => {
        const { stage, is_active, is_next_or_prev, send_at, display_paid } =
          elem;
        const active =
          ["0", "1"].includes(is_active) && is_next_or_prev === "1";
        const isPayNowButton = display_paid === 1;
        /* const isPayNowButton = is_next_or_prev === "1" && is_active === "0"; */

        /* const isHideOfferExpired = is_active === "0"; */
        const isHideOfferExpired = !isPayNowButton;
        return (
          <React.Fragment key={index}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="follow-step-block">
                <span className="inner-block"></span>
              </div>
              <div>
                <div
                  style={{ maxHeight: "20px" }}
                  className={`${
                    active ? "color-new-car" : "color-6866"
                  } text-16-500-20`}
                >
                  {stage}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="follow-up-sparator-block">
                <span className="hr-line" />
              </div>
              <div className="cmb-19 cpt-10">
                <>
                  {!isPayNowButton && active && (
                    <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
                      {moment(send_at).format("DD-MM-YYYY")}
                    </div>
                  )}

                  {isHideOfferExpired && (
                    <span className="text-12-400-15 color-1717 br-5 cps-9 cpe-9 cpt-4 cpb-4 bg-f4fc">
                      Offer Expired
                    </span>
                  )}
                </>

                {isPayNowButton && (
                  <div className="d-flex  cmt-20">
                    <Button
                      text="Pay Now"
                      btnStyle="primary-dark"
                      className="cps-15 cpe-15 h-30"
                      onClick={() => {
                        localStorage.isRedirectToRegister = 1;
                        localStorage.prevRoute = window.location.pathname;
                        navigate(
                          `/${memberType}/conferences-and-events/event-details/${event_id}/conference-details?paper_id=${searchParams.paperId}`
                        );
                      }}
                    />
                  </div>
                )}

                {!active && isHideOfferExpired && !isPayNowButton && (
                  <div className="cpt-15 cpb-15"></div>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FollowUpsDetails;
