import Profile from "components/Layout/Profile";
import React, { useEffect, useState } from "react";
import { titleCaseString } from "utils/helpers";

const OldReviewReport = ({ oldData, paperData, index }) => {
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);

  useEffect(() => {
    if (oldData?.reviewer_result) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(oldData?.reviewer_result)?.includes(s.id)) {
          return {
            ...s,
            score: oldData?.reviewer_result[s?.id],
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldData?.reviewer_result]);
  const {
    paperId,
    submittedPapersStatus,
    // paperTitle, reviewer_revision_reasons
  } = paperData || {};
  const { is_remodify } = submittedPapersStatus || {};

  const {
    general_comment,
    recommendation,
    reviewer_result,
    paper_title,
    name,
    email_id,
    member_id,
    phone_number,
    allocated_review,
    profile_image,
    area_of_interest,
    paper_status,
  } = oldData || {};
  const { abstract_result, overall } = reviewer_result || {};
  // const paperAccepted = is_remodify === "2";
  const paperRejected = is_remodify === "3";
  const paperRevision = is_remodify === "1";
  return (
    <div className="cpt-14 cpb-14 cps-24 cpe-24">
      <div className="row cmb-18">
        <div className="col-lg-4 col-md-6 col-sm-12 gy-4">
          <div
            className={`cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
          >
            <div className="row">
              {/* Image Section */}
              <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                <div style={{ height: "114px" }}>
                  <Profile
                    url={profile_image}
                    size="s-114"
                    isS3UserURL
                    text={name}
                  />
                </div>
              </div>

              {/* Info Section */}
              <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                <div className="d-flex flex-column">
                  <div className="text-14-400-18 color-new-car cmb-15">
                    {name}
                  </div>
                  <div className="text-12-400-15 color-new-car cmb-15">
                    {member_id}
                  </div>
                  <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                    <i className="bi bi-envelope" /> {email_id}
                  </div>
                  <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                    <i className="bi bi-telephone" /> {phone_number}
                  </div>
                </div>
              </div>

              {/* Allocated Reviews */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-15">
                <div className="text-12-400-15 color-6866">
                  Allocated Reviews
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-15">
                <div className="text-12-400-15 color-6866">
                  : {allocated_review}
                </div>
              </div>

              {/* Area of Interest */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                <div className="text-12-400-15 color-6866">
                  Area of Interest
                </div>
              </div>
              <div
                className={`col-lg-8 col-md-6 col-sm-6 col-6 ${
                  paper_status ? "" : "cmb-13"
                }`}
              >
                <div className="text-12-400-15 color-6866">
                  : {area_of_interest?.split(",")?.join(", ")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-16-400-20 color-new-car border-bottom cpb-16 cmb-24">
        Peer Review Report :
      </div>
      <div className="row gy-3 mb-md-4 mb-lg-0">
        <div className="col-lg-7">
          <div className="text-14-500-17 color-6866 cmb-12">Abstract ID :</div>
          {paperId && (
            <div className="text-14-300-18 color-6866 cmb-24">{paperId}</div>
          )}

          <div className="text-14-500-17 color-6866 cmb-12">
            Manuscript Title :
          </div>
          {paper_title && (
            <div className="text-14-300-18 color-6866 cmb-24">
              {titleCaseString(paper_title)}
            </div>
          )}

          <div className="text-14-500-17 color-6866 cmb-12">
            Recommendation :
          </div>
          {recommendation && (
            <div className="text-14-300-18 color-6866 cmb-24">
              {titleCaseString(recommendation)}
            </div>
          )}

          <div className="text-14-500-17 color-6866 cmb-12">
            General Comments :
          </div>
          {general_comment && (
            <div className="text-14-300-18 color-6866">
              {titleCaseString(general_comment)}
            </div>
          )}
        </div>
        <div className="col-lg-5">
          <div className="text-14-500-17 color-6866 cmb-12">
            Followed Rates :
          </div>
          <div className="text-14-300-17 color-6866 cmb-24">
            (1 = Poor) , (2 = Fair) , (3 = Good) , (4 = Excellent)
          </div>
          <div className="border br-5 cpt-25 cpb-16">
            <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
              Evaluation Report
            </div>
            <div className="cpt-23 cpb-16 border-bottom">
              {scores?.map((item, index) => (
                <div
                  className="d-flex align-items-center cmb-16 text-14-500-17 color-6866 row"
                  key={index}
                >
                  <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                    {item?.label}{" "}
                    <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                  </div>
                  <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                    {item?.score}
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center text-14-500-17 color-6866 cpt-16 row">
              <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                Overall
                <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
              </div>
              <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                {`${overall} ${
                  overall > 0.0
                    ? `(${
                        overall <= 1
                          ? "Poor"
                          : overall <= 2
                          ? "Fair"
                          : overall <= 3
                          ? "Good"
                          : "Excellent"
                      })`
                    : ""
                }`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-16-500-20 color-6866 cmb-28 d-flex">
        Abstract Result :<div className="ms-4">{abstract_result}</div>
      </div>

      <div className="text-16-500-20 color-6866 cmb-28 d-flex">
        Status :
        <div className="ms-4">
          {paperRevision
            ? "Revision Sent !!"
            : paperRejected
            ? "Review has been Rejected !!"
            : "Review has been completed !!"}
        </div>
      </div>

      {/* <div className="text-16-500-20 color-1717 cmb-19">
        Revision Raised {index + 1}:
      </div>
      <div className="text-16-500-20 color-1717 cmb-19">
        Needed Revision Reason:{" "}
        {titleCaseString(reviewer_revision_reasons?.[index]?.revision_comment)}
      </div> */}
    </div>
  );
};

export default OldReviewReport;
