import Label from "components/form/Label";
import { icons, limit } from "utils/constants";
import { useEffect, useRef, useState } from "react";
import Loader from "components/Layout/Loader";
import { useDispatch } from "react-redux";
import { fetchEmailReciever, throwError } from "store/slices";
import { omit } from "lodash";
import ListGroup from "react-bootstrap/ListGroup";
import "./NewUserDropdown.scss";

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex?.test(email);
};

const NewUserDropdown = ({
  id,
  label,
  isRequired,
  labelClass,
  error,
  placeholder,
  handleInvite,
  onChange,
  existingList,
  handleDelete,
  existingAuthor,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef();
  const myRef = useRef();
  const [memberLoading, setMemberLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState("");
  const [userDetails, setUserDetails] = useState({
    userList: [],
    offset: 0,
    limit: limit,
    name: "",
    total: 0,
  });

  const handleUserSelect = (e) => {
    const isExistingAuthor = e?.email_id === existingAuthor?.email;

    const userAlreadySelected = existingList?.some(
      (user) => user?.email === e?.email_id
    );

    if (isExistingAuthor || userAlreadySelected) {
      const message = isExistingAuthor
        ? "Co-Author already exists as Author."
        : "Co-Author already selected.";
      dispatch(throwError({ message }));
      return;
    }
    onChange({
      target: {
        id: id,
        value: e,
      },
    });
    setSearchText("");
  };
  const handelSearch = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setSearchText(val.toLowerCase());
    if (!val?.includes("@")) {
      // Disable suggestions if "@" is not present
      setUserDetails((prev) => ({ ...prev, userList: [] }));
      return;
    }
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let oldData = JSON.parse(JSON.stringify(userDetails));
      oldData.offset = 0;
      oldData.name = val.toLowerCase();
      listRef?.current?.scrollTo(0, 0);
      setUserDetails(oldData);
      getProfiles(oldData, true);
    }, 800);
    setTimer(time);
  };
  const handelScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let oldData = JSON.parse(JSON.stringify(userDetails));
      oldData.offset = userDetails.offset + limit;
      setUserDetails(oldData);
      getProfiles(oldData);
    }
  };
  const getProfiles = async (obj, isReset) => {
    let queryParams = new URLSearchParams(
      omit(obj, ["userList", "total"])
    ).toString();
    const response = await dispatch(fetchEmailReciever(queryParams));
    const resData = response?.data?.user_data || [];
    setUserDetails((prev) => {
      let newData = isReset ? resData : [...prev.userList, ...resData];
      return {
        ...prev,
        total: response?.data?.result_count,
        userList: newData,
      };
    });
    if (response?.status === 200) {
    }
    setMemberLoading(false);
  };
  const handleClickOutside = (e) => {
    if (
      myRef &&
      myRef?.current &&
      !myRef.current.contains(e.target) &&
      !e?.target?.classList?.contains("list-group-item")
    ) {
      setSearchText("");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  useEffect(() => {
    getProfiles(userDetails, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { userList, total } = userDetails;
  let newUserList =userList?.filter((o)=>o?.user_type !=="0")
  let showInviteButton = newUserList?.length === 0;



  return (
    <div className="multiple-user-select">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <div className="multiple-input-container" ref={myRef}>
        {existingList?.length === 0 && (
          <div className="d-flex mt-1">
            <img src={icons?.userOutline} alt="user" />
          </div>
        )}
        <div className="center-input">
          <div className="d-flex flex-wrap gap-2">
            {existingList?.map((elm, index) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center gap-2 text-12-500 color-new-car bg-edff br-2 py-1 px-2"
                >
                  <span>{elm?.name}</span>
                  <span className="d-flex color-new-car text-18-500">
                    <i
                      className="bi bi-x remove-user"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    />
                  </span>
                </div>
              );
            })}
            <div className="searc-center-input">
              <input
                type="text"
                placeholder={placeholder}
                onChange={handelSearch}
                value={searchText}
              />
            </div>
          </div>
        </div>
        {showInviteButton && isValidEmail(searchText) && (
          <div className="d-flex align-items-center gap-2">
            <div
              className="invite-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleInvite();
              }}
            >
              Invite
            </div>
            {memberLoading ? (
              <Loader size="sm" />
            ) : (
              <i className="bi bi-chevron-down pointer color-gray" />
            )}
          </div>
        )}
      </div>
      {searchText && (
        <div
          className="search-list-block shadow rounded iferp-scroll mt-1"
          ref={listRef}
          onScroll={(e) => {
            if (newUserList?.length < total) {
              handelScroll(e);
            }
          }}
        >
          <ListGroup>
            {newUserList?.map((elem, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleUserSelect(elem);
                  }}
                >
                  {elem?.name}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      )}
      {error && (
        <span className="text-13-400 pt-1">
          <span style={{ color: "red" }}>{error}</span>
        </span>
      )}
    </div>
  );
};

export default NewUserDropdown;
