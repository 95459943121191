import React from "react";
import { helpDeskMailIFERP } from "utils/helpers";
// Session name inclusion notification
const FirstStepTemplate = ({ values }) => {
  const {
    client_name,
    conference_name,
    conference_organizer,
    conference_date,
    conference_location,
    session_title,
    brief_overview,
    paper_title,
    relevant_field,
    view_session_details,
    // support_email,
    your_organization_name,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `Dear ${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        Greetings from{" "}
        <strong>
          {conference_organizer
            ? conference_organizer
            : `[Conference Organizer/IFERP]`}
          !
        </strong>
      </p>

      <p>
        We are excited to provide you with the details of your upcoming session
        at{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>{" "}
        accredited by{" "}
        <strong> Continuous Professional Development (CPD) </strong>, which will
        take place on{" "}
        <strong>
          {" "}
          {conference_date ? conference_date : "[Conference Date]"}{" "}
        </strong>
        at{" "}
        <strong>
          {" "}
          {conference_location ? conference_location : "[Conference Location]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Session Details:</strong>
      </h6>

      <ul>
        <li>
          <strong>Session Title:</strong>{" "}
          {session_title ? session_title : "[Session Title]"}
        </li>
        <li>
          <strong>Session Overview:</strong>{" "}
          {brief_overview
            ? brief_overview
            : "[Brief overview of the session content and objectives]"}
        </li>
      </ul>

      <p>
        Your Paper: {paper_title ? paper_title : [`Paper Title`]} will present
        groundbreaking research and innovative approaches in{" "}
        <strong> {relevant_field ? relevant_field : "[Relevant Field]"}</strong>
        . Your work will be a key contribution to the discussions on this topic.
      </p>

      <p>
        We are thrilled to have you present and contribute to the dynamic
        exchange of ideas at the conference. Both your paper and the event align
        with the{" "}
        <strong>United Nations’ 17 Sustainable Development Goals (SDGs)</strong>
        , furthering impactful research that promotes global progress.
      </p>

      <h6>
        <strong>Conference Highlights:</strong>
      </h6>
      <ul>
        <li>
          <strong>Scopus Publication Opportunities:</strong> Offering enhanced
          visibility for your research through international indexing.
        </li>
        <li>
          <strong>Enhance Your Professional Development :</strong> Earn a CPD
          Certificate from an accredited provider, enjoy a 1-year free iCPD
          membership, and claim CPD hours/points by participating in our
          accredited conferences and training activities.
        </li>
        <li>
          <strong>Awards and Certifications:</strong> Internationally accredited
          recognitions that will boost your academic and professional profile.
        </li>
        <li>
          <strong>Scholarships:</strong> Available to support broader
          participation and inclusion.
        </li>
        <li>
          <strong>Networking Opportunities:</strong> Connect with keynote
          speakers and global thought leaders, enriching your professional
          journey.
        </li>
      </ul>

      <h6>
        <strong>Next Steps:</strong>
      </h6>

      <p>
        To view or manage your session details, please follow the link below:
      </p>
      {view_session_details ? (
        <p>
          <a href={view_session_details}>Click Here</a>
        </p>
      ) : (
        <p>
          <strong>CTA: </strong>
          [View Session Details Link]
        </p>
      )}

      <p>
        If you have any questions or require additional information, feel free
        to reach out to us at{" "}
        <strong>
          <a
            href={`mailto:${helpDeskMailIFERP}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {helpDeskMailIFERP}
          </a>
        </strong>
        .
      </p>

      <p>
        We look forward to your engaging presentation and the thought-provoking
        discussions it will inspire!
      </p>

      <div>Best regards,</div>
      <div>
        {your_organization_name
          ? your_organization_name
          : "[Your Name/Your Organization]"}
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>Click Here</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default FirstStepTemplate;
