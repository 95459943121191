import React from "react";
import { useDispatch } from "react-redux";
import { eventAcceptanceLetterPath, icons } from "utils/constants";
import { downloadFile, generatePreSignedUrl } from "utils/helpers";

const UserAcceptanceState = ({ data, paperData }) => {
  const dispatch = useDispatch();
  const { status, is_remodify } = data || {};
  const isReviewDone = is_remodify === "2";
  const { submittedPapersStatus } = paperData || {};
  const { acceptance_letter } = submittedPapersStatus || {};
  const acceptanceStateDone = acceptance_letter && status >= 4;
  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            isReviewDone && status >= 4 ? "active" : "not-active"
          }`}
        >
          {acceptanceStateDone ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "4"
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isReviewDone && status >= 4 ? "color-new-car" : "color-6866"
            }`}
          >
            Acceptance{" "}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        {isReviewDone && status >= 3 ? (
          <div>
            <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
              {acceptance_letter
                ? "You has been accepted !!"
                : "Acceptance is under processing"}
            </div>
            {acceptance_letter && status >= 4 && (
              <div className="cmb-16 text-16-500-20 color-7cff d-flex gap-2 align-items-center">
                <span
                  className={`${
                    acceptance_letter ? "pointer" : ""
                  } border-end cpe-10`}
                  onClick={async () => {
                    let downloadContent = "";
                    if (acceptance_letter) {
                      downloadContent = await generatePreSignedUrl(
                        acceptance_letter,
                        eventAcceptanceLetterPath
                      );
                    }
                    window.open(downloadContent, "_blank");
                  }}
                >
                  View
                </span>
                <span
                  className={`${acceptance_letter ? "pointer" : ""}`}
                  onClick={async () => {
                    let downloadContent = "";
                    if (acceptance_letter) {
                      downloadContent = await generatePreSignedUrl(
                        acceptance_letter,
                        eventAcceptanceLetterPath
                      );
                    }

                    dispatch(downloadFile(downloadContent));
                  }}
                >
                  Download Acceptance Letter
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="w-100 cpt-20 cpb-20"></div>
        )}
      </div>
    </>
  );
};

export default UserAcceptanceState;
