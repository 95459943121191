import DatePicker from "components/form/DatePicker";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import React from "react";
// Session name inclusion notification
const FirstStepOfFollowUps = ({ props }) => {
  const { handleChange, values } = props;
  const {
    client_name,
    conference_name,
    conference_organizer,
    conference_date,
    conference_location,
    session_title,
    brief_overview,
    paper_title,
    relevant_field,
    view_session_details,
    // support_email,
    your_organization_name,
    your_contact_information,
    your_website,
  } = values;
  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Organizer
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_organizer"
            onChange={handleChange}
            value={conference_organizer}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Location
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Session Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="session_title"
            onChange={handleChange}
            value={session_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Session Brief
        </div>
        <div className="col-lg-6">
          <TextArea
            id="brief_overview"
            onChange={handleChange}
            value={brief_overview}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Paper Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="paper_title"
            onChange={handleChange}
            value={paper_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Relevant Field
        </div>
        <div className="col-lg-6">
          <TextInput
            id="relevant_field"
            onChange={handleChange}
            value={relevant_field}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          View Session Details Link
        </div>
        <div className="col-lg-6">
          <TextInput
            id="view_session_details"
            onChange={handleChange}
            value={view_session_details}
          />
        </div>
      </div>

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Support Email
        </div>
        <div className="col-lg-6">
          <TextInput
            id="support_email"
            onChange={handleChange}
            value={support_email}
          />
        </div>
      </div> */}

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Organization’s Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_organization_name"
            onChange={handleChange}
            value={your_organization_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
          />
        </div>
      </div>
    </>
  );
};

export default FirstStepOfFollowUps;
