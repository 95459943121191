import DatePicker from "components/form/DatePicker";
import TextInput from "components/form/TextInput";
import React from "react";
// Coupon or Voucher follow ups/early bird deadline
const SecondStepOfFollowUps = ({ props }) => {
  const { handleChange, values } = props;
  const {
    coupon_code,
    conference_name,
    client_name,
    conference_date,
    conference_location,
    discount_amount,
    expiration_date,
    register_now,
    // support_email,
    your_name,
    // your_title,
    // your_contact_information,
    // your_website,
  } = values;
  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Coupon Code
        </div>
        <div className="col-lg-6">
          <TextInput
            id="coupon_code"
            onChange={handleChange}
            value={coupon_code}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Venue
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Discount Percentage
        </div>
        <div className="col-lg-6">
          <TextInput
            id="discount_amount"
            onChange={handleChange}
            value={discount_amount}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Expiration Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="expiration_date"
            onChange={handleChange}
            value={expiration_date}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Register Now
        </div>
        <div className="col-lg-6">
          <TextInput
            id="register_now"
            onChange={handleChange}
            value={register_now}
          />
        </div>
      </div>

      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Support Email
        </div>
        <div className="col-lg-6">
          <TextInput
            id="support_email"
            onChange={handleChange}
            value={support_email}
          />
        </div>
      </div> */}

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput id="your_name" onChange={handleChange} value={your_name} />
        </div>
      </div>
      {/* <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
          />
        </div>
      </div> */}
    </>
  );
};

export default SecondStepOfFollowUps;
