import React from "react";
import { titleCaseString } from "utils/helpers";

const leadColorWithBG = (values = "") => {
  if (values) {
    if (values === "Warm") {
      return "lead-status-warm";
    } else if (values === "Hot") {
      return "lead-status-hot";
    } else if (values === "Cold") {
      return "lead-status-cold";
    } else {
      return "lead-status-default";
    }
  }
};

const BasicInfo = ({ paperData }) => {
  const {
    conference,
    paperTitle,
    paperId,
    memberId,
    lead,
    paper_keywords,
    authorName,
    co_author_details,
    authorEmail,
    authorPhone,
    whatapp_number,
    authorCountry,
  } = paperData || {};

  const isLatestPaper = paperData?.latest_paper_title ? paperData?.latest_paper_title :""
  let newPaperTitle = isLatestPaper ? paperData?.latest_paper_title :paperTitle

  return (
    <div className="cps-44 cpt-15 cpe-44 cpb-15">
      <div className="row custom-border-bottom gx-0 cpb-10 normal-screen-header">
        <div className="col-lg-8">
          <h2 className="text-16-400-20 color-new-car">
            Conference & Abstract Info :
          </h2>
        </div>
        <div className="col-lg-4 cps-20">
          <h2 className="text-16-400-20 color-new-car">
            Author’s & Contact Info :
          </h2>
        </div>
      </div>

      <div className="row gx-lg-0 gy-5">
        <div className="col-lg-8 custom-border-end pt-2">
          <h2 className="text-16-400-20 color-new-car cpb-10 mobile-screen-header">
            Conference & Abstract Info :
          </h2>
          {conference && (
            <>
              <div className="text-14-400-17 color-new-car">
                Conference Name:
              </div>
              <p className="text-14-300-24 color-black-olive cmb-20">
                {titleCaseString(conference)}
              </p>
            </>
          )}
          {newPaperTitle && (
            <>
              <div className="text-14-400-17 color-new-car">Paper Title:</div>
              <p className="text-14-300-24 color-black-olive cmb-20">
                {titleCaseString(newPaperTitle)}
              </p>
            </>
          )}
          <div className="row cmb-20 gy-3">
            {paperId && (
              <div className="col-lg-6">
                <div className="text-14-400-17 color-new-car">Paper ID:</div>
                <span className="text-14-300-24 color-black-olive">
                  {paperId}
                </span>
              </div>
            )}
            {memberId && (
              <div className="col-lg-6">
                <div className="text-14-400-17 color-new-car">Member ID:</div>
                <span className="text-14-300-24 color-black-olive">
                  {memberId}
                </span>
              </div>
            )}
          </div>

          {paper_keywords?.length > 0 && (
            <div className="d-flex align-items-center gap-4">
              <div className="text-14-400-17 color-new-car">Keywords:</div>
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {paper_keywords?.map((words, index) => {
                  return (
                    <span
                      key={index}
                      className="bg-f4ff color-new-car cps-8 cpe-8 cpt-2 cpb-2 br-2 text-14-400-17"
                    >
                      {words}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-4 custom-padding">
          <h2 className="text-16-400-20 color-new-car cpb-10 mobile-screen-header">
            Author’s & Contact Info :
          </h2>
          <div className="d-flex align-items-center gap-2 cmb-20">
            {authorName && (
              <>
                <div className="text-14-400-17 color-new-car">Author:</div>
                <div className="d-flex align-items-center text-14-300-17 color-black-olive me-2">
                  {authorName}
                </div>
              </>
            )}
            {lead && (
              <span className={`${leadColorWithBG(lead)} text-14-400-17`}>
                {lead}
              </span>
            )}
          </div>

          {co_author_details?.length > 0 && (
            <>
              <div className="text-14-400-17 color-new-car cmb-8">
                Co Author:
              </div>
              <div className="d-flex align-items-center gap-2 flex-wrap cmb-20">
                {co_author_details?.map((coAuthor, index) => {
                  return (
                    <span
                      key={index}
                      className="bg-f4ff color-new-car cps-8 cpe-8 cpt-2 cpb-2 br-2 text-14-400-17"
                    >
                      {coAuthor?.name}
                    </span>
                  );
                })}
              </div>
            </>
          )}
          {authorEmail && (
            <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2 cmb-20">
              <i className="bi bi-envelope" /> {authorEmail}
            </div>
          )}
          {whatapp_number && (
            <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2 cmb-20">
              <i className="bi bi-whatsapp" /> {whatapp_number}
            </div>
          )}
          {authorPhone && (
            <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2 cmb-20">
              <i className="bi bi-telephone" /> {authorPhone}
            </div>
          )}
          {authorCountry && (
            <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-2">
              <i className="bi bi-globe-americas" /> {authorCountry}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
