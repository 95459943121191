import React from "react";
import { icons } from "utils/constants";

const AttendanceState = ({ status, data, paperData, handleSuccess }) => {
  const { payment_status, attendanceStatus, registration_pass } =
    paperData || {};
  const paymentIsAccepted = ["1"].includes(payment_status);
  const isActiveState = status >= 5 && paymentIsAccepted;
  const isAttendancePending = attendanceStatus === "0" && registration_pass;
  const isAttendanceCompleted = attendanceStatus === "1" && registration_pass;
  const isAbsent = attendanceStatus === "2" && registration_pass;
  const isAttendanceStepIsDone = isAttendancePending && status === 6;

  
  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${isActiveState ? "active" : "not-active"}`}
        >
          {isAttendanceStepIsDone ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            6
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveState ? "color-new-car" : "color-6866"
            }`}
          >
            Attendance
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        <div className="w-100 cpt-20 cpb-20">
          {isAttendancePending && (
            <div className="cpt-10 cpb-10 w-100 text-14-400-18 color-black-olive">
              Please take the attendance.
            </div>
          )}
          {isAttendanceCompleted && (
            <div className="cpt-10 cpb-10 w-100 text-14-400-18 color-black-olive">
              You have successfully recorded attendance for the conference.
            </div>
          )}
          {isAbsent && (
            <div className="cpt-10 cpb-10 w-100 text-14-400-18 color-black-olive">
              The user was not present at the conference.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AttendanceState;
