import Button from "components/form/Button";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import Modal from "components/Layout/Modal";
import Profile from "components/Layout/Profile";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMentorDetails } from "store/slices";
import { icons } from "utils/constants";
import {
  getDataFromLocalStorage,
  getUserType,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import EducationDetails from "../ProfileManagement/CampaignManagement/EducationDetails";

const MentorProfilePopup = ({ onHide, userID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memberType = getUserType();
  const [loader, setLoader] = useState(false);
  const [mentorDetails, setMentorDetails] = useState({});
  const [isMoreRemark, setIsMoreRemark] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const userType = getDataFromLocalStorage("user_type");
  const fetchMentorDetails = async () => {
    setLoader(true);
    const response = await dispatch(
      getMentorDetails(objectToFormData({ mentor_id: userID }))
    );
    setMentorDetails(response?.data);
    setLoader(false);
  };

  useEffect(() => {
    fetchMentorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    name,
    profile,
    about_introduction,
    skills,
    // disciplines,
    country,
    institution,
    session,
    educationDetails,
  } = mentorDetails || {};

  const sessionsToShow = useMemo(() => {
    return showMore ? session : session?.slice(0, 6);
  }, [showMore, session]);

  const transformEducationDetails = (data) => {
    const levels = ["ug", "pg", "phd"];

    return levels
      .map((level) => ({
        courseType: level,
        courseName: data?.[`${level}_course`] || "",
        department: data?.[`${level}_department`] || "",
        university:
          data?.[`${level}_university`] ||
          data?.[`other_${level}_university`] ||
          "",
        institution: data?.[`${level}_institution`] || "",
        yearOfCompletion: data?.[`${level}_year_of_completion`] || "",
      }))
      ?.filter((item) => item?.courseName);
  };
  return (
    <Modal
      titleBesideClose={"Mentor Profile"}
      onHide={onHide}
      isTitleBorder
      size="xl"
      width={"100%"}
      isTitleVerticalCenter
    >
      {loader ? (
        <Card className="pt-5 pb-5">
          <Loader size="sm" />
        </Card>
      ) : (
        <Card className="cps-16 cpe-16 cpt-20 cpb-20">
          <div className="row gy-3">
            <div className="col-lg-4 ">
              <div className="border rounded p-3 ">
                <div className="profile-block d-flex flex-column justify-content-center cmb-10 cmt-20">
                  <div className="cmb-20">
                    <Profile
                      isRounded
                      url={profile}
                      text={`${name}`}
                      size="s-168"
                      isS3UserURL
                      isRoundedBorder
                      isVerifiedIcon
                    />
                  </div>
                  <div className="text-center text-20-500 cmb-15">
                    {titleCaseString(`${name}`)}
                  </div>
                </div>
                {about_introduction && (
                  <p className="cmb-15">
                    {isMoreRemark
                      ? titleCaseString(about_introduction)
                      : `${titleCaseString(about_introduction)?.substring(
                          0,
                          100
                        )}${about_introduction?.length > 100 ? "..." : ""}`}

                    {about_introduction?.length > 100 && (
                      <span
                        onClick={() => {
                          setIsMoreRemark(!isMoreRemark);
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {isMoreRemark ? " Show less" : " Read more"}
                      </span>
                    )}
                  </p>
                )}

                <div className="color-new-car text-15-600 cmb-5">
                  Area Of Interest:
                </div>
                <div className="d-flex gap-3 flex-wrap align-items-center cmb-15">
                  {skills?.length > 0
                    ? skills?.map((data, i) => {
                        return (
                          <div
                            className="text-14-400 key-points color-dark-blue bg-f6fc text-nowrap cps-5 cpt-6 cpe-5 cpb-6 rounded"
                            key={i}
                          >
                            {data}
                          </div>
                        );
                      })
                    : "-"}
                </div>

                {/* <div className="text-15-600 cmb-15 d-flex gap-2 align-items-center flex-wrap">
                  Category:
                  <span>-</span>
                </div> */}

                <div className="text-15-600 cmb-5">Institution:</div>
                <p>
                  {institution
                    ? `${titleCaseString(institution)}, ${country}`
                    : "-"}
                </p>
              </div>
            </div>

            <div
              className="col-lg-8 h-100 overflow-x-hidden overflow-y-auto"
              style={{ maxHeight: "800px" }}
            >
              {session?.length > 0 && (
                <div className="cmb-20">
                  <div className="text-15-600 color-new-car cmb-15">
                    Sessions:
                  </div>
                  <div className="row g-3">
                    {sessionsToShow?.map((item, index) => {
                      const {
                        id,
                        session_name,
                        rating_star,
                        amount,
                        meeting_duration,
                        meeting_link,
                      } = item;
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="d-flex flex-column cpt-15 cpb-15 cps-15 cpe-15 bg-f6ff h-100 br-10">
                            <span className="cmb-15">
                              {titleCaseString(session_name)}
                            </span>

                            <div className="d-flex align-items-center justify-content-between flex-wrap cmb-15 gap-1">
                              {meeting_link && (
                                <div
                                  className={`d-flex gap-1 align-items-center`}
                                >
                                  <img src={icons.videoMeet} alt="meet" />
                                  <div className="text-14-400 color-dark-blue cpt-2">
                                    1:1 Video Meet
                                  </div>
                                </div>
                              )}
                              {meeting_duration && (
                                <div className="d-flex gap-1 align-items-center">
                                  <img src={icons.clockTime} alt="clock" />
                                  <div className="text-14-400 color-dark-blue">
                                    {meeting_duration}
                                  </div>
                                </div>
                              )}

                              {rating_star && (
                                <div className="d-flex gap-1 align-items-center text-nowrap">
                                  <img src={icons.star} alt="star" />

                                  <span className="text-14-500 color-dark-blue">
                                    {rating_star}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="d-flex flex-column flex-grow-1 justify-content-end">
                              <div
                                className={`${
                                  !["0", "6"].includes(userType)
                                    ? "cmb-15 align-items-center"
                                    : ""
                                } d-flex gap-2`}
                              >
                                <span className="text-16-500 color-black">{`₹ ${amount}`}</span>
                                {/* <span className="text-14-400 color-light-gray text-decoration-line-through">
                                {`₹ ${439}`}
                              </span>
                              <span className="text-14-400 color-bc60">
                                10% off
                              </span> */}
                              </div>

                              {!["0", "6"].includes(userType) && (
                                <div className="d-flex">
                                  <Button
                                    text="Book Now"
                                    btnStyle="primary-dark"
                                    className="h-35 text-14-500 cps-14 cpe-15"
                                    onClick={() => {
                                      navigate(
                                        `/${memberType}/mentorship/mentee/book-session/${id}`
                                      );
                                      onHide();
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {session?.length > 6 && (
                <div className="d-flex justify-content-center cmb-20">
                  <Button
                    text={showMore ? "Show Less" : "More Sessions"}
                    btnStyle="primary-outline"
                    className="h-35 text-14-500 cps-14 cpe-15"
                    onClick={() => setShowMore((prev) => !prev)}
                  />
                </div>
              )}

              {educationDetails?.ug_course && (
                <div className="border rounded p-3">
                  <div className="text-15-600 color-new-car cmb-20">
                    Education Details:
                  </div>
                  <EducationDetails
                    educationDetails={transformEducationDetails(
                      educationDetails
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default MentorProfilePopup;
