import { useDispatch } from "react-redux";
import BillingInfoCommonPopup from "../BillingInfoCommonPopup";
import { useState } from "react";
import { fetchProfile, savePaypalPaymentData } from "store/slices";
import { decrypt, encrypt, getDataFromLocalStorage } from "utils/helpers";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_SECRET,
  currency: "USD",
  intent: "capture",
};

const createItems = (selectedItems, completedPapers, totalValue, currency) => {
  const selectedItemsArray = selectedItems?.split(",");
  const items = [];
  const valueOfSingleItem = +totalValue / +selectedItemsArray?.length;
  selectedItemsArray?.forEach((itemId) => {
    const item = completedPapers?.find((paper) => paper?.id === +itemId);
    if (item) {
      items?.push({
        description: item?.id,
        name: item?.paper_title,
        quantity: "1",
        unit_amount: {
          currency_code: "USD",
          value: valueOfSingleItem?.toString(),
        },
      });
    }
  });
  return items;
};

const PaypalPay = ({ onClick, completedPapers, eventId, inrPrice }) => {
  const dispatch = useDispatch();
  const [isBillingInfoCommonPopup, setIsBillingInfoCommonPopup] =
    useState(false);

  const userDetails = getDataFromLocalStorage();
  const {
    first_name,
    last_name,
    phone_number,
    email_id,
    personal_details = {},
    institution_details = {},
    company_details = {},
    user_type,
    id: user_id,
  } = userDetails;
  const { address, pincode, city_name, state_name, country_name } =
    personal_details;
  const { address: institutionAddress, pincode: institutionPincode } =
    institution_details;
  const { address: companyAddress, pincode: companyPincode } = company_details;

  let billingName = `${first_name} ${last_name}`;
  let billingAddress = "";
  let billingCity = "";
  let billingState = "";
  let billingCountry = "";
  let billingTel = "";
  let billingEmail = "";
  let billingZip = "";

  switch (user_type) {
    case "2": // Professional
    case "5": // Student
      billingAddress = address || "NULL";
      billingCity = city_name || "NULL";
      billingState = state_name || "NULL";
      billingCountry = country_name || "NULL";
      billingTel = phone_number;
      billingEmail = email_id;
      billingZip = pincode;

      break;

    case "3": // Institute
      const {
        address: institutionAddress,
        pincode: institutionPincode,
        city_name: institutionCity,
        country_name: institutionCountry,
        state_name: institutionState,
        institution_contact_number,
        institution_email_id,
      } = institution_details;
      billingAddress = institutionAddress || "NULL";
      billingCity = institutionCity || "NULL";
      billingState = institutionState || "NULL";
      billingCountry = institutionCountry || "NULL";
      billingTel = institution_contact_number;
      billingEmail = institution_email_id;
      billingZip = institutionPincode;

      break;

    case "4": // Corporate
      const {
        address: company_address,
        pincode: company_pincode,
        company_email_id,
        company_contact_number,
        company_state_name,
        company_country_name,
        company_city_name,
      } = company_details;
      billingAddress = company_address || "NULL";
      billingCity = company_city_name || "NULL";
      billingState = company_state_name || "NULL";
      billingCountry = company_country_name || "NULL";
      billingTel = company_contact_number;
      billingEmail = company_email_id;
      billingZip = company_pincode;

      break;

    default:
      break;
  }

  const fetchUSerProfile = async () => {
    const response = await dispatch(fetchProfile());
    if (response?.status === 200) {
      setTimeout(() => {
        setIsBillingInfoCommonPopup(false);
        onClick();
      }, 500);
    }
  };

  const createOrder = (data, actions) => {
    const paymentIntent = localStorage.paymentIntent
      ? decrypt(localStorage.paymentIntent)
      : {};

    const purchaseUnit = {
      amount: {
        currency_code: "USD",
        value:
          paymentIntent?.currency === "INR"
            ? (paymentIntent?.amount * inrPrice)?.toFixed(2)
            : paymentIntent?.amount,
      },
      invoice_id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
    };
    if (paymentIntent?.formInitialValue?.join_as === "presenter") {
      purchaseUnit.amount.breakdown = {
        item_total: {
          currency_code: "USD",
          value:
            paymentIntent?.currency === "INR"
              ? (paymentIntent?.amount * inrPrice)?.toFixed(2)
              : paymentIntent?.amount,
        },
      };
      purchaseUnit.items = [];
      purchaseUnit.items = createItems(
        paymentIntent?.formInitialValue?.selected_papers,
        completedPapers,
        paymentIntent?.currency === "INR"
          ? (paymentIntent?.amount * inrPrice)?.toFixed(2)
          : paymentIntent?.amount,
        paymentIntent?.currency
      );
    }

    return actions.order.create({
      purchase_units: [purchaseUnit],
      experience_context: {
        return_url: "https://www.google.com",
        cancel_url: "https://www.youtube.com",
      },
    });
  };

  const handlePaymentClick = () => {
    if ((user_type === "2" || user_type === "5") && (!address || !pincode)) {
      setIsBillingInfoCommonPopup(true);
      return;
    } else if (
      user_type === "3" &&
      (!institutionAddress || !institutionPincode)
    ) {
      setIsBillingInfoCommonPopup(true);
      return;
    } else if (user_type === "4" && (!companyAddress || !companyPincode)) {
      setIsBillingInfoCommonPopup(true);
      return;
    }
    onClick();
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      const paymentIntent = localStorage.paymentIntent
        ? decrypt(localStorage.paymentIntent)
        : {};

      const billingData = {
        type: paymentIntent?.type,
        amount:
          paymentIntent?.currency === "INR"
            ? (paymentIntent?.amount * inrPrice)?.toFixed(2)
            : paymentIntent?.amount,
        currency: "USD",
        merchant_id: process.env.REACT_APP_MERCHANT_ID,
        language: "EN",
        billing_zip: billingZip,
        billing_name: billingName,
        billing_address: billingAddress,
        billing_city: billingCity,
        billing_state: billingState,
        billing_country: billingCountry,
        billing_tel: billingTel,
        billing_email: billingEmail,
        join_as: paymentIntent?.formInitialValue?.join_as,
        event_id: eventId,
        user_id: user_id,
        how_you_know: paymentIntent?.formInitialValue?.how_you_know,
        strength_of_students:
          paymentIntent?.formInitialValue?.strength_of_students,
        strength_of_faculties:
          paymentIntent?.formInitialValue?.strength_of_faculties,
      };

      const payload = {
        paypalPaymentData: encrypt(details),
        data: encrypt(billingData),
      };
      const response = await dispatch(savePaypalPaymentData(payload));
      if (response?.data) {
        if (response?.data?.status === "COMPLETED") {
          const paymentResponse = {
            order_status: "Success",
            status_message: "Payment Successful",
            payment_type: "PAYPAL",
          };
          localStorage.paymentResponse = encrypt(paymentResponse);

          window.location.href = paymentIntent?.toURL;
        } else {
          const paymentResponse = {
            order_status: "Failed",
            status_message: "Payment Failed",
            payment_type: "PAYPAL",
          };
          localStorage.paymentResponse = encrypt(paymentResponse);

          window.location.href = paymentIntent?.toURL;
        }
      }
    });
  };

  const onCancel = (data, actions) => {
    console.log("Payment cancelled", data, actions);
  };

  const onError = (err, actions) => {
    console.log("Error", err, actions);
  };

  return (
    <>
      {isBillingInfoCommonPopup && (
        <BillingInfoCommonPopup
          onHide={() => {
            setIsBillingInfoCommonPopup(false);
          }}
          onSuccess={() => {
            fetchUSerProfile();
          }}
        />
      )}
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{ layout: "horizontal", shape: "pill" }}
          onClick={() => handlePaymentClick()}
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          onCancel={(data, actions) => onCancel(data, actions)}
          onError={(data, actions) => onError(data, actions)}
        />
      </PayPalScriptProvider>
    </>
  );
};

export default PaypalPay;
