import React, { useState } from "react";
import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import TextInput from "components/form/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  convertString,
  getPhoneNumberLength,
  numberOnlyFromInput,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { errorMsgForMobile } from "utils/constants";
import DatePicker from "components/form/DatePicker";
import moment from "moment";
import InstitutionDropdown from "components/form/InstitutionDropdown";
import UniversityDropdown from "components/form/UniversityDropdown";
import { isEqual, pickBy, unionBy } from "lodash";
import CourseDropdown from "components/form/CourseDropdown";
import {
  setIsEditDataOfProfile,
  setIsEditUserProfile,
  throwError,
  throwSuccess,
  UpdateDetailsOfUsers,
} from "store/slices";

const EditPersonalEduDetails = ({ profileData, setIsEditDetails }) => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.global);
  const { membershipList, departmentList } = reduxData || {};
  const [isLoading, setIsLoading] = useState(false);
  const {
    user_id,
    first_name,
    last_name,
    email_id,
    country_code = "IN",
    phone_number,
    user_type,
    memberType,
    educational_details = {},
    personal_details = {},
  } = profileData;

  const { user_id: personal_user_id } = personal_details;

  const {
    ug_course,
    ug_department,
    ug_university,
    ug_university_name,
    ug_institution,
    ug_institution_name,
    ug_year_of_completion,
    pg_course,
    pg_department,
    pg_university,
    pg_university_name,
    pg_institution,
    pg_institution_name,
    pg_year_of_completion,
    phd_course,
    phd_department,
    phd_university,
    phd_university_name,
    phd_institution,
    phd_institution_name,
    phd_year_of_completion,
    other_ug_university,
    other_ug_institution,
    other_pg_university,
    other_pg_institution,
    other_phd_university,
    other_phd_institution,
  } = educational_details;

  const getMemberId = (name) => {
    let returnValue = "";
    const findObj = membershipList.find((o) => o.name === name);
    if (findObj) {
      returnValue = findObj.id;
    }
    return returnValue;
  };

  const handelValue = (value) => {
    return value && value !== "--Not Applicable--" ? value : "";
  };

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    membership_plan_id: getMemberId(memberType),
    email_id: email_id || "",
    phone_number: phone_number || "",
    country_code: country_code || "IN",
    mobile_no_length: getPhoneNumberLength(country_code) || 10,
    ug_course: handelValue(ug_course) || "",
    ug_course_name: "",
    ug_department: handelValue(ug_department) || "",
    ug_department_name: "",
    ug_university: handelValue(ug_university) || "",
    ug_university_name: "",
    ug_institution: handelValue(ug_institution) || "",
    ug_institution_name: "",
    ug_year_of_completion: handelValue(ug_year_of_completion) || "",
    pg_course: handelValue(pg_course) || "",
    pg_course_nme: "",
    pg_department: handelValue(pg_department) || "",
    pg_department_name: "",
    pg_university: handelValue(pg_university) || "",
    pg_university_name: "",
    pg_institution: handelValue(pg_institution) || "",
    pg_institution_name: "",
    pg_year_of_completion: handelValue(pg_year_of_completion) || "",
    phd_course: handelValue(phd_course) || "",
    phd_course_name: "",
    phd_department: handelValue(phd_department) || "",
    phd_department_name: "",
    phd_university: handelValue(phd_university) || "",
    phd_university_name: "",
    phd_institution: handelValue(phd_institution) || "",
    phd_institution_name: "",
    phd_year_of_completion: handelValue(phd_year_of_completion) || "",
    other_ug_university: other_ug_university || "",
    other_pg_university: other_pg_university || "",
    other_phd_university: other_phd_university || "",
    other_ug_institution: other_ug_institution || "",
    other_pg_institution: other_pg_institution || "",
    other_phd_institution: other_phd_institution || "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required."),
    last_name: Yup.string().required("Last name is required."),
    membership_plan_id: Yup.string().required("Member type is required."),
    email_id: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
    phone_number: Yup.lazy((_, obj) => {
      const { country_code, mobile_no_length } = obj?.parent || {};
      if (country_code) {
        return Yup.string()
          .required("Phone number is required.")
          .min(mobile_no_length, errorMsgForMobile(mobile_no_length)?.min)
          .max(mobile_no_length, errorMsgForMobile(mobile_no_length)?.max);
      } else {
        return Yup.string();
      }
    }),
    ug_course: Yup.lazy((_, obj) => {
      const {
        ug_university,
        ug_department,
        ug_institution,
        ug_year_of_completion,
      } = obj?.parent;
      if (
        ug_university ||
        ug_department ||
        ug_institution ||
        ug_year_of_completion
      ) {
        return Yup.string().required("UG course is required.");
      } else {
        return Yup.string();
      }
    }),
    ug_department: Yup.lazy((_, obj) => {
      const {
        ug_course,
        ug_university,
        ug_institution,
        ug_year_of_completion,
      } = obj?.parent;
      if (
        ug_course ||
        ug_university ||
        ug_institution ||
        ug_year_of_completion
      ) {
        return Yup.string().required("UG department is required.");
      } else {
        return Yup.string();
      }
    }),
    ug_university: Yup.lazy((_, obj) => {
      const {
        ug_course,
        ug_department,
        ug_institution,
        ug_year_of_completion,
      } = obj?.parent;
      if (
        ug_course ||
        ug_department ||
        ug_institution ||
        ug_year_of_completion
      ) {
        return Yup.string().required("UG university is required.");
      } else {
        return Yup.string();
      }
    }),
    ug_institution: Yup.lazy((_, obj) => {
      const { ug_course, ug_department, ug_university, ug_year_of_completion } =
        obj?.parent;
      if (
        ug_course ||
        ug_department ||
        ug_university ||
        ug_year_of_completion
      ) {
        return Yup.string().required("UG institution is required.");
      } else {
        return Yup.string();
      }
    }),
    ug_year_of_completion: Yup.lazy((_, obj) => {
      const { ug_course, ug_department, ug_university, ug_institution } =
        obj?.parent;
      if (ug_course || ug_department || ug_university || ug_institution) {
        return Yup.string().required("UG year of completion is required");
      } else {
        return Yup.string();
      }
    }),

    pg_course: Yup.lazy((_, obj) => {
      const {
        pg_department,
        pg_university,
        pg_year_of_completion,
        pg_institution,
        phd_course,
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        pg_department ||
        pg_university ||
        pg_year_of_completion ||
        pg_institution ||
        phd_course ||
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PG course is required.");
      } else {
        return Yup.string();
      }
    }),
    pg_department: Yup.lazy((_, obj) => {
      const {
        pg_course,
        pg_university,
        pg_year_of_completion,
        pg_institution,
        phd_course,
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        pg_course ||
        pg_university ||
        pg_year_of_completion ||
        pg_institution ||
        phd_course ||
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PG department is required.");
      } else {
        return Yup.string();
      }
    }),
    pg_university: Yup.lazy((_, obj) => {
      const {
        pg_department,
        pg_course,
        pg_year_of_completion,
        phd_course,
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        pg_department ||
        pg_course ||
        pg_year_of_completion ||
        phd_course ||
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PG university is required.");
      } else {
        return Yup.string();
      }
    }),
    pg_institution: Yup.lazy((_, obj) => {
      const {
        pg_department,
        pg_course,
        pg_year_of_completion,
        pg_university,
        phd_course,
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        pg_department ||
        pg_course ||
        pg_year_of_completion ||
        pg_university ||
        phd_course ||
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PG institution is required.");
      } else {
        return Yup.string();
      }
    }),
    pg_year_of_completion: Yup.lazy((_, obj) => {
      const {
        pg_course,
        pg_university,
        pg_department,
        pg_institution,
        phd_course,
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        pg_course ||
        pg_university ||
        pg_department ||
        pg_institution ||
        phd_course ||
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PG year of completion is required.");
      } else {
        return Yup.string();
      }
    }),

    phd_course: Yup.lazy((_, obj) => {
      const {
        phd_department,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        phd_department ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PHD course is required.");
      } else {
        return Yup.string();
      }
    }),
    phd_department: Yup.lazy((_, obj) => {
      const {
        phd_course,
        phd_university,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        phd_course ||
        phd_university ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PHD department is required.");
      } else {
        return Yup.string();
      }
    }),
    phd_university: Yup.lazy((_, obj) => {
      const {
        phd_department,
        phd_course,
        phd_year_of_completion,
        phd_institution,
      } = obj?.parent;
      if (
        phd_department ||
        phd_course ||
        phd_year_of_completion ||
        phd_institution
      ) {
        return Yup.string().required("PHD university is required.");
      } else {
        return Yup.string();
      }
    }),
    phd_institution: Yup.lazy((_, obj) => {
      const {
        phd_department,
        phd_course,
        phd_year_of_completion,
        phd_university,
      } = obj?.parent;
      if (
        phd_department ||
        phd_course ||
        phd_year_of_completion ||
        phd_university
      ) {
        return Yup.string().required("PHD institution is required.");
      } else {
        return Yup.string();
      }
    }),
    phd_year_of_completion: Yup.lazy((_, obj) => {
      const { phd_course, phd_university, phd_department, phd_institution } =
        obj?.parent;
      if (phd_course || phd_university || phd_department || phd_institution) {
        return Yup.string().required("PHD year of completion is required.");
      } else {
        return Yup.string();
      }
    }),
  });

  const handleUpdate = async (updatedFields) => {
    setIsLoading(true);
    const payload = {
      user_id: +user_id || personal_user_id,
      ...updatedFields,
      user_type: user_type,
    };
    const response = await dispatch(
      UpdateDetailsOfUsers(objectToFormData(payload))
    );

    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      setIsEditDetails(false);
      dispatch(setIsEditUserProfile(false));
      dispatch(setIsEditDataOfProfile(null));
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setIsLoading(false);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const changedFields = pickBy(
          values,
          (value, key) => !isEqual(value, initialValues[key])
        );
        handleUpdate(changedFields);
      }}
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
          resetForm,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <>
                <div className="text-18-500 title-text cmb-25">
                  Profile Details
                </div>
                <div className="col-md-6 cmb-22">
                  <TextInput
                    label="First Name"
                    placeholder="First Name*"
                    id="first_name"
                    value={values.first_name}
                    error={errors.first_name}
                    onChange={(e) => {
                      setFieldValue(
                        "first_name",
                        titleCaseString(e.target.value)
                      );
                    }}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name*"
                    id="last_name"
                    value={values.last_name}
                    error={errors.last_name}
                    onChange={(e) => {
                      setFieldValue(
                        "last_name",
                        titleCaseString(e.target.value)
                      );
                    }}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <Dropdown
                    label="Member Type"
                    id="membership_plan_id"
                    placeholder="Select Member Type"
                    options={membershipList}
                    value={values.membership_plan_id}
                    error={errors.membership_plan_id}
                    optionKey="id"
                    optionValue="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <TextInput
                    label="Email ID"
                    placeholder="Email ID*"
                    id="email_id"
                    value={values.email_id}
                    onChange={(e) => {
                      handleChange(convertString(1, e));
                    }}
                    error={errors.email_id}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <TextInput
                    isPhoneNumber
                    label="Phone Number"
                    placeholder="Phone Number*"
                    id="phone_number"
                    value={values.phone_number}
                    error={errors.phone_number}
                    phoneNumberData={{
                      id: "country_code",
                      value: values.country_code,
                    }}
                    onChange={(e) => {
                      if (e.target.id === "phone_number") {
                        handleChange(numberOnlyFromInput(e));
                      } else {
                        handleChange(e);
                        handleChange({
                          target: { id: "phone_number", value: "" },
                        });
                        setFieldValue("mobile_no_length", e.target.length);
                      }
                    }}
                  />
                </div>

                <div className="text-18-500 title-text cmb-25">
                  Education Details
                </div>

                <div className="mt-2 mb-2">Bachelor Degree/UG Details</div>
                <div className="col-md-6 cmb-22">
                  <CourseDropdown
                    id="ug_course"
                    courseType="ug"
                    value={values.ug_course}
                    error={errors.ug_course}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "ug_course_name",
                        e.target?.data?.courses_name
                      );
                    }}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <Dropdown
                    optionValue="name"
                    id="ug_department"
                    onChange={(e) => {
                      setFieldValue("ug_department_name", e.target?.data?.name);
                      handleChange(e);
                    }}
                    options={unionBy(departmentList, "name")}
                    value={values.ug_department}
                    error={errors.ug_department}
                    placeholder="Select Department"
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <UniversityDropdown
                    id="ug_university"
                    onChange={(e) => {
                      setFieldValue("ug_university_name", e.target?.data?.name);
                      handleChange(e);
                    }}
                    value={values.ug_university}
                    error={errors.ug_university}
                    existingList={
                      ug_university
                        ? [
                            {
                              id: +ug_university,
                              name: ug_university_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.ug_university === 581 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example University, Country"
                      id="other_ug_university"
                      value={values.other_ug_university}
                      error={errors.other_ug_university}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <InstitutionDropdown
                    id="ug_institution"
                    onChange={(e) => {
                      setFieldValue(
                        "ug_institution_name",
                        e.target?.data?.name
                      );
                      handleChange(e);
                    }}
                    value={values.ug_institution}
                    error={errors.ug_institution}
                    existingList={
                      ug_institution
                        ? [
                            {
                              id: +ug_institution,
                              name: ug_institution_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.ug_institution === 1772 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example Institution, Country"
                      id="other_ug_institution"
                      value={values.other_ug_institution}
                      error={errors.other_ug_institution}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <DatePicker
                    placeholder="Year of Completion"
                    id="ug_year_of_completion"
                    onChange={handleChange}
                    value={values.ug_year_of_completion}
                    error={errors.ug_year_of_completion}
                    maxDate={moment()}
                  />
                </div>
                <div className="col-md-6" />
                <div className="mb-2 mt-2">Master Degree/PG Details</div>
                <div className="col-md-6 cmb-22">
                  <CourseDropdown
                    isClearable
                    id="pg_course"
                    courseType="pg"
                    value={values.pg_course}
                    error={errors.pg_course}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue(
                        "pg_course_name",
                        e.target?.data?.courses_name
                      );
                    }}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <Dropdown
                    isClearable
                    id="pg_department"
                    optionValue="name"
                    onChange={(e) => {
                      setFieldValue("pg_department_name", e.target?.data?.name);
                      handleChange(e);
                    }}
                    options={unionBy(departmentList, "name")}
                    value={values.pg_department}
                    error={errors.pg_department}
                    disabled={!values.pg_course}
                    placeholder="Select Department"
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <UniversityDropdown
                    isClearable
                    id="pg_university"
                    onChange={(e) => {
                      setFieldValue("pg_university_name", e.target?.data?.name);
                      handleChange(e);
                    }}
                    value={values.pg_university}
                    error={errors.pg_university}
                    existingList={
                      pg_university
                        ? [
                            {
                              id: +pg_university,
                              name: pg_university_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.pg_university === 581 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example University, Country"
                      id="other_pg_university"
                      value={values.other_pg_university}
                      error={errors.other_pg_university}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <InstitutionDropdown
                    isClearable
                    id="pg_institution"
                    onChange={(e) => {
                      setFieldValue(
                        "pg_institution_name",
                        e.target?.data?.name
                      );
                      handleChange(e);
                    }}
                    value={values.pg_institution}
                    error={errors.pg_institution}
                    existingList={
                      pg_institution
                        ? [
                            {
                              id: +pg_institution,
                              name: pg_institution_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.pg_institution === 1772 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example Institution, Country"
                      id="other_pg_institution"
                      value={values.other_pg_institution}
                      error={errors.other_pg_institution}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <DatePicker
                    isClearable
                    placeholder="Year of Completion"
                    id="pg_year_of_completion"
                    onChange={handleChange}
                    value={values.pg_year_of_completion}
                    error={errors.pg_year_of_completion}
                    maxDate={moment()}
                  />
                </div>
                <div className="col-md-6" />
                <div className="mb-2 mt-2">
                  Doctorate/Ph.D Programme Details
                </div>
                <div className="col-md-6 cmb-22">
                  <CourseDropdown
                    isClearable
                    id="phd_course"
                    courseType="phd"
                    value={values.phd_course}
                    error={errors.phd_course}
                    onChange={(e) => {
                      setFieldValue(
                        "phd_course_name",
                        e.target?.data?.courses_name
                      );
                      handleChange(e);
                    }}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <Dropdown
                    isClearable
                    optionValue="name"
                    id="phd_department"
                    onChange={(e) => {
                      setFieldValue(
                        "phd_department_name",
                        e.target?.data?.name
                      );
                      handleChange(e);
                    }}
                    options={unionBy(departmentList, "name")}
                    value={values.phd_department}
                    error={errors.phd_department}
                    placeholder="Select Department"
                    disabled={!values.phd_course}
                  />
                </div>
                <div className="col-md-6 cmb-22">
                  <UniversityDropdown
                    isClearable
                    id="phd_university"
                    onChange={(e) => {
                      setFieldValue(
                        "phd_university_name",
                        e.target?.data?.name
                      );
                      handleChange(e);
                    }}
                    value={values.phd_university}
                    error={errors.phd_university}
                    existingList={
                      phd_university
                        ? [
                            {
                              id: +phd_university,
                              name: phd_university_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.phd_university === 581 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example University, Country"
                      id="other_phd_university"
                      value={values.other_phd_university}
                      error={errors.other_phd_university}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <InstitutionDropdown
                    isClearable
                    id="phd_institution"
                    onChange={(e) => {
                      setFieldValue(
                        "phd_institution_name",
                        e.target?.data?.name
                      );
                      handleChange(e);
                    }}
                    value={values.phd_institution}
                    error={errors.phd_institution}
                    existingList={
                      phd_institution
                        ? [
                            {
                              id: +phd_institution,
                              name: phd_institution_name,
                            },
                          ]
                        : []
                    }
                  />
                </div>
                {+values?.phd_institution === 1772 && (
                  <div className="col-md-6 cmb-22">
                    <TextInput
                      placeholder="ie. Example Institution, Country"
                      id="other_phd_institution"
                      value={values.other_phd_institution}
                      error={errors.other_phd_institution}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-6 cmb-22">
                  <DatePicker
                    isClearable
                    placeholder="Year of Completion"
                    id="phd_year_of_completion"
                    onChange={handleChange}
                    value={values.phd_year_of_completion}
                    error={errors.phd_year_of_completion}
                    maxDate={moment()}
                  />
                </div>
              </>

              <div className="d-flex justify-content-center gap-4 mt-4">
                <Button
                  isRounded
                  text="Cancel"
                  btnStyle="light-outline"
                  className="cps-50 cpe-50"
                  onClick={() => {
                    resetForm();
                    setIsEditDetails(false);
                  }}
                />
                <Button
                  isRounded
                  text={"Submit"}
                  btnStyle="primary-dark"
                  className="cps-40 cpe-40"
                  onClick={handleSubmit}
                  btnLoading={isLoading}
                  disabled={isEqual(initialValues, values)}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditPersonalEduDetails;
