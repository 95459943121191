import React from "react";

const ProfileProgressBar = ({ profileCount }) => {
  return (
    <div className="d-flex justify-content-between mb-3 gap-3">
      <div className="d-flex justify-content-between flex-column flex-grow-1 pt-1">
        <div
          className={`d-flex justify-content-between ${
            profileCount < 100 ? "mb-2" : "mb-2"
          }`}
        >
          <div className="text-15-500 color-moderate-blue">Profile Status</div>
          <div className="text-15-500 color-subtitle-navy">
            {profileCount >= 100 ? 100 : profileCount}%
          </div>
        </div>
        <div className="progress" style={{ height: "6px" }}>
          <div
            className="progress-bar bg-lime-green"
            role="progressbar"
            style={{ width: `${profileCount}%` }}
            aria-valuenow={profileCount}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileProgressBar;
