import { api } from "services";
import { handelCatch, handelResponse } from "./globalSlice";
import { saveData } from "./adminSlice";
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  accountsDetails: {},
  isAttendanceConferencePopup: false,
  isAttendanceConfirmationPopup: false,
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccountsDetails(state, action) {
      state.accountsDetails = action.payload;
    },
    setIsAttendanceConferencePopup(state, action) {
      state.isAttendanceConferencePopup = action.payload;
    },
    setIsAttendanceConfirmationPopup(state, action) {
      state.isAttendanceConfirmationPopup = action.payload;
    },
  },
});

// payment verification list and account management list
export const fetchPaymentsList = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/payments/list`, formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// export the payment verification list
export const exportPaymentVerificationList = (formData) => async (dispatch) => {
  try {
    const res = await api.post(
      `/admin/payments/export-abstract-payments`,
      formData,
      {}
    );
    const response = await dispatch(handelResponse(res));
    if (response?.data) {
      saveData(response?.data);
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

// single payment details
export const fetchPaymentDetails = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/admin/payments/get-single-payment?id=${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const addPaymentDetails = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/payments/check-payment`, payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const addAccount = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/add-accountant`, payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//edit accounts
export const updateAccount = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/edit-accountant`, payload);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//user, resource remarks
export const sendRemarks = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/payments/chatting-with-user`, formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//accounts list on admin side
export const fetchAllAccountsList = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/get-all-accountant`, formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//export the accounts list
export const exportAccountsList = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/export-accountant`, formData, {});
    const response = await dispatch(handelResponse(res));
    if (response?.data) {
      saveData(response?.data);
    }
    return response;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//delete the accounts user
export const deleteAccountsUser = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/admin/delete-accountant`, formData);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

//the accounts details
export const accountsDetails = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/admin/view-accountant/${id}`);
    return await dispatch(handelResponse(res));
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const {
  setAccountsDetails,
  setIsAttendanceConferencePopup,
  setIsAttendanceConfirmationPopup,
} = accountsSlice.actions;
export default accountsSlice.reducer;
