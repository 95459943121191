import React, { useRef, useState } from "react";
import AcceptanceTemplatePreview from "./AcceptanceTemplatePreview";
import { Formik } from "formik";
import * as Yup from "yup";
import AcceptanceTemplateForm from "./AcceptanceTemplateForm";
import { encrypt } from "utils/helpers";
import { useDispatch } from "react-redux";
import {
  generateAcceptanceTemplate,
  throwError,
  throwSuccess,
} from "store/slices";
import { icons } from "utils/constants";

const AcceptanceState = ({ status, data, paperData, handleSuccess }) => {
  const dispatch = useDispatch();
  const templatePreviewRef = useRef(null);
  const formRef = useRef();
  const [btnLoader, setBtnLoader] = useState(false);
  const { is_remodify } = data || {};
  const { submittedPapersStatus } = paperData || {};
  const { acceptance_letter } = submittedPapersStatus || {};
  const isActiveState = is_remodify === "2";
  const IsAcceptanceCompeted = status >= 4 && acceptance_letter;
  const prefillCoAuthors = paperData?.co_author_details
    ? paperData?.co_author_details?.map((o) => o?.name)?.join(", ")
    : "";
  const isLatestPaper = paperData?.latest_paper_title ? paperData?.latest_paper_title :""

  const initialValues = {
    client_name: paperData?.authorName || "",
    conference_organizer: paperData?.organizer_name || "",
    paper_title: isLatestPaper ? paperData?.latest_paper_title : paperData?.paperTitle || "",
    presentation_type: paperData?.presentation_type || "",
    conference_name: paperData?.conference || "",
    conference_short_name: paperData?.conference_short_name || "",
    date: paperData?.conference_date || "",
    location: paperData?.country || "",
    paper_id: paperData?.paperId || "",
    coAuthor_name: prefillCoAuthors || "",
    session_name: "",
    relevant_field: "",
    video_link: "",
    registration_deadline: paperData?.registration_deadline || "",
    complete_registration: "",
    support_email: "",
    conference_theme: "",
    your_organization_name: "",
    your_contact_information: "",
    your_website: "",
    preferences: {
      whatsapp: "",
      // sms: "",
      email: "",
    },
  };

  const validationSchema = Yup.object({
    client_name: Yup.string().required("Client name is required"),
    conference_organizer: Yup.string().required(
      "Conference organizer is required"
    ),
    paper_title: Yup.string().required("Paper title is required"),
    presentation_type: Yup.string().required("Presentation type is required"),
    conference_name: Yup.string().required("Conference name is required"),
    date: Yup.date().required("Date is required").nullable(),
    location: Yup.string().required("Location is required"),
    paper_id: Yup.string().required("Paper ID is required"),
    coAuthor_name: Yup.string(),
    session_name: Yup.string(),
    relevant_field: Yup.string().required("Relevant field is required"),
    video_link: Yup.string().url("Enter a valid URL"),
    registration_deadline: Yup.date()
      .required("Registration Date is required")
      .nullable(),
    complete_registration: Yup.string().required("link is required"),
    // support_email: Yup.string()
    //   .email("Enter a valid email")
    //   .required("Support email is required"),
    conference_theme: Yup.string().required("Conference theme is required"),
    your_organization_name: Yup.string().required(
      "Organization name is required"
    ),
    your_contact_information: Yup.string().required(
      "Contact information is required"
    ),
    your_website: Yup.string()
      .url("Enter a valid URL")
      .required("Website is required"),
    preferences: Yup.object().test(
      "at-least-one-selected",
      "Please select at least one contact preference",
      (values) => values.whatsapp || values.email
      // values.sms ||
    ),
  });

  const handleSave = async (values) => {
    setBtnLoader(true);
    if (templatePreviewRef.current) {
      const htmlContent = templatePreviewRef.current.innerHTML.trim();

      const payload = {
        paper_submitted_status: "4",
        id: paperData?.id,
        acceptance_letter: encrypt(htmlContent),
      };

      let formData = new FormData();
      for (let key in payload) {
        formData.append(key, payload[key]);
      }
      for (let key in values?.preferences) {
        formData.append(`send_via[]`, values?.preferences[key]);
      }

      const response = await dispatch(generateAcceptanceTemplate(formData));
      if (response.status === 200) {
        if (formRef.current) {
          formRef.current.resetForm();
        }
        dispatch(throwSuccess(response?.message));
        handleSuccess();
      } else {
        dispatch(throwError({ message: response?.messsage }));
      }

      setBtnLoader(false);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            isActiveState && status >= 3 ? "active" : "not-active"
          }`}
        >
          {IsAcceptanceCompeted ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "4"
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveState && status >= 3 ? "color-new-car" : "color-6866"
            }`}
          >
            Acceptance for the Conference
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cpt-14 cpb-20 w-100 acceptance-state-block">
          {IsAcceptanceCompeted ? (
            <div className="cpt-14 cpb-14 w-100 text-14-400-18 color-black-olive">
              Acceptance successfully completed !!
            </div>
          ) : isActiveState ? (
            <div className="cpt-20">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSave}
                innerRef={formRef}
              >
                {(props) => {
                  const { values } = props;

                  return (
                    <div className="row gy-4">
                      <div className="col-lg-6">
                        <AcceptanceTemplateForm
                          props={props}
                          initialValues={initialValues}
                          btnLoader={btnLoader}
                          paperData={paperData}
                        />
                      </div>
                      <div
                        className="col-lg-6 bg-f6ff overflow-auto"
                        // style={{ height: "538px" }}
                      >
                        <div className="cpt-21 cpe-21 cpb-21 cps-21">
                          <AcceptanceTemplatePreview
                            values={values}
                            templatePreviewRef={templatePreviewRef}
                            paperData={paperData}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          ) : (
            <div className="w-100 cpt-14 cpb-20"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default AcceptanceState;
