import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "components/form/Button";
import {
  objectToFormData,
  titleCaseString,
  trimLeftSpace,
} from "utils/helpers";
import { useDispatch } from "react-redux";
import TextArea from "components/form/TextArea";
import { throwError, throwSuccess } from "store/slices";
import { addComments } from "store/slices";
import "./CommentAddView.scss";

const CommentAddView = ({ icon, elem, handelSuccess, isPayment }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [commentVal, setCommentVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toggleDropdown = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
    setCommentVal("");
  };

  const handleComment = async () => {
    setIsLoading(true);
    let payload;
    if (isPayment) {
      payload = {
        abstract_paper_id: elem?.abstract_id,
        remarks: commentVal,
        remarks_type: "payment",
      };
    } else {
      payload = { abstract_paper_id: elem?.id, remarks: commentVal };
    }

    const response = await dispatch(addComments(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      setShow(false);
      handelSuccess();
      setCommentVal("");
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setIsLoading(false);
  };
  return (
    <Dropdown drop={"start"} show={show} onToggle={toggleDropdown}>
      <Button
        btnStyle="unset-primary"
        icon={icon}
        onClick={toggleDropdown}
        isSquare
      />

      {elem?.remarks?.length > 0 && (
        <span className="message-indicator bg-4646"></span>
      )}

      <Dropdown.Menu align="end" className="comment-menu-custom">
        <div className="cps-20 cpe-20 cpt-15 cpb-15">
          {elem?.remarks?.length > 0 && (
            <>
              <div className="d-flex justify-content-between cmb-10">
                <span className="text-14-400-20 color-black-olive">
                  Remarks
                </span>
                <i className="bi bi-x-lg pointer" onClick={handleClose} />
              </div>

              <div className="comments-list cmb-20">
                {elem?.remarks?.map((elm, index) => {
                  return (
                    <div className="d-flex flex-column cmb-20" key={index}>
                      <div className="text-12-400-15 color-6866 cmb-12">
                        {elm?.created_at}
                      </div>
                      <div className="text-14-400-20 color-black-olive">
                        {titleCaseString(elm?.remark)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <div className="d-flex justify-content-between cmb-10">
            <span className="text-14-400-20 color-black-olive">Comment</span>
            {elem?.remarks?.length === 0 && (
              <i className="bi bi-x-lg pointer" onClick={handleClose} />
            )}
          </div>
          <div className="cmb-10">
            <TextArea
              value={commentVal}
              rows={3}
              labelClass="text-15-400-18 color-black-olive"
              onChange={(e) => {
                setCommentVal(trimLeftSpace(e.target.value));
              }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
            <Button
              btnStyle="light-outline"
              text="Cancel"
              className="mw-100 max-w-100"
              onClick={handleClose}
            />
            <Button
              btnStyle="primary-dark"
              text={elem?.remarks?.length === 0 ? "Add" : "Update"}
              className="mw-100 max-w-100"
              onClick={handleComment}
              btnLoading={isLoading}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CommentAddView;
