import moment from "moment";
import React from "react";
// Full Paper Follow-Up Email
const EigthStepTemplate = ({ values }) => {
  const {
    conference_name,
    client_name,
    conference_date,
    conference_location,
    submission_date_line,
    guideline,
    submission_full_paper,
    contact_support,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `Dear ${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        Thank you for registering for{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"} accredited
          by Continuous Professional Development (CPD)
        </strong>
        , taking place on{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Dates]"}
        </strong>{" "}
        at{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        .
      </p>

      <p>
        As the conference approaches, we would like to remind you to submit your
        <strong> full paper</strong> by{" "}
        <strong>
          {moment(submission_date_line)?.format("YYYY-MM-DD")
            ? submission_date_line
            : "[Submission Deadline]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Submission Details:</strong>
      </h6>
      <ul>
        <li>
          <strong>Submission Deadline:</strong>{" "}
          {submission_date_line
            ? moment(submission_date_line)?.format("YYYY-MM-DD")
            : "[Submission Deadline]"}
        </li>
        <li>
          <strong>Paper Format:</strong> Please ensure your paper follows the{" "}
          {guideline ? (
            <a href={guideline}>Click Here</a>
          ) : (
            "[guidelines provided on our website]"
          )}
          .
        </li>
        <li>
          <strong>
            Submission Link:{" "}
            {submission_full_paper ? (
              <a href={submission_full_paper}>Click Here</a>
            ) : (
              "[Submit Full Paper]"
            )}
          </strong>
        </li>
      </ul>

      <p>
        Your full paper will undergo a rigorous review process, and selected
        papers will have the opportunity to be published in{" "}
        <strong>renowned Scopus and Web of Science indexed journals.</strong>
      </p>

      <p>
        We are excited to see your research contributions and look forward to
        your active participation at the conference. Should you have any
        questions or need assistance with your submission, please feel free to
        contact us.
      </p>

      <p>
        {contact_support ? (
          <a href={contact_support}>Click Here</a>
        ) : (
          "[Contact Support Link]"
        )}
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>Click Here</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default EigthStepTemplate;
