import React, { useEffect, useState } from "react";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import {
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
  userTypeByStatus,
} from "utils/helpers";
import Card from "components/Layout/Card";
import { useNavigate } from "react-router-dom";
import { limit } from "utils/constants";
import { useDispatch } from "react-redux";
import { exportMyTaskData, fetchMyReviewersAbstracts } from "store/slices";
import moment from "moment";
import SeachInput from "components/form/SeachInput";
import "./MyTask.scss";

const reviewStatusOption = [
  { name: "Busy", value: "Busy" },
  {
    name: "Not In my area",
    value: "Not In my area",
  },
  {
    name: "Conflict of interest",
    value: "Conflict of interest",
  },
  {
    name: "Accept to Review",
    value: "Accept to Review",
  },
];

const paperStatusOption = [
  {
    name: "Not Yet Started",
    value: "Not Yet Started",
  },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Completed" },
  { name: "Rejected", value: "Rejected" },
];

const MyTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memberType = userTypeByStatus(getDataFromLocalStorage("user_type"));
  const [tableList, setTableList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [globalSearchVal, setGlobalSearchVal] = useState("");
  const [timer, setTimer] = useState("");
  const [searchPayload, setSearchPayload] = useState({
    conference: "",
    paper_id: "",
    paper_title: "",
    receive_date: "",
    due_date: "",
    reviewer_status: "",
    paper_status: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    global_filter: "",
  });

  const getTableList = async (obj) => {
    const response = await dispatch(
      fetchMyReviewersAbstracts(objectToFormData(obj))
    );

    if (response?.data?.myTask) {
      setTableList(response?.data?.myTask);
      setFilterData({
        ...obj,
        total: response?.data?.result_count || 0,
      });
    }
    setIsLoading(false);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getTableList(newData);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getTableList(newData);
  };

  const handleGlobalSearch = (e) => {
    setGlobalSearchVal(e.target.value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let newData = { ...filterData, ...searchPayload };
      newData = { ...newData, global_filter: e.target.value, offset: 0 };
      setIsLoading(true);
      setFilterData(newData);
      getTableList(newData);
    }, 800);
    setTimer(time);
  };

  const handleRedirect = (paperId) => {
    navigate(`/${memberType}/my-task/detail/${paperId}`);
  };

  useEffect(() => {
    getTableList({ ...searchPayload, ...filterData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "conference",
      title: <div className="text-nowrap">Conference Name</div>,
    },
    {
      isSearch: true,
      searchInputName: "paper_id",
      title: "Paper ID",
    },
    {
      isSearch: true,
      searchInputName: "paper_title",
      title: "Paper Title",
    },
    {
      isSearch: true,
      searchInputName: "receive_date",
      title: <span className="text-nowrap">Received Date</span>,
    },
    {
      isSearch: true,
      searchInputName: "due_date",
      title: <span className="text-nowrap">Due Date</span>,
    },

    {
      isSearch: true,
      isReviewerStatusFilter: true,
      isMyTaskFilter: true,
      myTaskDropdownOptions: {
        options: reviewStatusOption,
        key: "name",
        value: "value",
      },
      handleStatus: (e) => {
        setSearchPayload((prev) => {
          return { ...prev, reviewer_status: e.target.value };
        });
      },
      searchInputName: "reviewer_status",
      title: <span className="text-nowrap">Reviewer Status</span>,
    },
    {
      isSearch: true,
      isReviewerPaperStatusFilter: true,
      isMyTaskFilter: true,
      myTaskDropdownOptions: {
        options: paperStatusOption,
        key: "name",
        value: "value",
      },
      handleStatus: (e) => {
        setSearchPayload((prev) => {
          return { ...prev, paper_status: e.target.value };
        });
      },
      searchInputName: "paper_status",
      title: <span className="text-nowrap">Paper Status</span>,
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];

  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      event_name,
      paper_id,
      paper_title,
      receive_date,
      due_date,
      reviewer_status,
      paper_status,
    } = elem;
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: <div className="color-new-car text-nowrap">{event_name}</div>,
      },
      {
        value: (
          <span
            onClick={() => {
              handleRedirect(elem?.id);
            }}
            className="pointer color-new-car text-nowrap"
          >
            {paper_id}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(paper_title)}</span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {receive_date ? moment(receive_date)?.format("DD-MMM-YYYY") : ""}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {due_date ? moment(due_date)?.format("DD-MMM-YYYY") : ""}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {reviewer_status ? reviewer_status : "-"}
          </span>
        ),
      },
      {
        value: (
          <span
            className={`${
              paper_status === "Completed"
                ? "color-a35b"
                : paper_status === "Pending"
                ? "color-5110"
                : paper_status === "Rejected"
                ? "color-4646"
                : "color-black-olive"
            } text-nowrap`}
          >
            {paper_status}
          </span>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            <>
              <i
                className="bi bi-eye pointer color-new-car"
                onClick={() => {
                  handleRedirect(elem?.id);
                }}
              />
            </>
          </span>
        ),
      },
    ];

    rowData.push({ data: obj });
  });
  return (
    <Card id="my-task-container" className="p-2 unset-br">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28 flex-wrap gap-2">
        <div className="table-title">My Tasks ({filterData?.total})</div>

        <div className="d-flex align-items-center flex-wrap gap-3">
          <div className="d-flex">
            <SeachInput
              placeholder="Search here"
              id="global_filter"
              value={globalSearchVal}
              onChange={handleGlobalSearch}
            />
          </div>
          <ExportButton
            newHeight={"h-45"}
            exportAPI={exportMyTaskData}
            payload={objectToFormData({ ...searchPayload, ...filterData })}
          />
        </div>
      </div>
      <div className="overflow-auto">
        <Table
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
          isOtherPagination
        />
      </div>
    </Card>
  );
};

export default MyTask;
