import React, { useEffect, useState } from "react";
import ExportButton from "components/Layout/ExportButton";
import TableV2 from "components/Layout/TableV2";
import { useDispatch } from "react-redux";
import { cvDownloadList, exportCVData } from "store/slices";
import { objectToFormData, titleCaseString } from "utils/helpers";

const CVDownloads = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchPayload, setSearchPayload] = useState({
    resource_id: "",
    resource_name: "",
    mentor_name: "",
    email: "",
    phone_number: "",
    download_date: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
  });

  const fetchAllList = async (obj) => {
    setIsLoading(true);
    const formData = objectToFormData(obj);
    const response = await dispatch(cvDownloadList(formData));
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.data) {
      resList = response?.data?.data || [];
      resResultCount = response?.data?.total_count || 0;
    }
    setTableData(resList);
    setFilterData({
      ...obj,
      total: resResultCount,
    });
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  const handelChangeSearch = (searchVal) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchVal);
    newData = { ...newData, ...searchVal, offset: 0 };
    setFilterData(newData);
    fetchAllList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchAllList(newData);
  };

  useEffect(() => {
    fetchAllList({ ...searchPayload, ...filterData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: true,
      searchInputName: "resource_id",
      title: <div className="text-nowrap">Resource ID</div>,
    },
    {
      isSearch: true,
      searchInputName: "resource_name",
      title: <div className="text-nowrap">Resource Name</div>,
    },
    {
      isSearch: true,
      searchInputName: "mentor_name",
      title: <div className="text-nowrap">Mentor Name</div>,
    },
    {
      isSearch: true,
      searchInputName: "email",
      title: "Email",
    },
    {
      isSearch: true,
      searchInputName: "phone_number",
      title: <div className="text-nowrap">Phone Number</div>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "download_date",
      title: <div className="text-nowrap">Download Date</div>,
    },
  ];
  const rowData = [];
  tableData?.forEach((elem) => {
    const {
      resource_id,
      resource_name,
      mentor_name,
      email,
      phone_number,
      download_date,
    } = elem;
    let obj = [
      {
        value: <span className="text-nowrap">{resource_id}</span>,
      },
      {
        value: (
          <div className="text-nowrap">{titleCaseString(resource_name)}</div>
        ),
      },
      {
        value: (
          <div className="text-nowrap">{titleCaseString(mentor_name)}</div>
        ),
      },

      {
        value: <div className="text-nowrap">{email}</div>,
      },
      {
        value: <div className="text-nowrap">{phone_number}</div>,
      },

      {
        value: <div className="text-nowrap">{download_date}</div>,
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <div id="all-cv-downloads-container">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="text-16-500 color-text-navy mt-4 cmb-10 cps-10">
          All CV Downloads ({filterData?.total})
        </div>
        <ExportButton
          exportAPI={exportCVData}
          payload={objectToFormData({
            ...filterData,
            ...searchPayload,
          })}
        />
      </div>
      <div className="overflow-auto">
        <TableV2
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </div>
    </div>
  );
};

export default CVDownloads;
