import Button from "components/form/Button";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { handleProceedToNext } from "store/slices";
import { eventAbstarctPath, icons } from "utils/constants";
import {
  downloadFile,
  formatDate,
  generatePreSignedUrl,
  objectToFormData,
} from "utils/helpers";

const AbstractState = ({
  data,
  paperData,
  isEvent,
  handleSuccess,
  setIsEdit,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [isSubmittedPaperDetail, setIsSubmittedPaperDetail] = useState(false);
  const [isRevisionDetails, setIsRevisionDetails] = useState(null);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState({});

  const handleNext = async () => {
    setIsNextLoading(true);
    const response = await dispatch(
      handleProceedToNext(objectToFormData({ id: params.paperId }))
    );

    if (response?.status === 200) {
      handleSuccess();
    } else {
      setIsNextLoading(false);
    }
    setIsNextLoading(false);
  };

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const toggleRevisionReadMore = (index) => {
    setExpandedIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const { abstract_path, paper_abstract, paperImage, revision_details } =
    paperData || {};
  const {
    paper_submitted_date: paperSubmittedDate,
    paper_submitted_status,
    process_to_next: processToNext,
  } = data || {};
  const paperURL = isEvent ? abstract_path || "" : paperImage || "";
  const status = +paper_submitted_status || 1;
  const abstractText = paper_abstract
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;

  useEffect(() => {
    if (revision_details?.length > 0) {
      setIsRevisionDetails(revision_details?.length - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.revision_details]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className="steps-block active">
          {/* 1 */}
          {status >= 2 ? <img src={icons?.RightIcon} alt="right" /> : "1"}
        </div>
        <div>
          <div className="text-16-500-20 color-new-car d-flex align-items-center gap-2">
            Abstract
            {revision_details?.length === 0 && (
              <img
                src={icons?.primaryEditPen}
                alt="pen"
                className="pointer"
                onClick={() => {
                  setIsEdit({
                    abstract_id: params.paperId,
                    paper_abstract: paper_abstract,
                    update_type: "Abstract",
                  });
                }}
              />
            )}
            <i className="bi bi-chevron-down" style={{ fontSize: "15px" }} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>

        <div className="w-100">
          {revision_details?.length === 0 ? (
            <>
              <div className="cmt-14 cmb-19">
                {paperSubmittedDate && (
                  <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                    <img src={icons.calendarCheck} alt="calendar" />
                    {formatDate(paperSubmittedDate)}
                  </div>
                )}
                {paper_abstract && (
                  <div className="cmb-16 text-14-300-24 color-black-olive">
                    <div>
                      {isExpanded ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: paper_abstract,
                          }}
                        />
                      ) : (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: paper_abstract
                                ?.replace(/<\/?p>/g, "")
                                ?.replace(/<\/?span[^>]*>/g, "")
                                ?.split(" ")
                                ?.slice(0, maxWords)
                                ?.join(" "),
                            }}
                          />

                          {abstractWords?.length > maxWords && (
                            <>
                              {"... "}
                              <span
                                onClick={toggleReadMore}
                                className="text-14-500-23 color-new-car pointer"
                              >
                                Read More
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    {isExpanded && (
                      <span
                        onClick={toggleReadMore}
                        className="text-14-500-23 color-new-car pointer"
                      >
                        Read Less
                      </span>
                    )}
                  </div>
                )}
                {paperURL && (
                  <div
                    className="cmb-16 text-16-500-20 color-7cff pointer"
                    onClick={async () => {
                      if (paperURL) {
                        let downloadContent = "";
                        if (isEvent) {
                          downloadContent = await generatePreSignedUrl(
                            paperURL,
                            eventAbstarctPath
                          );
                        }

                        dispatch(downloadFile(downloadContent));
                      }
                    }}
                  >
                    Download Abstract File
                  </div>
                )}
                {processToNext === "1" && (
                  <div className="d-flex">
                    <Button
                      text="Proceed to Next"
                      btnStyle="primary-dark"
                      className="h-35 cps-20 cpe-20"
                      onClick={handleNext}
                      btnLoading={isNextLoading}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="submitted-paper-block border br-5 w-100 cmb-8">
                <div
                  className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                  onClick={() => {
                    setIsSubmittedPaperDetail(!isSubmittedPaperDetail);
                  }}
                >
                  <span>Submitted Paper</span>
                  <i
                    className={`${
                      isSubmittedPaperDetail
                        ? "bi bi-chevron-up"
                        : "bi bi-chevron-down"
                    }`}
                    style={{ fontSize: "15px" }}
                  />
                </div>

                {isSubmittedPaperDetail && (
                  <div className="cps-24 cpt-12 cpe-24 cpb-12">
                    {paperSubmittedDate && (
                      <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                        <img src={icons.calendarCheck} alt="calendar" />
                        {formatDate(paperSubmittedDate)}
                      </div>
                    )}
                    {paper_abstract && (
                      <div className="cmb-16 text-14-300-24 color-black-olive">
                        <div>
                          {isExpanded ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: paper_abstract,
                              }}
                            />
                          ) : (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: paper_abstract
                                    ?.replace(/<\/?p>/g, "")
                                    ?.replace(/<\/?span[^>]*>/g, "")
                                    ?.split(" ")
                                    ?.slice(0, maxWords)
                                    ?.join(" "),
                                }}
                              />

                              {abstractWords?.length > maxWords && (
                                <>
                                  {"... "}
                                  <span
                                    onClick={toggleReadMore}
                                    className="text-14-500-23 color-new-car pointer"
                                  >
                                    Read More
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        {isExpanded && (
                          <span
                            onClick={toggleReadMore}
                            className="text-14-500-23 color-new-car pointer"
                          >
                            Read Less
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {revision_details?.map((details, index) => {
                const { revision_status } = details;
                const isOpen = isRevisionDetails === index;
                const isExpanded = expandedIndexes[index];
                return (
                  revision_status === "1" && (
                    <div
                      className="revision-block border br-5 w-100 cmb-8"
                      key={index + 1}
                    >
                      <div
                        className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                        onClick={() => {
                          setIsRevisionDetails(isOpen ? null : index);
                        }}
                      >
                        <div className="d-flex align-items-center gap-3">
                          Revision {index + 1}{" "}
                          {revision_details?.length - 1 === index &&
                            details?.process_to_next === "1" && (
                              <img
                                src={icons?.primaryEditPen}
                                alt="pen"
                                className="pointer"
                                onClick={() => {
                                  setIsEdit({
                                    revision_id: details?.id,
                                    abstract_id: params.paperId,
                                    paper_abstract: details?.paper_abstract,
                                    update_type: "Revision",
                                  });
                                }}
                              />
                            )}
                        </div>
                        <i
                          className={`${
                            isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                          }`}
                          style={{ fontSize: "15px" }}
                        />
                      </div>

                      {isOpen && (
                        <div className="cps-24 cpt-12 cpe-24 cpb-12">
                          <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300-17">
                            <img src={icons.calendarCheck} alt="calendar" />

                            {formatDate(details?.revision_date)}
                          </div>

                          {details?.paper_abstract && (
                            <div className="cmb-16 text-14-300-24 color-black-olive">
                              <div>
                                {isExpanded ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: details?.paper_abstract,
                                    }}
                                  />
                                ) : (
                                  <>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: details?.paper_abstract
                                          ?.replace(/<\/?p>/g, "")
                                          ?.replace(/<\/?span[^>]*>/g, "")
                                          ?.split(" ")
                                          ?.slice(0, maxWords)
                                          ?.join(" "),
                                      }}
                                    />
                                    {details?.paper_abstract?.split(" ")
                                      ?.length > maxWords && (
                                      <>
                                        {"... "}
                                        <span
                                          onClick={() =>
                                            toggleRevisionReadMore(index)
                                          }
                                          className="text-14-500-23 color-new-car pointer"
                                        >
                                          Read More
                                        </span>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>

                              {isExpanded && (
                                <span
                                  onClick={() => toggleRevisionReadMore(index)}
                                  className="text-14-500-23 color-new-car pointer"
                                >
                                  Read Less
                                </span>
                              )}
                            </div>
                          )}
                          {details?.abstract_path && (
                            <div
                              className="text-16-500-20 color-7cff pointer cmb-16"
                              onClick={async () => {
                                if (details?.abstract_path) {
                                  let downloadContent = "";
                                  if (isEvent) {
                                    downloadContent =
                                      await generatePreSignedUrl(
                                        details?.abstract_path,
                                        eventAbstarctPath
                                      );
                                  }

                                  dispatch(downloadFile(downloadContent));
                                }
                              }}
                            >
                              Download Abstract File
                            </div>
                          )}
                          {details?.is_visible_proceed_next === "1" && (
                            <div className="d-flex">
                              <Button
                                text="Proceed to Next"
                                btnStyle="primary-dark"
                                className="h-35 cps-20 cpe-20"
                                onClick={handleNext}
                                btnLoading={isNextLoading}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AbstractState;
