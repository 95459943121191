import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "components/form/Button";
import RadioInput from "components/form/RadioInput";
import { useDispatch } from "react-redux";
import { handleRemarkLead, throwError, throwSuccess } from "store/slices";
import { objectToFormData } from "utils/helpers";
import "./ActionFilter.scss";

const options = [
  { title: "Pending", value: "Pending" },
  { title: "Cold", value: "Cold" },
  { title: "Warm", value: "Warm" },
  { title: "Hot", value: "Hot" },
];

const ActionFilter = ({
  id,
  className,
  text,
  handleSuccess,
  elem,
  isOpen,
  onToggle,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(elem?.lead || "");

  const handleSave = async () => {
    setIsLoading(true);
    const response = await dispatch(
      handleRemarkLead(
        objectToFormData({ abstract_id: elem?.id, lead: selectedValue })
      )
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
      onToggle();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }

    setIsLoading(false);
  };

  const handleCancel = () => {
    setSelectedValue(elem?.lead || "");
    onToggle();
  };

  useEffect(() => {
    if (elem?.lead) {
      setSelectedValue(elem?.lead);
    }
  }, [elem]);

  return (
    <Dropdown drop={"start"} show={isOpen} id="action-filter-container">
      <Dropdown.Toggle
        variant="light"
        id={id}
        className={` ${className}`}
        align="end"
        onClick={onToggle}
      >
        {text}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-body-container">
        <div className="options-block border-bottom">
          <div className="text-14-300-17 color-1717 cmb-12 title">
            Choose the lead category :
          </div>
          {options.map((elm, index) => {
            return (
              <div
                key={elm?.title}
                className={`${
                  options?.length - 1 !== index ? "cmb-14" : ""
                } text-14-300-17 color-1717 d-flex gap-2 align-items-center`}
              >
                <RadioInput
                  id={elm?.title}
                  name="submission_type"
                  value={elm?.value}
                  checked={elm?.value === selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                  label={elm?.title}
                  labelClassName="text-14-300-17 color-1717 ps-2"
                />
              </div>
            );
          })}
        </div>

        <div className="d-flex align-items-center gap-2 button-block">
          <Button
            text="Save"
            btnStyle="primary-dark"
            className="h-35 cps-30 cpe-30"
            onClick={handleSave}
            btnLoading={isLoading}
          />
          <Button
            text="Cancel"
            btnStyle="primary-outline"
            className="h-35 cps-30 cpe-30"
            onClick={handleCancel}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionFilter;
