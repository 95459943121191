import Button from "components/form/Button";
import TextArea from "components/form/TextArea";
import Modal from "components/Layout/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { mentorAcceptReject, throwError, throwSuccess } from "store/slices";
import { objectToFormData } from "utils/helpers";

const errorMsg = "Please enter the rejection reason!";

const MentorRejectionPopup = ({ onHide, elem, handleSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [payload, setPayload] = useState({
    reason: "",
    mentor_id: elem?.mentor_id,
    status: 2,
    id: elem?.id,
  });

  const handleRequest = async (obj) => {
    setIsLoading(true);
    let formData = objectToFormData(obj);
    const response = await dispatch(mentorAcceptReject(formData));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError(response?.message));
    }
    setIsLoading(false);
  };
  return (
    <Modal onHide={onHide} titleBesideClose={"Mentor Request Rejection"}>
      <div className="cmt-20 cps-15 cpe-15 cpt-15 d-flex flex-column">
        <TextArea
          label="Describe rejection reason"
          rows={5}
          placeholder="Enter remarks for rejecting"
          labelClass="text-15-400-18 color-black-olive"
          onChange={(e) => {
            setPayload((prev) => {
              return {
                ...prev,
                reason: e?.target?.value,
              };
            });
            if (e?.target?.value) {
              setIsError(false);
            } else {
              setIsError(errorMsg);
            }
          }}
          error={isError}
        />
        <div className="d-flex align-items-center cmt-25 cmb-20 gap-4">
          <Button
            btnStyle="primary-dark"
            text="Submit"
            className="cps-23 cpe-23"
            onClick={() => {
              if (payload?.reason) {
                handleRequest(payload);
              } else {
                setIsError(errorMsg);
              }
            }}
            btnLoading={isLoading}
          />
          <Button
            btnStyle="light-outline"
            text="Back"
            className="cps-15 cpe-15"
            onClick={onHide}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MentorRejectionPopup;
