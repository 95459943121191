import Card from "components/Layout/Card";
import Profile from "components/Layout/Profile";
import React, { useEffect, useRef, useState } from "react";
import {
  downloadFile,
  generatePreSignedUrl,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { icons, paymentAbstractPath, paymentInvoice } from "utils/constants";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/form/Button";
import Modal from "components/Layout/Modal";
import TextArea from "components/form/TextArea";
import { useDispatch } from "react-redux";
import {
  fetchPaymentDetails,
  addPaymentDetails,
} from "store/slices/accountsSlice";
import Loader from "components/Layout/Loader";
import RadioInput from "components/form/RadioInput";
import { Formik } from "formik";
import { throwError, throwSuccess } from "store/slices";
import * as Yup from "yup";
import UploadInput from "components/form/UploadInput";
import "./PaymentVerificationDetails.scss";

const getStatusName = (val) => {
  if (val) {
    if (val === "0") {
      return "Pending";
    } else if (val === "1") {
      return "Success";
    } else {
      return "Verifying";
    }
  }
};

const PaymentVerificationDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const formRef = useRef();
  const invoiceSectionRef = useRef();
  const invoiceFormRef = useRef();
  const acceptedSectionRef = useRef();
  const [isUploadInvoiceError, setIsUploadInvoiceError] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isCoAuthorNameBlock, setIsCoAuthorNameBlock] = useState(false);
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const [remarkList, setRemarkList] = useState([]);
  const [btnTypeLoading, setBtnTypeLoading] = useState(false);
  const [isInvoicePreview, setIsInvoicePreview] = useState(null);

  const getPaymentDetails = async () => {
    setIsLoading(true);
    const response = await dispatch(fetchPaymentDetails(params?.id));
    if (response?.data?.payment_invoice) {
      const previewPaymentInvoice = await generatePreSignedUrl(
        response?.data?.payment_invoice,
        paymentInvoice
      );
      setPaymentDetails({
        ...response?.data,
        previewInvoiceUrl: previewPaymentInvoice,
      });
    } else {
      setPaymentDetails(response?.data);
    }
    if (response?.data?.abstract_payment_chatting) {
      setRemarkList(response?.data?.abstract_payment_chatting);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    profile_image,
    user_name,
    user_email,
    dob,
    gender,
    address,
    phone_number,
    country,
    introduction,
    conference_name,
    paper_name,
    paper_id,
    presentation_type,
    ref_no,
    payment_type,
    payment_category,
    payment_proof,
    amount,
    payment_status,
    author_name,
    coauthor,
    billing_address,
    billing_city,
    billing_state,
    billing_country,
    billing_zip_code,
    payment_invoice,
    previewInvoiceUrl,
    payment_remarks,
  } = paymentDetails || {};

  const formattedAddress = [
    billing_address,
    billing_city,
    billing_state,
    billing_country,
  ]
    ?.filter(Boolean)
    ?.join(", ");

  const initialValues = {
    is_valid_details: "1",
    remarks: "",
    // payment_verify_invoice: "",
  };
  const validationSchema = Yup.object({
    remarks: Yup.string().when("is_valid_details", {
      is: "0",
      then: Yup.string().required("Remarks are required"),
      otherwise: Yup.string(),
    }),
  });

  const handleSave = async (value) => {
    let payload = {
      id: paymentDetails?.abstract_id,
    };
    if (btnTypeLoading === "accept") {
      payload = {
        ...payload,
        remarks: value?.remarks,
        acceptance_status: 1,
        ...invoiceFormRef?.current?.values,
      };
    } else if (btnTypeLoading === "reject") {
      payload = { ...payload, remarks: value?.remarks, acceptance_status: 3 };
    } else {
      payload = { ...payload, remarks: value?.remarks, acceptance_status: 2 };
    }
    const response = await dispatch(
      addPaymentDetails(objectToFormData(payload))
    );
    if (response?.status === 200) {
      if (formRef?.current) {
        formRef.current.resetForm();
      }
      dispatch(throwSuccess(response?.message));
      getPaymentDetails();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnTypeLoading(false);
  };

  const scrollToSection = (sectionRef) => {
    if (sectionRef?.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const invoiceInitialValues = {
    invoice: "",
    invoiceFileName: "",
  };

  const handleInvoiceUpload = (value) => {
    if (acceptedSectionRef?.current) {
      acceptedSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const getRemarkLabel = (remark) => {
    if (remark?.user_type === "Resource") {
      return `${remark?.user_type} Comment ${remark?.user_counter} :`;
    }
    if (remark?.user_type === "Client") {
      return `${remark?.user_type} Reply ${remark?.user_counter} :`;
    }
    if (remark?.user_type === "Remarks") {
      return `${remark?.user_type} ${remark?.user_counter} :`;
    }
  };

  const isDisabledFields =
    window.location.pathname.includes("admin/payment-verification-details") &&
    !window.location.pathname.includes("accounts/payment-verification-details");

  return (
    <>
      {isConfirmPopup && (
        <Modal
          onHide={() => {
            setIsConfirmPopup(false);
          }}
          titleBesideClose={"Are you sure want to reject?"}
          isTitleVerticalCenter
          titleBesideCloseClassName={"text-16-300-20 color-4141"}
        >
          <div className="d-flex align-items-center gap-3 cmt-15 cps-20 cpe-20 cpb-10">
            <Button
              text="Yes, Reject"
              btnStyle="primary-dark"
              className="h-35 cps-15 cpe-15"
              onClick={() => {}}
            />
            <Button
              text="Cancel"
              btnStyle="primary-outline"
              className="h-35 cps-15 cpe-15"
              onClick={() => {
                setIsConfirmPopup(false);
              }}
            />
          </div>
        </Modal>
      )}
      <div className="payment-verification-details-container">
        <Card className="d-flex align-items-center p-2 unset-br mb-4">
          <span
            className="d-flex ps-2"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src={icons.leftArrow}
              alt="back"
              className="h-21 me-3 pointer"
            />
            Payment Verification
          </span>
        </Card>

        {isLoading ? (
          <>
            <Card className="cpt-38 cpe-24 cpb-38 cps-24 d-flex align-items-center justify-content-center cmb-16">
              <Loader size={"md"} />
            </Card>
          </>
        ) : (
          <>
            <Card className="cpt-38 cpe-24 cpb-38 cps-24 d-flex align-items-center cmb-16">
              <div className="cme-45">
                <Profile
                  isRounded
                  size="s-200"
                  url={profile_image}
                  isS3UserURL
                />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                {user_name && (
                  <h1 className="color-new-car text-20-400-25 cmb-14">
                    {user_name}
                  </h1>
                )}
                <div className="row cmb-18 gy-3">
                  <div className="d-flex flex-column col-lg-4">
                    <div className="d-flex gap-3 align-items-center cmb-16 text-14-300-17 color-black-olive">
                      <i className="bi bi-envelope" />
                      {user_email ? user_email : "-"}
                    </div>
                    <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-3 cmb-16">
                      <i className="bi bi-telephone" />{" "}
                      {phone_number ? phone_number : "-"}
                    </div>
                    <div className="text-14-300-17 color-black-olive d-flex align-items-center gap-3">
                      <i className="bi bi-globe-americas" />{" "}
                      {country ? country : "-"}
                    </div>
                  </div>
                  <div className="align-items-center  gap-5 col-lg-8">
                    <div className="row gy-3">
                      <div className="d-flex flex-column col-lg-3">
                        <span className="text-14-400-18 color-1717 cmb-8">
                          DOB
                        </span>
                        <span className="text-14-300-17 color-black-olive">
                          {dob ? dob : "-"}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-lg-3">
                        <span className="text-14-400-18 color-1717 cmb-8">
                          Gender
                        </span>
                        <span className="text-14-300-17 color-black-olive">
                          {gender ? gender : "-"}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-lg-4">
                        <span className="text-14-400-18 color-1717 cmb-8">
                          Address
                        </span>
                        <span className="text-14-300-17 color-black-olive">
                          {address ? address : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {introduction && (
                  <p className="text-14-300-24 color-black-olive">
                    {titleCaseString(introduction)}
                  </p>
                )}
              </div>
            </Card>
            <Card className="cpt-32 cpe-32 cpb-32 cps-32 cmb-16">
              <h2 className="text-16-400-20 color-new-car cmb-20">
                Conference & Abstract Info :
              </h2>
              <div className="text-14-400-17 color-1717">Conference Name:</div>
              <p className="text-14-300-24 color-black-olive cmb-20">
                {conference_name ? titleCaseString(conference_name) : "-"}
              </p>

              <div className="row gy-3">
                <div className="col-lg-7">
                  <div className="text-14-400-17 color-1717">Paper Title:</div>
                  <p className="text-14-300-24 color-black-olive">
                    {paper_name ? titleCaseString(paper_name) : "-"}
                  </p>
                </div>
                <div className="col-lg-3">
                  <div className="text-14-400-17 color-1717">Paper ID:</div>
                  <span className="text-14-300-24 color-black-olive cmb-20">
                    {paper_id ? paper_id : "-"}
                  </span>
                </div>
                <div className="col-lg-2">
                  <div className="text-14-400-17 color-1717">
                    Presentation Type:
                  </div>
                  <span className="text-14-300-24 color-black-olive cmb-20">
                    {presentation_type ? presentation_type : "-"}
                  </span>
                </div>
              </div>
            </Card>

            <div className="cmb-16" ref={invoiceSectionRef}>
              <Row>
                <Col lg={6}>
                  <Card className="cpt-32 cpe-32 cpb-32 cps-32 card-height">
                    <div className="h-100">
                      <h2 className="text-16-400-20 color-new-car cmb-24">
                        Payment Details
                      </h2>

                      <div className="row cmb-32">
                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Reference No :
                          </div>
                          <span className="text-14-300-18 color-black-olive">
                            {ref_no ? ref_no : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Payment Type :
                          </div>
                          <span className="text-14-300-18 color-black-olive">
                            {payment_type ? payment_type : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Payment Category :
                          </div>
                          <span className="text-14-300-18 color-black-olive">
                            {payment_category
                              ? payment_category === "1"
                                ? "Abstract Paper"
                                : "Journal Publication"
                              : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Payment Proof :
                          </div>
                          <span
                            className="text-14-300-18 color-new-car pointer"
                            onClick={async () => {
                              let downloadContent = await generatePreSignedUrl(
                                payment_proof,
                                paymentAbstractPath
                              );

                              dispatch(downloadFile(downloadContent));
                            }}
                          >
                            {payment_proof ? payment_proof : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Total Amount :
                          </div>
                          <span className="text-14-300-18 color-black-olive">
                            {amount ? amount : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Payment Status :
                          </div>
                          <span
                            className={`${
                              payment_status === "0"
                                ? "color-5110"
                                : payment_status === "1"
                                ? "color-a35b"
                                : payment_status === "2"
                                ? "color-6866"
                                : "text-14-300-18"
                            } color-black-olive`}
                          >
                            {payment_status
                              ? getStatusName(payment_status)
                              : "-"}
                          </span>
                        </div>

                        <div className="col-lg-6 cmb-14">
                          <div className="text-14-400-17 color-new-car">
                            Author Name :
                          </div>
                          <span className="text-14-300-18 color-black-olive">
                            {author_name ? titleCaseString(author_name) : "-"}
                          </span>
                        </div>
                      </div>
                      {coauthor?.length > 0 && (
                        <div
                          className={`${
                            isCoAuthorNameBlock
                              ? "border flex-column d-flex"
                              : ""
                          }`}
                        >
                          <div
                            className={`${
                              isCoAuthorNameBlock ? "cmb-15" : ""
                            } bg-f4ff color-new-car text-14-500-17 cps-24 cpt-12 cpe-24 cpb-12 gap-2 d-flex align-items-center justify-content-between pointer`}
                            onClick={() => {
                              setIsCoAuthorNameBlock(!isCoAuthorNameBlock);
                            }}
                          >
                            <div>Co-Author Name</div>
                            <i
                              className={`${
                                isCoAuthorNameBlock
                                  ? "bi bi-chevron-up"
                                  : "bi bi-chevron-down"
                              }`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                          {isCoAuthorNameBlock && (
                            <ol className="text-14-300-24 color-black-olive list">
                              {coauthor?.map((o, i) => {
                                return <li key={i}>{titleCaseString(o)}</li>;
                              })}
                            </ol>
                          )}
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="cpe-32 cpb-32 cps-32 cpt-32 upload-invoice-section h-100">
                    {payment_invoice ? (
                      <div style={{ height: "100%" }}>
                        <iframe
                          src={previewInvoiceUrl}
                          title="description"
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          allow="fullscreen"
                        />
                        <div
                          className="color-7cff text-16-500-20 d-flex gap-2 justify-content-center pointer"
                          onClick={() =>
                            dispatch(downloadFile(previewInvoiceUrl))
                          }
                        >
                          <div>
                            <svg
                              width="27"
                              height="19"
                              viewBox="0 0 27 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7214 2.51575C18.2445 2.51575 22.2783 5.05775 24.2474 9.07958C22.2783 13.1014 18.2564 15.6434 13.7214 15.6434C9.1864 15.6434 5.16456 13.1014 3.19541 9.07958C5.16456 5.05775 9.19834 2.51575 13.7214 2.51575ZM13.7214 0.128906C7.75429 0.128906 2.65837 3.84045 0.59375 9.07958C2.65837 14.3187 7.75429 18.0303 13.7214 18.0303C19.6885 18.0303 24.7844 14.3187 26.8491 9.07958C24.7844 3.84045 19.6885 0.128906 13.7214 0.128906ZM13.7214 6.09602C15.3683 6.09602 16.705 7.43266 16.705 9.07958C16.705 10.7265 15.3683 12.0631 13.7214 12.0631C12.0745 12.0631 10.7379 10.7265 10.7379 9.07958C10.7379 7.43266 12.0745 6.09602 13.7214 6.09602ZM13.7214 3.70918C10.7617 3.70918 8.351 6.11989 8.351 9.07958C8.351 12.0393 10.7617 14.45 13.7214 14.45C16.6811 14.45 19.0918 12.0393 19.0918 9.07958C19.0918 6.11989 16.6811 3.70918 13.7214 3.70918Z"
                                fill="#4A7CFF"
                              />
                            </svg>
                          </div>
                          <div>Preview payment proof</div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <Formik
                          initialValues={invoiceInitialValues}
                          onSubmit={handleInvoiceUpload}
                          innerRef={invoiceFormRef}
                        >
                          {(props) => {
                            const {
                              values,
                              handleChange,
                              handleSubmit,
                              setFieldValue,
                            } = props;

                            return (
                              <form className="w-100">
                                <div className=" cmb-20">
                                  <UploadInput
                                    isInvoiceFormate
                                    multiple={false}
                                    label="Upload Invoice*"
                                    id="invoice"
                                    value={values?.invoice}
                                    supportedFormats={[
                                      "PDF",
                                      "PNG",
                                      "JPG",
                                      "JPEG",
                                    ]}
                                    handleChange={(e) => {
                                      if (!e?.target?.value) {
                                        setIsInvoicePreview(null);
                                      }
                                      setFieldValue(
                                        "invoiceFileName",
                                        e?.target?.fileName
                                      );
                                      setFieldValue(
                                        e?.target?.id,
                                        e?.target?.value
                                      );
                                      if (e?.target?.value) {
                                        setIsUploadInvoiceError(false);
                                      } else {
                                        setIsUploadInvoiceError(true);
                                      }
                                      handleChange(e);
                                    }}
                                  />
                                  {isUploadInvoiceError && (
                                    <span
                                      className="text-13-400 mt-1 d-flex align-items-cemter gap-2"
                                      style={{ color: "red" }}
                                    >
                                      Invoice is required
                                    </span>
                                  )}
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                  <div>
                                    <Button
                                      text="Submit"
                                      className="h-35 cps-20 cpe-20"
                                      btnStyle="primary-dark"
                                      disabled={
                                        isUploadInvoiceError || !values?.invoice
                                      }
                                      onClick={handleSubmit}
                                    />
                                  </div>
                                  {values?.invoice && (
                                    <div
                                      className="color-7cff text-16-500-20 d-flex gap-2 justify-content-center pointer"
                                      onClick={() => {
                                        setIsInvoicePreview(values?.invoice);
                                      }}
                                    >
                                      <div>
                                        <svg
                                          width="27"
                                          height="19"
                                          viewBox="0 0 27 19"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M13.7214 2.51575C18.2445 2.51575 22.2783 5.05775 24.2474 9.07958C22.2783 13.1014 18.2564 15.6434 13.7214 15.6434C9.1864 15.6434 5.16456 13.1014 3.19541 9.07958C5.16456 5.05775 9.19834 2.51575 13.7214 2.51575ZM13.7214 0.128906C7.75429 0.128906 2.65837 3.84045 0.59375 9.07958C2.65837 14.3187 7.75429 18.0303 13.7214 18.0303C19.6885 18.0303 24.7844 14.3187 26.8491 9.07958C24.7844 3.84045 19.6885 0.128906 13.7214 0.128906ZM13.7214 6.09602C15.3683 6.09602 16.705 7.43266 16.705 9.07958C16.705 10.7265 15.3683 12.0631 13.7214 12.0631C12.0745 12.0631 10.7379 10.7265 10.7379 9.07958C10.7379 7.43266 12.0745 6.09602 13.7214 6.09602ZM13.7214 3.70918C10.7617 3.70918 8.351 6.11989 8.351 9.07958C8.351 12.0393 10.7617 14.45 13.7214 14.45C16.6811 14.45 19.0918 12.0393 19.0918 9.07958C19.0918 6.11989 16.6811 3.70918 13.7214 3.70918Z"
                                            fill="#4A7CFF"
                                          />
                                        </svg>
                                      </div>
                                      <div>Preview payment proof</div>
                                    </div>
                                  )}
                                </div>
                                {isInvoicePreview && (
                                  <div className="mt-3">
                                    <iframe
                                      src={isInvoicePreview}
                                      title="Invoice Preview"
                                      style={{
                                        width: "100%",
                                        height: "500px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  </div>
                                )}
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </div>

            <Card className="cpt-32 cpe-32 cpb-32 cps-32 cmb-16">
              <div
                className="d-flex justify-content-between"
                ref={acceptedSectionRef}
              >
                <div className="w-50">
                  <h2 className="text-16-400-20 color-new-car cmb-20">
                    Payment Verification :
                  </h2>
                  <div>
                    {remarkList?.length > 0
                      ? remarkList?.map((r, index) => {
                          return (
                            <div key={index} className="cmb-20">
                              <div className="cmb-12">
                                <span className="text-14-300-18 color-black-olive">
                                  {getRemarkLabel(r)}
                                </span>
                              </div>
                              <div>
                                <span className="text-14-300-18 color-black-olive">
                                  {r?.remarks}
                                </span>
                              </div>
                              {r?.payment_proof !== "" ? (
                                <div className="d-flex gap-3 cmt-9">
                                  <div
                                    className="cmb-16 text-16-500-20 color-7cff pointer border-end cpe-20"
                                    onClick={async () => {
                                      if (r?.payment_proof) {
                                        let downloadContent =
                                          await generatePreSignedUrl(
                                            r?.payment_proof,
                                            paymentAbstractPath
                                          );

                                        dispatch(downloadFile(downloadContent));
                                      }
                                    }}
                                  >
                                    View
                                  </div>

                                  <div
                                    className="cmb-16 text-16-500-20 color-7cff pointer"
                                    onClick={async () => {
                                      if (r?.payment_proof) {
                                        dispatch(
                                          downloadFile(r?.payment_proof)
                                        );
                                      }
                                    }}
                                  >
                                    Download
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                  {payment_status === "1" ? (
                    <div className="text-14-500-18 bg-effed success-payment cps-12 cpt-8 cpe-12 cpb-8 w-fit">
                      Payment verification has been completed !!
                    </div>
                  ) : (
                    <div className={isDisabledFields ? "disabled-form" : ""}>
                      {payment_remarks && (
                        <p>Resource Remark: {payment_remarks}</p>
                      )}
                      <p>Submitted proof has valid :</p>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSave}
                        innerRef={formRef}
                      >
                        {(props) => {
                          const { values, errors, handleChange, handleSubmit } =
                            props;

                          return (
                            <form>
                              <div className="cmb-20">
                                <div className="d-flex gap-2">
                                  <div>
                                    <RadioInput
                                      name="is_valid_details"
                                      label="Yes"
                                      value={"1"}
                                      onChange={handleChange}
                                      checked={values?.is_valid_details === "1"}
                                    />
                                  </div>
                                  <div>
                                    <RadioInput
                                      name="is_valid_details"
                                      label="No"
                                      value={"0"}
                                      onChange={handleChange}
                                      checked={values?.is_valid_details === "0"}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <TextArea
                                  label="Remarks :"
                                  placeholder={"Enter remarks"}
                                  id="remarks"
                                  value={values?.remarks}
                                  error={errors?.remarks}
                                  onChange={handleChange}
                                  disabled={isDisabledFields}
                                />
                              </div>
                              {values?.is_valid_details === "1" ? (
                                <div className="d-flex gap-2">
                                  <Button
                                    text="Accept"
                                    btnStyle="primary-dark cps-15 cpe-15"
                                    className="h-35"
                                    onClick={() => {
                                      setBtnTypeLoading("accept");

                                      if (
                                        values?.is_valid_details === "1" &&
                                        // !values?.payment_verify_invoice &&
                                        invoiceFormRef.current?.values
                                          ?.invoice === ""
                                      ) {
                                        setBtnTypeLoading(false);
                                        setIsUploadInvoiceError(true);
                                        dispatch(
                                          throwError({
                                            message:
                                              "Please upload the invoice for accepting the payment.",
                                          })
                                        );
                                        scrollToSection(invoiceSectionRef);
                                        return;
                                      }
                                      handleSubmit();
                                    }}
                                    btnLoading={btnTypeLoading === "accept"}
                                  />

                                  <Button
                                    text="Revision Needed"
                                    btnStyle={`primary-outline cps-15 cpe-15`}
                                    className="h-35"
                                    disabled={
                                      values?.remarks?.trim()?.length === 0
                                    }
                                    btnLoading={btnTypeLoading === "revision"}
                                    onClick={() => {
                                      setBtnTypeLoading("revision");
                                      handleSubmit();
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="d-flex gap-2">
                                  <Button
                                    text="Revision Needed"
                                    btnStyle={`primary-dark cps-15 cpe-15`}
                                    className="h-35"
                                    disabled={
                                      values?.remarks?.trim()?.length === 0
                                    }
                                    btnLoading={btnTypeLoading === "revision"}
                                    onClick={() => {
                                      setBtnTypeLoading("revision");
                                      handleSubmit();
                                    }}
                                  />

                                  <Button
                                    text="Reject"
                                    btnStyle="primary-outline cps-15 cpe-15"
                                    className="h-35"
                                    disabled={
                                      values?.remarks?.trim()?.length === 0
                                    }
                                    btnLoading={btnTypeLoading === "reject"}
                                    onClick={() => {
                                      setBtnTypeLoading("reject");
                                      handleSubmit();
                                    }}
                                  />
                                </div>
                              )}
                            </form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}
                </div>
                <div className="w-50">
                  <h2 className="text-16-400-20 color-new-car cmb-20">
                    Billing Address :
                  </h2>
                  <div className="d-flex align-items-center gap-2 cmb-18">
                    <i className="bi bi-geo-alt" />
                    <div className="text-14-300-24 color-black-olive">
                      {formattedAddress ? formattedAddress : "-"}
                    </div>
                  </div>

                  <div className="d-flex align-items-center gap-5 cmb-36">
                    <div className="d-flex align-items-center gap-2 cmb-18">
                      <i className="bi bi-geo" />
                      <div className="text-14-300-24 color-black-olive">
                        {billing_zip_code ? billing_zip_code : "-"}
                      </div>
                    </div>

                    <div className="d-flex align-items-center gap-2 cmb-18">
                      <i className="bi bi-globe-americas" />
                      <div className="text-14-300-24 color-black-olive">
                        {billing_country ? billing_country : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default PaymentVerificationDetails;
