import React from "react";
import EditPersonalEduDetails from "./EditPersonalEduDetails";
import EditInstitutionDetails from "./EditInstitutionDetails";
import EditCompanyDetails from "./EditCompanyDetails";

const EditDetailsOfAllUsers = ({ profileData, setIsEditDetails }) => {
  const { user_type } = profileData;

  return (
    <>
      {["2", "5"].includes(user_type) && (
        <EditPersonalEduDetails
          profileData={profileData}
          setIsEditDetails={setIsEditDetails}
        />
      )}
      {user_type === "3" && (
        <EditInstitutionDetails
          profileData={profileData}
          setIsEditDetails={setIsEditDetails}
        />
      )}
      {user_type === "4" && (
        <EditCompanyDetails
          profileData={profileData}
          setIsEditDetails={setIsEditDetails}
        />
      )}
    </>
  );
};

export default EditDetailsOfAllUsers;
