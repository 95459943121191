import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import Label from "components/form/Label";
import Button from "components/form/Button";
import TextArea from "components/form/TextArea";
import TextInput from "components/form/TextInput";
import TimePicker from "components/form/TimePicker";
import FileUpload from "components/form/FileUpload";
import MultipleSelect from "components/form/MultipleSelect";
import Card from "components/Layout/Card";
import ToggleContainer from "components/Layout/ToggleContainer";
import {
  icons,
  participationMode,
  speakerCategoryOptions,
} from "utils/constants";
import {
  addAgenda,
  getEvent,
  storeEventData,
  throwError,
  throwSuccess,
} from "store/slices";
import {
  formatDate,
  getEventDate,
  getFilenameFromUrl,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import Dropdown from "components/form/Dropdown";
import moment from "moment";

const AgendaForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventData } = useSelector((state) => ({
    eventData: state.global.eventData,
  }));
  const [btnLoading, setBtnLoading] = useState("");
  const [initialValues, setInitialValues] = useState([]);
  const [toggleIndex, setToggleIndex] = useState(0);

  const fetchEventDetails = async () => {
    await dispatch(getEvent(params?.eventId));
  };
  const handelSave = async (elem, index) => {
    setBtnLoading(elem.date);
    const payloadData = {
      ...elem,
      agenda_sessions: JSON.stringify(elem.agenda_sessions),
      event_id: params?.eventId,
    };
    const payload = objectToFormData(payloadData);
    const response = await dispatch(addAgenda(payload));
    if (response?.status === 200) {
      fetchEventDetails();
      dispatch(throwSuccess(response?.message));
      let newList = cloneDeep(initialValues);
      newList[index] = response.data;
      setInitialValues(newList);
    }
    setBtnLoading("");
  };

  useEffect(() => {
    fetchEventDetails();
    return () => {
      dispatch(storeEventData({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const agendasList = [];
    if (eventData?.event_agendas) {
      eventData?.event_agendas.forEach((elem) => {
        const newAgenda = elem.agenda_sessions?.map((o) => {
          return {
            ...o,
            session_time: o?.session_time
              ? moment(o?.session_time, "HH:mm:ss").format("HH:mm")
              : "",
          };
        });
        agendasList.push({
          ...elem,
          agenda_sessions:
            elem.agenda_sessions.length > 0
              ? newAgenda
              : [
                  {
                    id: "",
                    session_time: "",
                    session_topic: "",
                    name: "",
                    description: "",
                    speaker_id: "",
                    speaker_category: "",
                    participation_mode: "",
                    transcription: "",
                    transcriptionFileName: "",
                  },
                ],
        });
      });
    }
    setInitialValues(agendasList);
  }, [eventData]);

  const validationSchema = Yup.array().of(
    Yup.object().shape({
      agenda_sessions: Yup.array().of(
        Yup.object().shape({
          session_time: Yup.string().required("Time is required."),
          session_topic: Yup.string().required("Session topic is required."),
          name: Yup.string().required("Name is required."),
          description: Yup.string()
            .required("Description is required.")
            .max(100, "Maximum 100 character allow for this field."),
          speaker_id: Yup.string().required("Speaker is required."),
          speaker_category: Yup.string().required(
            "Speaker category is required."
          ),
          participation_mode: Yup.string().required(
            "Participation mode is required."
          ),
          transcription: Yup.string().required(
            "Transcription file is required."
          ),
        })
      ),
    })
  );

  return (
    <div className="">
      <Card className="d-flex align-items-center unset-br cpe-26 cps-26 pt-2 pb-2">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span
              className="d-flex"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={icons.leftArrow}
                alt="back"
                className="h-21 me-3 pointer"
              />
            </span>
            <div>{`${
              params.agandaId === "edit-agenda" ? "Edit" : "Add"
            } Agenda`}</div>
          </div>
          <div className="d-flex gap-3">
            <div>
              Event Date:{" "}
              {getEventDate(eventData?.start_date, eventData?.end_date)}
            </div>
          </div>
        </div>
      </Card>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => {
          const { values, errors, setFieldValue, handleChange } = props;

          return (
            <div className="fadeInUp">
              {values.map((elem, index) => {
                return (
                  <div className="mt-3" key={index}>
                    <ToggleContainer
                      title={`Add Day ${index + 1} Sessions`}
                      onSubmit={() => {}}
                      defaultOpen={index === toggleIndex}
                      onClick={() => {
                        setToggleIndex(index);
                      }}
                    >
                      <div className="row pt-3">
                        <div className="col-md-2 col-12 d-flex align-items-center cmb-22">
                          <Label label="Event Name" />
                        </div>
                        <div className="col-md-10 col-12 cmb-22">
                          <TextInput
                            placeholder="Event Name"
                            onChange={() => {}}
                            value={eventData?.event_name}
                            disabled
                          />
                        </div>
                        <div className="col-md-12">
                          {elem.agenda_sessions?.map(
                            (childElem, childIndex) => {
                              const lastElem =
                                elem.agenda_sessions.length - 1 === childIndex;
                              const isButton =
                                !childElem?.session_time ||
                                !childElem?.name ||
                                !childElem?.description ||
                                !childElem?.speaker_id ||
                                !childElem?.transcription;
                              return (
                                <div className="row mb-3" key={childIndex}>
                                  {elem.agenda_sessions.length > 1 && (
                                    <div className="d-flex justify-content-end mb-2">
                                      <Button
                                        onClick={() => {
                                          const listArray = cloneDeep(
                                            elem.agenda_sessions
                                          );
                                          listArray.splice(childIndex, 1);
                                          setFieldValue([index], {
                                            ...elem,
                                            agenda_sessions: listArray,
                                          });
                                        }}
                                        btnStyle="delete-outline"
                                        icon={
                                          <i className="bi bi-trash me-2 d-flex align-items-center" />
                                        }
                                        text="Delete"
                                        className="cpt-5 cpb-5 cps-10 cpe-10"
                                        isSquare
                                      />
                                    </div>
                                  )}
                                  <div className="col-md-2 col-12 d-flex align-items-center cmb-22">
                                    <Label label={`Day ${index + 1}`} />
                                  </div>
                                  <div className="col-md-5 col-12 cmb-22">
                                    <TextInput
                                      placeholder="Select Date"
                                      onChange={() => {}}
                                      value={formatDate(
                                        elem?.date,
                                        "DD MMM YYYY"
                                      )}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-md-5 col-12 cmb-22">
                                    <TimePicker
                                      placeholder="Enter Session Time"
                                      id={`[${index}][agenda_sessions][${childIndex}][session_time]`}
                                      onChange={(e) => {
                                        const id = e.target.id;
                                        const value = titleCaseString(
                                          e.target.value
                                        );
                                        if (
                                          elem?.agenda_sessions?.some(
                                            (o) => o?.session_time === value
                                          )
                                        ) {
                                          const errMsg = {
                                            message: `${moment(
                                              value,
                                              "HH:mm"
                                            ).format(
                                              "hh:mm A"
                                            )} is already taken.`,
                                          };
                                          dispatch(throwError(errMsg));
                                          return;
                                        }
                                        setFieldValue(id, value);
                                      }}
                                      value={childElem?.session_time}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.session_time
                                      }
                                    />
                                  </div>
                                  {/* new field */}
                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Session Topic" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <TextInput
                                      placeholder="Topic Name"
                                      id={`[${index}][agenda_sessions][${childIndex}][session_topic]`}
                                      onChange={(e) => {
                                        const id = e.target.id;
                                        const value = titleCaseString(
                                          e.target.value
                                        );
                                        setFieldValue(id, value);
                                      }}
                                      value={childElem.session_topic}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.session_topic
                                      }
                                    />
                                  </div>

                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Session Name" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <TextInput
                                      placeholder="Name"
                                      id={`[${index}][agenda_sessions][${childIndex}][name]`}
                                      onChange={(e) => {
                                        const id = e.target.id;
                                        const value = titleCaseString(
                                          e.target.value
                                        );
                                        setFieldValue(id, value);
                                      }}
                                      value={childElem.name}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.name
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Description" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <TextArea
                                      placeholder="Description"
                                      rows={3}
                                      id={`[${index}][agenda_sessions][${childIndex}][description]`}
                                      onChange={(e) => {
                                        const id = e.target.id;
                                        const value = titleCaseString(
                                          e.target.value
                                        );
                                        setFieldValue(id, value);
                                      }}
                                      value={childElem.description}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.description
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Speaker" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <MultipleSelect
                                      placeholder="Select Speakers"
                                      id={`[${index}][agenda_sessions][${childIndex}][speaker_id]`}
                                      value={childElem.speaker_id}
                                      options={
                                        eventData?.speaker_details?.map(
                                          (elm) => {
                                            return {
                                              ...elm,
                                              dName:
                                                elm?.name || elm?.new_user_name,
                                            };
                                          }
                                        ) || []
                                      }
                                      optionKey="id"
                                      optionValue="dName"
                                      onChange={handleChange}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.speaker_id
                                      }
                                    />
                                  </div>

                                  {/* new fields */}
                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Speaker Categories" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <Dropdown
                                      id={`[${index}][agenda_sessions][${childIndex}][speaker_category]`}
                                      options={speakerCategoryOptions}
                                      optionKey="id"
                                      optionValue="name"
                                      onChange={handleChange}
                                      placeholder="Select Category"
                                      value={childElem.speaker_category}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.speaker_category
                                      }
                                    />
                                  </div>

                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Participation Mode" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <Dropdown
                                      id={`[${index}][agenda_sessions][${childIndex}][participation_mode]`}
                                      options={participationMode}
                                      optionKey="id"
                                      optionValue="name"
                                      onChange={handleChange}
                                      placeholder="Select Mode"
                                      value={childElem.participation_mode}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.participation_mode
                                      }
                                    />
                                  </div>

                                  <div className="col-md-2 d-flex align-items-center cmb-22">
                                    <Label label="Transcription" />
                                  </div>
                                  <div className="col-md-10 cmb-22">
                                    <FileUpload
                                      id={`[${index}][agenda_sessions][${childIndex}][transcription]`}
                                      onChange={(e) => {
                                        const id = e.target.id;
                                        const value = e.target.value;
                                        const fileName = e.target.fileName;
                                        setFieldValue(
                                          `[${index}][agenda_sessions][${childIndex}][transcriptionFileName]`,
                                          fileName
                                        );
                                        setFieldValue(id, value);
                                      }}
                                      fileText={getFilenameFromUrl(
                                        childElem?.transcriptionFileName ||
                                          childElem.transcription
                                      )}
                                      error={
                                        errors?.[index]?.agenda_sessions?.[
                                          childIndex
                                        ]?.transcription
                                      }
                                    />
                                  </div>
                                  {lastElem && (
                                    <div className="d-flex">
                                      <Button
                                        onClick={() => {
                                          let newArray =
                                            JSON.parse(
                                              JSON.stringify(
                                                elem?.agenda_sessions
                                              )
                                            ) || [];
                                          newArray.push({
                                            id: "",
                                            session_time: "",
                                            name: "",
                                            description: "",
                                            speaker_id: "",
                                            transcription: "",
                                          });
                                          setFieldValue([index], {
                                            ...elem,
                                            agenda_sessions: newArray,
                                          });
                                        }}
                                        text="+ Add Next Session"
                                        btnStyle="primary-light"
                                        className="text-14-500 cps-20 cpe-20"
                                        disabled={isButton}
                                        isSquare
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>

                        <div className="d-flex justify-content-center gap-4 cmt-30 cpb-20">
                          <Button
                            text="Cancel"
                            isRounded
                            btnStyle="light-outline"
                            className="cps-40 cpe-40"
                            onClick={() => {}}
                          />
                          <Button
                            isRounded
                            text="Done"
                            btnStyle="primary-dark"
                            className="cps-40 cpe-40"
                            btnLoading={btnLoading === `${elem?.date}`}
                            disabled={
                              JSON.stringify(elem) ===
                                JSON.stringify(initialValues[index]) ||
                              Object.keys(
                                errors?.[index]?.agenda_sessions || {}
                              )?.length > 0
                            }
                            onClick={() => {
                              handelSave(elem, index);
                            }}
                          />
                        </div>
                      </div>
                    </ToggleContainer>
                  </div>
                );
              })}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AgendaForm;
