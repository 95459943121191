import React, { useEffect, useState } from "react";
import RadioInput from "components/form/RadioInput";
import SingleForm from "./SingleForm";
import { useDispatch } from "react-redux";
import { fetchDetailsOfBulkPayment } from "store/slices";
import {
  downloadFile,
  generatePreSignedUrl,
  objectToFormData,
} from "utils/helpers";
import { paymentAbstractPath } from "utils/constants";
import TextArea from "components/form/TextArea";
import RemarksConfirmation from "./RemarksConfirmationPopup/RemarksConfirmation";
import "./RegistrationPayment.scss";

const RegistrationPayment = ({ paperData, handleSuccess }) => {
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState("1");
  const [bulkPaymentDetails, setBulkPaymentDetails] = useState({});
  const [isRevisionTractBlock, setIsRivisionTrack] = useState(false);
  const [remarkList, setRemarkList] = useState([]);
  const [remark, setRemark] = useState("");
  const { abstract_payment = {}, payment_status } = paperData || {};
  const paymentIsAccepted = ["1"].includes(payment_status);

  const getBulkPaymentDetails = async () => {
    const response = await dispatch(
      fetchDetailsOfBulkPayment(
        objectToFormData({
          user_id: paperData?.user_id,
          event_id: paperData?.event_id,
        })
      )
    );
    setBulkPaymentDetails(response?.data || {});
  };

  const getRemarkLabel = (remark) => {
    if (remark?.user_type === "Accountant") {
      return `${remark?.user_type} Remarks ${remark?.user_counter} :`;
    }
    if (["Student", "Client"].includes(remark?.user_type)) {
      return `${remark?.user_type} Reply ${remark?.user_counter} :`;
    } else {
      return `${remark?.user_type} ${remark?.user_counter} :`;
    }
  };

  useEffect(() => {
    // if (paymentType === "2") {
    getBulkPaymentDetails();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paperData?.abstract_payment_chatting &&
      paperData?.abstract_payment_chatting?.length > 0
    ) {
      setRemarkList(paperData?.abstract_payment_chatting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.abstract_payment_chatting]);

  return (
    <>
      {abstract_payment && Object.keys(abstract_payment)?.length > 0 ? (
        <>
          {paymentIsAccepted && (
            <>
              <div className="text-14-500-18 color-6866 cmb-12">
                Registration successfully completed !!
              </div>
            </>
          )}
          {abstract_payment?.acceptance_status === "0" && (
            <div className="d-flex">
              <div className="text-14-500-18 bg-f6de info-border-payment cps-12 cpt-8 cpe-12 cpb-8">
                Verification Under Progress
              </div>
            </div>
          )}
          {remarkList?.length > 0 && (
            <div
              className={`${
                isRevisionTractBlock ? "border flex-column d-flex" : ""
              } cmb-30`}
            >
              {remarkList?.length > 0 && (
                <>
                  <div
                    className={`${
                      isRevisionTractBlock ? "cmb-15" : ""
                    } bg-f4ff color-new-car text-14-500-17 cps-24 cpt-12 cpe-24 cpb-12 gap-2 d-flex align-items-center justify-content-between pointer`}
                    onClick={() => {
                      setIsRivisionTrack(!isRevisionTractBlock);
                    }}
                  >
                    <div>Payment Revision Track</div>
                    <i
                      className={`${
                        isRevisionTractBlock
                          ? "bi bi-chevron-up"
                          : "bi bi-chevron-down"
                      }`}
                      style={{ fontSize: "15px" }}
                    />
                  </div>

                  {isRevisionTractBlock && (
                    <>
                      <div className="cps-24">
                        {remarkList?.length > 0 &&
                          remarkList?.map((remark, index) => (
                            <div key={index} className="cmb-20">
                              <div className="cmb-12">
                                <span className="text-14-300-18 color-black-olive">
                                  {getRemarkLabel(remark)}
                                </span>
                              </div>
                              <div>
                                <span className="text-14-300-18 color-black-olive">
                                  {remark?.remarks}
                                </span>
                              </div>
                              {remark?.payment_proof !== "" ? (
                                <div className="d-flex gap-3 cmt-9">
                                  <div
                                    className="cmb-16 text-16-500-20 color-7cff pointer border-end cpe-20"
                                    onClick={async () => {
                                      if (remark?.payment_proof) {
                                        let downloadContent =
                                          await generatePreSignedUrl(
                                            remark?.payment_proof,
                                            paymentAbstractPath
                                          );

                                        dispatch(downloadFile(downloadContent));
                                      }
                                    }}
                                  >
                                    View
                                  </div>
                                  <div
                                    className="cmb-16 text-16-500-20 color-7cff pointer"
                                    onClick={async () => {
                                      if (remark?.payment_proof) {
                                        dispatch(
                                          downloadFile(remark?.payment_proof)
                                        );
                                      }
                                    }}
                                  >
                                    Download
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                      </div>
                      <div className="cpb-24 cps-24 w-50">
                        <div className="cmb-25">
                          <TextArea
                            label="Remarks *"
                            placeholder={"Enter remarks"}
                            id="remarks"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </div>
                        <RemarksConfirmation
                          remark={remark}
                          paperData={paperData}
                          handleSuccess={() => {
                            handleSuccess();
                            setRemark("");
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="d-flex align-items-center gap-4 cmb-30">
            <div className="text-14-400-18 color-1717">Payment Type:</div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center ms-5">
                <RadioInput
                  name="payment_type"
                  id="payment_type"
                  onChange={() => {
                    setPaymentType("1");
                  }}
                  checked={paymentType === "1"}
                />

                <div className="text-16-400 color-raisin-black ms-3">
                  Single
                </div>
              </div>

              <div className="d-flex align-items-center ms-5">
                <RadioInput
                  name="payment_type"
                  id="payment_type"
                  onChange={() => {
                    setPaymentType("2");
                  }}
                  checked={paymentType === "2"}
                />

                <div className="text-16-400 color-raisin-black ms-3">Bulk</div>
              </div>
            </div>
          </div>

          {paymentType === "1" && (
            <SingleForm
              type={paymentType}
              paperData={paperData}
              handleSuccess={handleSuccess}
            />
          )}
          {paymentType === "2" && (
            <SingleForm
              type={paymentType}
              paperData={paperData}
              handleSuccess={handleSuccess}
              bulkPaymentDetails={bulkPaymentDetails}
            />
          )}
        </>
      )}
    </>
  );
};

export default RegistrationPayment;
