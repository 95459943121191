import moment from "moment";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
import { icons } from "utils/constants";

const DateTimePicker = ({
  label,
  error,
  isRequired,
  placeholder,
  onChange,
  name,
  value,
  id,
  minDate,
  maxDate,
  disabled,
  isClearable,
  isLeftIcon,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: 2030 - 1953 + 1 },
    (_, index) => 1953 + index
  );

  return (
    <div id="date-time-picker-container">
      {label && (
        <label>
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className="input-container">
        <ReactDatePicker
          id={id}
          name={name}
          dateFormat="dd MMM yyyy hh:mm a"
          selected={value ? new Date(value) : null}
          minDate={minDate ? new Date(minDate) : null}
          maxDate={maxDate ? new Date(maxDate) : null}
          placeholderText={placeholder}
          disabled={disabled}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          onChange={(date) => {
            onChange({
              target: {
                id,
                value: date ? moment(date).format("YYYY-MM-DD HH:mm") : "",
              },
            });
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
              className="custom-date-header"
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={moment(date).format("YYYY")}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                value={months[moment(date).month()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  increaseMonth();
                }}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
        />
        {isClearable && value && (
          <span
            className="close-icon pointer"
            onClick={() => {
              onChange({
                target: {
                  id,
                  value: "",
                },
              });
            }}
          >
            <i className="bi bi-x text-24-500 color-gray" />
          </span>
        )}
        <span
          className={`calender-icon w-fit ${
            isLeftIcon ? " left-calender-icon" : ""
          }`}
        >
          <img src={icons.calendar} alt="calender" />
        </span>
        {error && (
          <span className="text-13-400 pt-1">
            <span style={{ color: "red" }}>{error}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default DateTimePicker;
