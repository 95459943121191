import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import Profile from "components/Layout/Profile";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  handleAllocateReviewers,
  throwError,
  throwSuccess,
} from "store/slices";

const AddReviewerDropdownPopup = ({ options, paperData, handleSuccess }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);

  const {
    event_id,
    id: abstractID,
    allocated_reviewer,
    allocated_time,
  } = paperData || {};

  const handleCheck = (data) => {
    const isSelected = selectedOptions?.some((o) => o?.id === data?.id);
    const isAlreadyPresentInExistingList = allocated_reviewer?.some(
      (existingUser) => existingUser?.user_id === data?.id
    );
    if (!isSelected && isAlreadyPresentInExistingList) {
      dispatch(throwError({ message: "This user has already been selected." }));
      return;
    }

    const finalList = isSelected
      ? selectedOptions?.filter((o) => o?.id !== data?.id)
      : [...selectedOptions, data];
    setSelectedOptions(finalList);
  };

  const handleApply = async () => {
    setBtnLoader(true);
    const payload = {
      abstract_paper_id: abstractID,
      event_id: event_id,
      user_id: selectedOptions,
      allocation_type: 2,
    };

    const formData = new FormData();
    ["abstract_paper_id", "event_id", "allocation_type"].forEach((field) => {
      formData.append(field, payload[field]);
    });

    payload?.user_id?.forEach((user) => {
      formData.append(`user_id[]`, user?.id);
    });
    const response = await dispatch(handleAllocateReviewers(formData));
    if (response?.status === 200) {
      setSelectedOptions([]);
      dispatch(throwSuccess(response?.message));
      handleSuccess();
      setShow(false);
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader(false);
  };
  useEffect(() => {
    if (options && allocated_reviewer?.length > 0) {
      const matchedUsers = options?.filter((o) =>
        allocated_reviewer?.some((option) => option?.user_id === o?.id)
      );

      if (matchedUsers) {
        // If a match is found, add the reviewer's ID to the selectedOptions array if not already present
        setSelectedOptions((prevSelected) =>
          prevSelected?.includes(matchedUsers?.id)
            ? prevSelected
            : [...prevSelected, ...matchedUsers]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, paperData?.allocated_reviewer]);

  return (
    <Dropdown drop={"start"} show={show}>
      <div className="d-flex">
        <Button
          text="+ Add Reviewer"
          btnStyle="primary-outline"
          className="h-35 cps-20 cpe-20"
          onClick={() => {
            setShow(true);
          }}
          disabled={allocated_time}
        />
      </div>

      <Dropdown.Menu align="end" className="reviewer-dropdown-menu-custom">
        <div className="options-list-block">
          {options?.map((elem, index) => {
            const {
              id,
              name,
              email_id,
              member_id,
              phone_number,
              allocated_review,
              profile_image,
              area_of_interest
            } = elem || {};
            const isChecked = selectedOptions?.some((o) => o?.id === id);
            return (
              <div
                className="gap-3 custom-option"
                key={index}
                onClick={() => {
                  handleCheck(elem);
                }}
              >
                <div className="me-2 ">
                  <CheckBox
                    type="PRIMARY-ACTIVE"
                    onClick={() => {}}
                    isChecked={isChecked}
                    readOnly
                  />
                </div>
                <div style={{ width: "48px", height: "48px" }}>
                  <Profile
                    url={profile_image}
                    size="s-48"
                    isRounded
                    isS3UserURL
                  />
                </div>
                <div className=" flex-grow-1 pointer row">
          <div className={`${area_of_interest && "cmb-12"} col-lg-4`}>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {name}
            </div>
            <div className="text-12-400-15 color-black-olive">{member_id}</div>
            
          </div>
          <div className={`${area_of_interest && "cmb-12"} col-lg-5`}>
            <div className="text-14-300-17 color-black-olive cmb-12 w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-envelope" />
              {email_id}
            </div>
            <div className="text-14-300-17 color-black-olive w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-telephone" /> {phone_number}
            </div>
          </div>
          <div className={`${area_of_interest && "cmb-12"} col-lg-3`}>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {"Allocated Review"}
            </div>
            <div className="text-14-400-18 color-black-olive">
              {allocated_review}
            </div>
          </div>
          <div className="col-lg-12">
          {area_of_interest&& <div className="text-12-400-15 color-black-olive">{area_of_interest?.split(",")?.join(", ")}</div>}

          </div>
        </div>
              </div>
            );
          })}
        </div>

        <div className="d-flex align-items-center gap-3 cpt-15 cpe-20 cpb-15 cps-43">
          <Button
            text="Apply"
            btnStyle="primary-dark"
            className="h-35 cps-15 cpe-15"
            onClick={handleApply}
            btnLoading={btnLoader}
            disabled={selectedOptions?.length === 0}
          />
          <Button
            text="Close"
            btnStyle="primary-outline"
            className="h-35 cps-15 cpe-15"
            onClick={() => {
              setSelectedOptions([]);
              setShow(false);
            }}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddReviewerDropdownPopup;
