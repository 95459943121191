import Modal from "components/Layout/Modal";
import React from "react";
import { icons } from "utils/constants";

const CongratulationPopup = ({ onHide }) => {
  return (
    <Modal onHide={onHide} size={"lg"}>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className="cmb-19">
          <img src={icons?.gift} alt="congratulations" />
        </div>
        <h1 className="text-40-600-50 color-new-car cmb-18">
          Congratulations !!!
        </h1>
        <p className="text-16-500-27 color-black-olive text-center">
          You have earned +20 pts for successfully completing this review. The
          Rewards points earned will show up in your Rewards Account shortly.
        </p>
      </div>
    </Modal>
  );
};

export default CongratulationPopup;
