import React, { useEffect, useRef, useState } from "react";
import RadioInput from "components/form/RadioInput";
import CheckBox from "components/form/CheckBox";
import Button from "components/form/Button";
import "./PaperStatusFilter.scss";

const list = [
  {
    title: "Plagiarism",
    data: [
      {
        title: "Accepted",
        value: 1,
      },
      {
        title: "Rejected",
        value: 2,
      },
      {
        title: "Pending",
        value: 0,
      },
    ],
  },
  {
    title: "Reviewer",
    data: [
      {
        title: "Allocated",
        value: 1,
      },
      {
        title: "Not Allocated",
        value: 0,
      },
      // {
      //   title: "Pending",
      //   value: 2,
      // },
    ],
  },
  // {
  //   title: "Resource",
  //   data: [
  //     {
  //       title: "Allocated",
  //     },
  //     {
  //       title: "Not Allocated",
  //     },
  //     {
  //       title: "Pending",
  //     },
  //   ],
  // },
  {
    title: "Registration",
    data: [
      {
        title: "Accepted",
        value: 1,
      },
      // {
      //   title: "Rejected",
      // },
      {
        title: "Pending",
        value: 0,
      },
    ],
  },
];
const PaperStatusFilter = ({ handlePaperStatusChange, searchObject }) => {
  const myRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState([]);
  const handleChange = (val, Id, reset) => {
    let oldVal = reset ? [] : JSON.parse(JSON.stringify(subCategory));
    let oldValID = reset ? [] : JSON.parse(JSON.stringify(subCategoryValue));
    if (oldVal.includes(val)) {
      oldVal = oldVal.filter((o) => o !== val);
      oldValID = oldValID.filter((o) => o !== Id);
    } else {
      oldVal.push(val);
      oldValID.push(Id);
    }
    setSubCategory(oldVal);
    setSubCategoryValue(oldValID);
  };
  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div className="paper-status-filter-container">
      <div
        className="pointer color-granite-gray text-16-400 cps-10 cpe-10"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {searchObject?.statusType || "Select"}
      </div>
      {isOpen && (
        <div className="popup-block shadow" ref={myRef}>
          <div className="list-block iferp-scroll">
            {list?.map((pElem, pindex) => {
              return (
                <div key={pindex} className="mb-2">
                  <RadioInput
                    name="is_peer_reviewed"
                    label={pElem?.title}
                    value={pElem?.title}
                    onChange={() => {
                      setCategory(pElem?.title);
                      setSubCategory([]);
                      setSubCategoryValue([]);
                    }}
                    checked={category === pElem?.title}
                  />

                  <div className="ps-4 mt-2">
                    {pElem?.data?.map((cElem, cIndex) => {
                      return (
                        <div
                          key={cIndex}
                          className="d-flex align-items-center gap-2 mb-2"
                        >
                          <CheckBox
                            type="PRIMARY-ACTIVE"
                            onClick={() => {
                              if (category === pElem?.title) {
                                handleChange(cElem?.title, cElem?.value);
                              } else {
                                if (subCategory?.length === 0) {
                                  setCategory(pElem?.title);
                                  handleChange(
                                    cElem?.title,
                                    cElem?.value,
                                    true
                                  );
                                } else {
                                  setCategory(pElem?.title);
                                  handleChange(
                                    cElem?.title,
                                    cElem?.value,
                                    true
                                  );
                                }
                              }
                            }}
                            isChecked={
                              subCategory.includes(cElem?.title) &&
                              category === pElem?.title
                            }
                          />
                          <div>{cElem?.title}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex align-items-center justify-content-center gap-3 border-top pt-3 pb-3">
            <Button
              text="Apply"
              btnStyle="primary-dark"
              className="h-35"
              disabled={!category || subCategory.length === 0}
              onClick={() => {
                handlePaperStatusChange({
                  category: category,
                  subCategory: subCategory.join(", "),
                  subCategoryValue: subCategoryValue,
                });
                setIsOpen(false);
              }}
            />
            <Button
              text="Reset"
              btnStyle="light-outline"
              className="h-35"
              onClick={() => {
                setCategory("");
                setSubCategory([]);
                setSubCategoryValue([]);
                handlePaperStatusChange({
                  category: "",
                  subCategory: "",
                  subCategoryValue: "",
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaperStatusFilter;
