import DatePicker from "components/form/DatePicker";
import TextInput from "components/form/TextInput";
import React from "react";
// Agenda Page and Slot Allotment notification
const SixthStepOfFollowUps = ({ props }) => {
  const { handleChange, values } = props;
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    industry,
    your_date_time,
    your_session_title,
    session_venue,
    view_full_agenda,
    contact_support,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;

  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Venue
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Field/Industry
        </div>
        <div className="col-lg-6">
          <TextInput id="industry" onChange={handleChange} value={industry} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Slot Date
        </div>
        <div className="col-lg-6">
          <DatePicker
            id="your_date_time"
            onChange={handleChange}
            value={your_date_time}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Session Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_session_title"
            onChange={handleChange}
            value={your_session_title}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Session Venue
        </div>
        <div className="col-lg-6">
          <TextInput
            id="session_venue"
            onChange={handleChange}
            value={session_venue}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          View Full Agenda
        </div>
        <div className="col-lg-6">
          <TextInput
            id="view_full_agenda"
            onChange={handleChange}
            value={view_full_agenda}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Contact Support
        </div>
        <div className="col-lg-6">
          <TextInput
            id="contact_support"
            onChange={handleChange}
            value={contact_support}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput id="your_name" onChange={handleChange} value={your_name} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
          />
        </div>
      </div>
    </>
  );
};

export default SixthStepOfFollowUps;
