import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import Button from "components/form/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { icons, limit, moduleData } from "utils/constants";
import {
  formatSeconds,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { useDispatch } from "react-redux";
import { exportLogsData, fetchSessionsLogsData } from "store/slices";
import moment from "moment";
import SeachInput from "components/form/SeachInput";
import SessionDetailsPopup from "./SessionDetailsPopup";
import Profile from "components/Layout/Profile";
import ProfileDetail from "pages/Common/ProfileManagement/ProfileDetail";

const SessionLogs = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isDeatils, setDeatils] = useState(false);
  const [userDetail, setUserDetail] = useState(null);

  const [timer, setTimer] = useState("");
  const [filterDates, setFilterDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
  });
  const [searchPayload, setSearchPayload] = useState({
    search: "",
    start_date: "",
    end_date: "",
  });

  const [sessionsData, setSessionsData] = useState([]);
  const [isDetailsPopup, setIsDetailsPopup] = useState(null);

  const getSessionData = async (obj) => {
    setIsLoading(true);
    const response = await dispatch(
      fetchSessionsLogsData(objectToFormData(obj))
    );
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.data) {
      resList = response?.data?.data || [];
      resResultCount = response?.data?.total_count || 0;
    }

    setSessionsData(resList);
    setFilterData({ ...obj, total: resResultCount });
    setIsLoading(false);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getSessionData(newData);
  };

  const handelChangeSearch = (e) => {
    let value = e?.target?.value?.toLowerCase();
    setSearchText(value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let newData = { ...filterData, ...searchPayload, search: value };
      setFilterData(newData);
      setSearchPayload(newData);
      getSessionData(newData);
    }, 800);
    setTimer(time);
  };

  const handleDateRangeChange = (range) => {
    const [startDate, endDate] = range;
    let newDates = { start_date: startDate, end_date: endDate };
    setFilterDates(newDates);

    let newData = {
      ...filterData,
      ...searchPayload,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    setSearchPayload({
      ...searchPayload,
      ...newData,
    });
    getSessionData({
      ...filterData,
      ...newData,
    });
  };

  useEffect(() => {
    getSessionData({ ...filterData, ...searchPayload, tab_type: "session" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,
      searchInputName: "search",
      title: "User id",
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: "Name",
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: "Email",
    },
    // {
    //   isSearch: false,
    //   searchInputName: "search",
    //   title: <div className="text-nowrap">Module Route</div>,
    // },
    {
      isSearch: false,
      searchInputName: "search",
      title: "Module",
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Ideal Time</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: "Date",
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: "Start Time",
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: "End Time",
    },

    {
      isSearch: false,
      // searchLable: "View",
      title: "Action",
    },
  ];
  const rowData = [];
  sessionsData?.forEach((elem) => {
    const {
      id,
      first_name,
      last_name,
      email_id,
      profile_photo_path,
      pageTimeListData,
    } = elem;
    const { start_time, end_time, page_slug, idel_time } =
      pageTimeListData || {};
    const moduleName = moduleData?.find((o) => o?.route === page_slug);

    let obj = [
      {
        value: <div className="text-start cps-5 text-nowrap">{id}</div>,
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap d-flex align-items-center gap-2 pointer">
            <span>
              <Profile
                text={`${first_name} ${last_name}`}
                size="s-30"
                url={profile_photo_path}
                isS3UserURL
                isRounded
              />
            </span>
            <span
              className="color-new-car"
              onClick={() => {
                setDeatils(true);
                setUserDetail(id);
              }}
            >
              {titleCaseString(`${first_name} ${last_name}`)}
            </span>
          </div>
        ),
      },
      {
        value: <div className="text-start cps-5 text-nowrap">{email_id}</div>,
      },
      // {
      //   value: (
      //     <div className="text-start cps-5 text-nowrap">
      //       {page_slug ? page_slug : "-"}
      //     </div>
      //   ),
      // },
      {
        value: (
          <div className="text-start cps-5 text-nowrap d-flex flex-column">
            <span className="">{`Module Name: ${
              moduleName ? moduleName?.moduleName : "-"
            }`}</span>
            {moduleName?.subModuleName && (
              <span className="">{`Page: ${moduleName?.subModuleName}`}</span>
            )}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {idel_time ? formatSeconds(idel_time) : "-"}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(start_time).format("DD.MM.YYYY")}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(start_time).format("HH:mm:ss")}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(end_time).format("HH:mm:ss")}
          </div>
        ),
      },

      {
        value: (
          <div className="d-flex justify-content-center gap-2">
            <span className="action-icon-buttons ">
              {/* {pageTimeListData?.length > 0 ? ( */}
              <Button
                text="View"
                btnStyle="primary-light"
                className="text-14-500 mw-70 me-2"
                isSquare
                onClick={() => {
                  setIsDetailsPopup(elem);
                }}
              />
              {/* ) : (
                "No More Data"
              )} */}
            </span>
          </div>
        ),
      },
    ];
    rowData.push({ data: obj });
    // });
  });
  return (
    <div>
      {isDetailsPopup && (
        <SessionDetailsPopup
          data={isDetailsPopup}
          onHide={() => {
            setIsDetailsPopup(null);
          }}
          title={`${
            isDetailsPopup
              ? `Session Details of ${isDetailsPopup?.first_name} ${isDetailsPopup?.last_name}`
              : ""
          }`}
        />
      )}
      {isDeatils && (
        <ProfileDetail
          userID={userDetail}
          onHide={() => {
            setDeatils(false);
          }}
        />
      )}
      <Card className="cps-20 cpe-20 cpb-20">
        <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 cpt-28 cpb-28">
          <div className="table-title">All Sessions Logs</div>

          <div className="d-flex gap-2">
            <SeachInput
              placeholder="Name & Email"
              value={searchText}
              onChange={handelChangeSearch}
            />
            <div id="date-picker-container">
              <div className="input-container">
                <DatePicker
                  selected=""
                  onChange={handleDateRangeChange}
                  startDate={filterDates?.start_date}
                  endDate={filterDates?.end_date}
                  placeholderText="Select Date Range"
                  selectsRange
                  isClearable={
                    filterDates?.start_date || filterDates?.end_date
                      ? true
                      : false
                  }
                />
                {!filterDates?.start_date && (
                  <span className={`calender-icon w-fit`}>
                    <img src={icons.calendar} alt="calender" />
                  </span>
                )}
              </div>
            </div>
            <ExportButton
              exportAPI={exportLogsData}
              payload={objectToFormData({
                start_date: searchPayload?.start_date,
                end_date: searchPayload?.start_date,
                tab_type: "session",
              })}
              newHeight="h-45"
            />
          </div>
        </div>
        <div className="w-100 overflow-auto">
          <Table
            header={header}
            rowData={rowData}
            isLoading={isLoading}
            filterData={filterData}
            changeOffset={handelChangePagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default SessionLogs;
