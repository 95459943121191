import EigthStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/EigthStepTemplate";
import FifthStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/FifthStepTemplate";
import FirstStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/FirstStepTemplate";
import FourthStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/FourthStepTemplate";
import SecondStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/SecondStepTemplate";
import SeventhStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/SeventhStepTemplate";
import SixthStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/SixthStepTemplate";
import ThirdStepTemplate from "components/V2ForNewAbstractFlow/ResourceSide/NewPaperDetails/RegisterTemplatesPreview/ThirdStepTemplate";

export const getRegistrationTemplatesPreview = (values) => {
  switch (values?.followUps) {
    case "Session name inclusion notification":
      return <FirstStepTemplate values={values} />;
    case "Coupon or Voucher follow ups/early bird deadline":
      return <SecondStepTemplate values={values} />;
    case "Publication Details Few slots left":
      return <ThirdStepTemplate values={values} />;
    case "Session-Based Publication Opportunities and Conference Outcomes at [Conference Name]":
      return <FourthStepTemplate values={values} />;
    case "Keynote speaker, session speaker notification":
      return <FifthStepTemplate values={values} />;
    case "Agenda Page and Slot Allotment notification":
      return <SixthStepTemplate values={values} />;
    case "Hall Ticket, Zoom link and Confirmation":
      return <SeventhStepTemplate values={values} />;
    case "Full Paper Follow-Up Email":
      return <EigthStepTemplate values={values} />;

    default:
      return (
        <div className="center-flex">
          Please select the value from the dropdown
        </div>
      );
  }
};

export const registrationTemplatesPreview = {
  "Session name inclusion notification": (
    <div>
      <p>Dear Dr. [Client Name] / [Client Name],</p>

      <p>Greetings from [IFERP/Conference Organizer]!</p>

      <p>
        We are excited to provide you with the details of your upcoming session
        at [Conference Name], which will take place on [Conference Date] at
        [Conference Location].
      </p>

      <h6>
        <strong>Session Details:</strong>
      </h6>

      <ul>
        <li>
          <strong>Session Title:</strong> [Session Title]
        </li>
        <li>
          <strong>Session Overview:</strong> [Brief overview of the session
          content and objectives]
        </li>
      </ul>

      <p>
        Your Paper: {[`Paper Title`]} will present groundbreaking research and
        innovative approaches in [Relevant Field]. Your work will be a key
        contribution to the discussions on this topic.
      </p>

      <p>
        We are thrilled to have you present and contribute to the dynamic
        exchange of ideas at the conference. Both your paper and the event align
        with the United Nations’ 17 Sustainable Development Goals (SDGs),
        furthering impactful research that promotes global progress.
      </p>

      <h6>
        <strong>Conference Highlights:</strong>
      </h6>
      <ul>
        <li>
          <strong>Scopus Publication Opportunities:</strong> Offering enhanced
          visibility for your research through international indexing.
        </li>
        <li>
          <strong>Awards and Certifications:</strong> Internationally accredited
          recognitions that will boost your academic and professional profile.
        </li>
        <li>
          <strong>Scholarships:</strong> Available to support broader
          participation and inclusion.
        </li>
        <li>
          <strong>Networking Opportunities:</strong> Connect with keynote
          speakers and global thought leaders, enriching your professional
          journey.
        </li>
      </ul>

      <h6>
        <strong>Next Steps:</strong>
      </h6>

      <p>
        To view or manage your session details, please follow the link below:
      </p>

      <p>
        <strong>CTA: </strong>
        [Complete Registration]
      </p>

      <p>
        If you have any questions or require additional information, feel free
        to reach out to us at <a href="mailto:[SupportEmail]">[SupportEmail]</a>
        .
      </p>

      <p>
        We look forward to your engaging presentation and the thought-provoking
        discussions it will inspire!
      </p>

      <div>Best regards,</div>
      <div>[Your Name/Your Organization]</div>
      <div>[Your Contact Information]</div>
      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),
  "Coupon or Voucher follow ups/early bird deadline": (
    <div>
      <p>Dear Dr. [Client Name] / [Client Name],</p>
      <p>Greetings from IFERP!</p>
      <p>
        We are excited to offer you an exclusive opportunity to register for{" "}
        <strong>[Conference Name]</strong> with a special discount! The
        conference will take place from <strong>[Conference Dates]</strong> at{" "}
        <strong>[Conference Venue]</strong>.
      </p>

      <h6>
        <strong>Exclusive Discount:</strong>
      </h6>
      <p>
        As a valued member of our community, you can use the coupon code{" "}
        <strong>[Coupon Code]</strong> to enjoy a{" "}
        <strong>[Discount Amount]%</strong> discount on your registration fee.
        Don’t miss this chance—apply the code during registration and save
        before the offer expires on <strong>[Expiration Date]</strong>!
      </p>

      <h6>
        <strong>Why Attend [Conference Name]?</strong>
      </h6>
      <ul>
        <li>
          <strong>Keynote Speakers:</strong> Hear from experts across 7+
          countries in the field.
        </li>
        <li>
          <strong>Networking Opportunities:</strong> Connect with professionals
          from over 12+ countries and expand your research collaborations.
        </li>
        <li>
          <strong>Workshops and Panels:</strong> Participate in 2+ interactive
          workshops to gain practical insights into research, article writing,
          and career development.
        </li>
        <li>
          <strong>Publication Opportunities:</strong> Selected papers will be
          submitted to Scopus and Web of Science (WoS) for evaluation and
          indexing.
        </li>
        <li>
          <strong>SDG Alignment:</strong> Sessions aligned with the United
          Nations’ Sustainable Development Goals (SDGs), promoting global
          progress through research.
        </li>
        <li>
          <strong>Certifications:</strong> Earn globally recognized
          certifications for your contributions and participation.
        </li>
      </ul>

      <h6>
        <strong>Conference Statistics:</strong>
      </h6>
      <ul>
        <li>
          5+ Technical Sessions covering groundbreaking research and
          innovations.
        </li>
        <li>
          2 Best Paper Awards and 2 Best Paper Presentation Awards to recognize
          excellence.
        </li>
        <li>3 Research Excellence Awards for outstanding contributions.</li>
        <li>
          2+ Pre-Conference Workshops offering insights into research
          methodologies and article writing.
        </li>
        <li>
          Panel Discussions with global experts to foster impactful discussions.
        </li>
        <li>
          1080 minutes of content from leading academics and professionals.
        </li>
      </ul>

      <h6>
        <strong>Scholarships and Awards:</strong>
      </h6>
      <p>
        We offer scholarships to support participation and inclusivity, along
        with opportunities to earn awards that will enhance your academic
        profile.
      </p>

      <h6>
        <strong>Pre-Conference Workshops:</strong>
      </h6>
      <p>
        Additionally, attend our pre-conference workshops to sharpen your skills
        in article writing, enhance your research methodologies, and network
        with peers—building connections before the main event.
      </p>

      <h6>
        <strong>Your Coupon Details:</strong>
      </h6>
      <ul>
        <li>
          <strong>Coupon Code:</strong> [Coupon Code]
        </li>
        <li>
          <strong>Discount Amount:</strong> [Discount Amount]
        </li>
        <li>
          <strong>Expiration Date:</strong> [Expiration Date]
        </li>
      </ul>

      <h6>
        <strong>Next Steps:</strong>
      </h6>
      <p>
        Register today and take advantage of this special offer by using the
        coupon code during checkout! <strong>[CTA: Register Now]</strong>
      </p>

      <p>
        If you have any questions or need further assistance, feel free to
        contact us at <a href="mailto:[SupportEmail]">[SupportEmail]</a>.
      </p>

      <p>
        We look forward to welcoming you to <strong>[Conference Name]</strong>!
      </p>

      <div>Best regards,</div>
      <div>[Your Name/Your Organization]</div>
      <div>[Your Contact Information]</div>
      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),
  "Publication Details Few slots left": (
    <div>
      <p>
        Dear Dr. <strong> [Client Name]</strong> /{" "}
        <strong>[Client Name]</strong>,
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are pleased to announce that ISBN and ISSN numbers are now available
        for the upcoming <strong>[Conference Name]</strong>, scheduled to take
        place from <strong>[Conference Date]</strong> at{" "}
        <strong>[Conference Venue]</strong>.
      </p>

      <h6>
        <strong>Why This Matters:</strong>
      </h6>
      <p>
        Obtaining an ISBN or ISSN number not only validates the authenticity of
        your work but also enhances its visibility and accessibility within the
        global academic and professional community. This prestigious
        accreditation ensures that your research is recognized and respected on
        a broader scale.
      </p>

      <h6>
        <strong>Publication Opportunities:</strong>
      </h6>
      <p>
        By participating in <strong>[Conference Name]</strong>, you will have
        the opportunity to publish your research in high-impact journals indexed
        in Scopus and Web of Science (WoS). This can significantly enhance the
        credibility of your work and extend your academic reach, giving you
        greater visibility in the research community.
      </p>

      <h6>
        <strong>Act Now – Limited Slots Available:</strong>
      </h6>
      <p>
        Due to overwhelming interest, slots for securing an ISBN and ISSN number
        are limited. We encourage you to register early to guarantee your place
        and take advantage of this prestigious opportunity for your research.
      </p>

      <h6>
        <strong>Register Here:</strong>
      </h6>
      <p>[Registration Link]</p>

      <p>
        We are looking forward to your active participation and contribution to
        the success of this conference. Should you have any questions or need
        further assistance, please feel free to reach out to us.
      </p>

      <div>Best regards,</div>
      <div>[Your Name]</div>
      <div>[Your Title]</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>[Your Contact Information]</div>

      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),
  "Session-Based Publication Opportunities and Conference Outcomes at [Conference Name]":
    (
      <div>
        <p>
          Dear Dr. <strong>[Client Name]</strong> /{" "}
          <strong>[Client Name]</strong>,
        </p>

        <p>Greetings from IFERP!</p>

        <p>
          We are delighted to inform you that your article titled "
          <strong>[Paper Title]</strong>" has been accepted for presentation at{" "}
          <strong>[Conference Name]</strong>, scheduled to take place on{" "}
          <strong>[Date]</strong> at <strong>[Venue]</strong>.
        </p>

        <h6>
          <strong>Conference Highlights:</strong>
        </h6>
        <ul>
          <li>
            <strong>Outcome:</strong> Engage in vibrant discussions and
            networking opportunities that drive impactful research and
            innovation.
          </li>
          <li>
            <strong>Publication Opportunities:</strong> Selected papers will be
            submitted for evaluation and indexing in Scopus and Web of Science
            (WoS) (T&C apply).
          </li>
          <li>
            <strong>High-Impact Journals:</strong> Depending on session
            relevance, you'll also have the opportunity to publish in
            high-impact journals.
          </li>
          <li>
            <strong>Successful Publication Track Record:</strong> Many past
            participants have successfully published in prestigious journals,
            gaining visibility and recognition.
          </li>
          <li>
            <strong>SDG Alignment:</strong> Sessions are aligned with the United
            Nations’ Sustainable Development Goals (SDGs), contributing to
            global progress through research.
          </li>
          <li>
            <strong>Certifications:</strong> All participants will receive
            official certificates recognizing their contributions and
            participation.
          </li>
        </ul>

        <h6>
          <strong>Indexing and Publication:</strong>
        </h6>
        <p>
          The proceedings of <strong>[Conference Name]</strong> will be
          submitted to the{" "}
          <strong>Web of Science Book Citation Index (BkCI) and Scopus </strong>
          for evaluation and indexing (T&C apply), ensuring enhanced visibility
          and impact for your research within the academic community.
        </p>

        <h6>
          <strong>Next Steps:</strong>
        </h6>
        <p>
          To confirm your participation, please complete your registration by
          following the link below:
        </p>

        <p>
          <strong>[Register Here]</strong>
        </p>

        <p>
          We are confident that your presentation will add significant value to
          the conference, and we look forward to the insights and contributions
          you will bring to our global community.
        </p>

        <p>
          Should you have any questions or require assistance, feel free to
          contact us.
        </p>

        <div>Best regards,</div>
        <div>[Your Name]</div>
        <div>[Your Title]</div>
        <div>
          <strong>IFERP</strong>
        </div>
        <div>[Your Contact Information]</div>

        <div>
          <a href="[Your Website]">[Your Website]</a>
        </div>
      </div>
    ),

  "Keynote speaker, session speaker notification": (
    <div>
      <p>
        Dear Dr. <strong>[Client Name]</strong> / <strong>[Client Name]</strong>
        ,
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are thrilled to introduce the distinguished speakers who will be
        presenting at our upcoming conference,{" "}
        <strong>"[Conference Name]"</strong>, scheduled for{" "}
        <strong>[Conference Dates]</strong> at{" "}
        <strong>[Conference Venue]</strong>. These renowned experts bring a
        wealth of knowledge, notable achievements, and invaluable insights that
        will enhance your conference experience.
      </p>

      <h6>
        <strong>Featured Speakers:</strong>
      </h6>

      <div>
        <strong>Dr. [Speaker's Name]</strong>
      </div>
      <div>
        Credentials:<strong> [Academic degrees, professional titles]</strong>
      </div>
      <div>
        Notable Achievements:
        <strong>
          Notable Achievements: [Awards, significant research contributions,
          industry impact]
        </strong>
      </div>
      <div>
        Session Topic:
        <strong> [Brief description of their presentation topic]</strong>
      </div>

      <div>
        <strong>Dr. [Speaker's Name]</strong>
      </div>
      <div>
        Credentials:<strong> [Academic degrees, professional titles]</strong>
      </div>
      <div>
        Notable Achievements:
        <strong>
          Notable Achievements: [Awards, significant research contributions,
          industry impact]
        </strong>
      </div>
      <div>
        Session Topic:
        <strong> [Brief description of their presentation topic]</strong>
      </div>

      <h6>
        <strong>Pre-Conference Webinar:</strong>
      </h6>
      <p>
        To give you a sneak peek of the incredible insights you can expect, we
        are hosting a pre-conference webinar featuring{" "}
        <strong>[Speaker's Name]</strong>. Join us for a live Q&A session on{" "}
        <strong>[Date and Time]</strong> to engage with one of our esteemed
        speakers and get a preview of their session.
      </p>
      <p>
        <a href="[Register for the Webinar]">Register for the Webinar</a>
      </p>

      <h6>
        <strong>View Speaker Profiles:</strong>
      </h6>
      <p>
        For a deeper look at the full lineup of speakers and session highlights,
        click below:
      </p>
      <p>
        <a href="[View Speaker Profiles]">View Speaker Profiles</a>
      </p>

      <h6>
        <strong>Register Now:</strong>
      </h6>
      <p>
        Don’t miss the opportunity to learn from and network with leading
        experts in the field. Register today to secure your spot at the
        conference:
      </p>
      <p>
        <a href="[Register for the Conference]">Register for the Conference</a>
      </p>

      <p>
        We look forward to welcoming you to an event filled with groundbreaking
        research, innovative ideas, and meaningful discussions.
      </p>

      <div>Best regards,</div>
      <div>[Your Name]</div>
      <div>[Your Title]</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>[Your Contact Information]</div>

      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),

  "Agenda Page and Slot Allotment notification": (
    <div>
      <p>
        Dear Dr. <strong>[Client Name]</strong> / <strong>[Client Name]</strong>
        ,
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are excited to share the comprehensive agenda and your slot allotment
        for <strong>[Conference Name]</strong>, which will take place from{" "}
        <strong>[Conference Dates]</strong> at{" "}
        <strong>[Conference Venue]</strong>.
      </p>

      <h6>
        <strong>Comprehensive Agenda:</strong>
      </h6>

      <p>
        Our conference agenda is designed to provide you with a diverse and
        enriching experience. Here's a glimpse of what you can expect:
      </p>

      <ul>
        <li>
          <strong>Keynote Sessions:</strong> Engage with renowned experts and
          gain insights into the latest trends and advancements in
          <strong> [Field/Industry]</strong>.
        </li>
        <li>
          <strong>Workshops:</strong> Participate in hands-on sessions and
          acquire practical knowledge and skills.
        </li>
        <li>
          <strong>Networking Events:</strong> Connect with peers, researchers,
          and professionals to foster collaboration and exchange ideas.
        </li>
        <li>
          <strong>Q&A Sessions:</strong> Take advantage of dedicated time slots
          to ask questions and delve deeper into the topics presented.
        </li>
      </ul>

      <h6>
        <strong>Your Slot Allotment:</strong>
      </h6>

      <p>
        You have been allotted the following slot for your presentation at the
        conference:
      </p>
      <p>
        <strong>Date and Time:</strong> [Your Slot Date and Time]
      </p>
      <p>
        <strong>Session Title:</strong> [Your Session Title]
      </p>
      <p>
        <strong>Venue:</strong> [Session Venue]
      </p>

      <p>
        For a detailed view of the full agenda and your presentation details,
        please visit the link below:
      </p>

      <p>
        <a href="[View Full Agenda]">View Full Agenda</a>
      </p>

      <h6>
        <strong>Prepare for the Conference:</strong>
      </h6>

      <p>
        Make the most of your conference experience by preparing in advance.
        Should you need further details or assistance, feel free to reach out to
        us.
      </p>

      <p>
        <a href="[Contact Support]">Contact Support</a>
      </p>

      <p>
        We look forward to your participation and contribution to{" "}
        <strong>[Conference Name]</strong>. Your presence will enrich the event,
        and we are excited about the insights you'll bring to the discussions.
      </p>

      <div>Best regards,</div>
      <div>[Your Name]</div>
      <div>[Your Title]</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>[Your Contact Information]</div>

      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),

  "Hall Ticket, Zoom link and Confirmation": (
    <div>
      <p>
        Dear Dr. <strong>[Client Name]</strong> / <strong>[Client Name]</strong>
        ,
      </p>

      <p>
        We are excited to share the final agenda for{" "}
        <strong>[Conference Name]</strong> and provide you with all the
        necessary details to ensure a seamless and enjoyable experience.
      </p>

      <h6>
        <strong>Final Agenda:</strong>
      </h6>
      <p>
        Please find the detailed final agenda attached to this email. This
        comprehensive schedule includes all sessions, workshops, and networking
        events, allowing ample time for presentations, Q&A, and participant
        interaction.
      </p>

      <h6>
        <strong>Venue Information:</strong>
      </h6>
      <p>
        <strong>Venue Address:</strong> [Venue Name], [Street Address], [City],
        [State/Province], [Postal Code], [Country]
      </p>
      <p>
        <strong>Google Maps Link:</strong>{" "}
        <a href="[Google Maps Link]">[Google Maps Link]</a>
      </p>

      <h6>
        <strong>Zoom Link for Virtual Participation:</strong>
      </h6>
      <p>
        If you’re participating virtually, you can join the conference using the
        link below:
      </p>
      <p>
        <strong>[Access Zoom Link]</strong>
      </p>

      <h6>
        <strong>Zoom Background:</strong>
      </h6>
      <p>
        For a uniform virtual experience, you can download the official Zoom
        background here:
        <strong>Download Zoom Background</strong>
      </p>

      <h6>
        <strong>On-Site Registration Details:</strong>
      </h6>
      <p>
        <strong>Location:</strong> Registration Desk, [Venue Name], [Street
        Address]
      </p>
      <p>
        <strong>Date and Time:</strong> [Date and Time Range for Registration]
      </p>

      <h6>
        <strong>What to Bring:</strong>
      </h6>
      <ul>
        <li>A valid ID for verification</li>
        <li>A copy of your registration confirmation</li>
        <li>Any materials or devices needed for your presentation</li>
      </ul>

      <h6>
        <strong>Access Your Hall Ticket/Zoom Link:</strong>
      </h6>
      <p>
        <a href="[Access Hall Ticket/Zoom Link]">
          Access Hall Ticket/Zoom Link
        </a>
      </p>

      <p>
        If you have any questions or require further assistance, please feel
        free to contact our support team at [Support Email] or [Support Phone
        Number].
      </p>

      <p>We look forward to your participation and a successful conference!</p>

      <div>Best regards,</div>
      <div>[Your Name]</div>
      <div>[Your Title]</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>[Your Contact Information]</div>

      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),

  "Full Paper Follow-Up Email": (
    <div>
      <p>
        Dear Dr. <strong>[Client Name]</strong> / <strong>[Client Name]</strong>
        ,
      </p>

      <p>
        We hope this message finds you well. Thank you for registering for{" "}
        <strong>[Conference Name]</strong>, scheduled to take place on{" "}
        <strong>[Conference Dates]</strong> in{" "}
        <strong>[Conference Location]</strong>.
      </p>

      <p>
        As the conference approaches, we would like to remind you to submit your
        full paper by <strong>[Submission Deadline]</strong>.
      </p>

      <h6>
        <strong>Submission Details:</strong>
      </h6>
      <ul>
        <li>
          <strong>Submission Deadline:</strong> [Submission Deadline]
        </li>
        <li>
          <strong>Paper Format:</strong> Please ensure your paper follows the{" "}
          <a href="[Guidelines Link]">[guidelines provided on our website]</a>.
        </li>
        <li>
          <strong>Submission Link: Submit Full Paper</strong>
        </li>
      </ul>

      <p>
        Your full paper will undergo a rigorous review process, and selected
        papers will have the opportunity to be published in renowned Scopus and
        Web of Science indexed journals.
      </p>

      <p>
        We are excited to see your research contributions and look forward to
        your active participation at the conference. Should you have any
        questions or need assistance with your submission, please feel free to
        contact us.
      </p>

      <p>
        <a href="[Contact Support Link]">Contact Support</a>
      </p>

      <div>Best regards,</div>
      <div>[Your Name]</div>
      <div>[Your Title]</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>[Your Contact Information]</div>

      <div>
        <a href="[Your Website]">[Your Website]</a>
      </div>
    </div>
  ),
};
