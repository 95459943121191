import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Modal from "../Modal";
import { throwError } from "store/slices";
import { useDispatch } from "react-redux";
import { titleCaseString } from "utils/helpers";
import Button from "components/form/Button";
import "./CalendarFilterLayout.scss";

const headBar = [
  {
    title: "Su",
    blank: 0,
  },
  {
    title: "Mo",
    blank: 1,
  },
  {
    title: "Tu",
    blank: 2,
  },
  {
    title: "We",
    blank: 3,
  },
  {
    title: "Th",
    blank: 4,
  },
  {
    title: "Fr",
    blank: 5,
  },
  {
    title: "Sa",
    blank: 6,
  },
];

const joinDateFilterList = [
  { name: "Today" },
  { name: "Last 1 Month" },
  { name: "Last 3 Month" },
  { name: "Last 6 Month" },
  { name: "Last 1 Year" },
  { name: "custom" },
];
const validDateFilterList = [
  { name: "Today" },
  { name: "Last 10 Days" },
  { name: "Upcoming 10 Days" },
  { name: "Last 1 Month" },
  { name: "Next 1 Month" },
  { name: "Last 3 Month" },
  { name: "Next 3 Month" },
  // { name: "custom" },
];

const exportFilterOption = joinDateFilterList.filter((o) => o.name !== "Today");

const CalendarFilterLayout = ({ onHide, elem, onChange, className }) => {
  const dispatch = useDispatch();
  const monthDropDownRef = useRef();
  const yearDropDownRef = useRef();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hoveredDate, setHoveredDate] = useState("");
  const [currentDate, setCurrentDate] = useState(moment());
  const [nextMonth, setNextMonth] = useState(moment().add(1, "M"));
  const [isMonthDropdown, setIsMonthDropdown] = useState(false);
  const [isYearDropdown, setIsYearDropdown] = useState(false);
  const [isSide, setIsSide] = useState("left");
  const [selectedOption, setSelectedOption] = useState("");

  const startOfMonth = moment(currentDate)?.startOf("month");
  const endOfMonth = moment(currentDate)?.endOf("month");
  const monthStartWeek = startOfMonth?.format("dd");
  const blankBoxes = headBar?.find((o) => o?.title === monthStartWeek)?.blank;
  const monthName = currentDate?.format("MMMM");
  const monthYear = currentDate?.format("YYYY");
  const monthLastDay = +endOfMonth?.format("DD");

  //2 Month
  const startOfMonth2 = moment(nextMonth)?.startOf("month");
  const endOfMonth2 = moment(nextMonth)?.endOf("month");
  const monthStartWeek2 = startOfMonth2?.format("dd");
  const blankBoxes2 = headBar?.find((o) => o?.title === monthStartWeek2)?.blank;
  const monthName2 = nextMonth?.format("MMMM");
  const monthYear2 = nextMonth?.format("YYYY");
  const monthLastDay2 = +endOfMonth2?.format("DD");

  const handleDayClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate) {
      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  };

  const isDateSelected = (date) => {
    return (
      (startDate && moment(date).isSame(startDate, "day")) ||
      (endDate && moment(date).isSame(endDate, "day"))
    );
  };

  const isDateInRange = (date) => {
    return (
      startDate &&
      endDate &&
      moment(date).isAfter(startDate, "day") &&
      moment(date).isBefore(endDate, "day")
    );
  };

  const isHoveringBetweenDates = (date) => {
    const dateMoment = moment(date);
    return (
      startDate &&
      hoveredDate &&
      dateMoment.isBetween(startDate, hoveredDate, null, "[]")
    );
  };

  const handleMouseEnter = (date) => {
    setHoveredDate(date);
  };

  const handleMonthSelect = (index, type) => {
    const newDate = currentDate.clone().month(index);
    if (type === "left") {
      setCurrentDate(newDate);
      setIsMonthDropdown(false);
      if (startDate || endDate) {
        setStartDate("");
        setEndDate("");
      }
    } else {
      setNextMonth(newDate);
      setIsMonthDropdown(false);
      if (startDate || endDate) {
        setStartDate("");
        setEndDate("");
      }
    }
  };

  const handleYearSelect = (year, type) => {
    const newDate = currentDate.clone().year(year);
    if (type === "left") {
      setCurrentDate(newDate);
      setIsYearDropdown(false);
      if (startDate || endDate) {
        setStartDate("");
        setEndDate("");
      }
    } else {
      setNextMonth(newDate);
      setIsYearDropdown(false);
      if (startDate || endDate) {
        setStartDate("");
        setEndDate("");
      }
    }
  };

  const handleClickOutsideForMonth = (e) => {
    if (
      monthDropDownRef &&
      monthDropDownRef?.current &&
      !monthDropDownRef.current.contains(e.target)
    ) {
      setIsMonthDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideForMonth);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideForMonth);
  });

  const handleClickOutsideForYear = (e) => {
    if (
      yearDropDownRef &&
      yearDropDownRef?.current &&
      !yearDropDownRef.current.contains(e.target)
    ) {
      setIsYearDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideForYear);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideForYear);
  });

  let filterList = ["Join Date", "Date"]?.includes(elem?.title)
    ? joinDateFilterList
    : validDateFilterList;

  const filterTypeMapping = {
    "Join Date": "join_date",
    Date: "data_filter",
  };

  let filterType_id = filterTypeMapping[elem?.title] || "valid_date";
  let newFilterList = elem ? filterList : exportFilterOption;

  const handleSetPayload = (data) => {
    let newVal = {
      id: filterType_id,
      value: data,
    };

    if (data === "custom") {
      newVal = { ...newVal, start_date: startDate, end_date: endDate };
      onChange({
        target: newVal,
      });
      onHide();
      setStartDate("");
      setEndDate("");
    } else {
      onChange({
        target: newVal,
      });
      onHide();
    }
  };
  let isBothDates = startDate && endDate ? true : false;
  return (
    <Modal onHide={onHide} hideClose className={className}>
      <div className="calendar-filter-layout-container row">
        <div
          className={`${
            elem?.title === "Join Date" ? "col-md-2" : "col-md-3"
          } col-sm-3 text-12-600 color-black`}
        >
          {newFilterList?.map((list, index) => {
            return (
              <div
                className={`${
                  isBothDates
                    ? list?.name === "custom"
                      ? "color-new-car"
                      : ""
                    : selectedOption === list?.name
                    ? "color-new-car"
                    : ""
                } cmb-10 pointer`}
                key={index}
                onClick={() => {
                  if (list?.name === "custom") {
                    setSelectedOption(list?.name);
                    if (!isBothDates) {
                      // handleSetPayload(list?.name);
                      dispatch(
                        throwError({
                          message: "Please select the start date and end date",
                        })
                      );
                    }
                  } else {
                    setSelectedOption(list?.name);
                    handleSetPayload(list?.name);
                    if (startDate && endDate) {
                      setStartDate("");
                      setEndDate("");
                    }
                  }
                }}
              >
                {titleCaseString(list?.name)}
              </div>
            );
          })}
        </div>
        <div
          className={`${
            elem?.title === "Join Date" ? "col-md-10" : "col-md-9"
          } col-sm-9`}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="left-head-indicator">
                <div
                  className="d-flex pointer"
                  onClick={() => {
                    setCurrentDate(currentDate.clone().subtract(1, "M"));
                    setNextMonth(currentDate);
                  }}
                >
                  <i className="bi bi-chevron-left text-16-600 color-black" />
                </div>
                {isMonthDropdown && isSide === "left" && (
                  <span className="left-dropdown-month" ref={monthDropDownRef}>
                    {moment.months().map((month, index) => {
                      return (
                        <span
                          className="pointer"
                          key={index}
                          onClick={() => handleMonthSelect(index, "left")}
                        >
                          {month}
                        </span>
                      );
                    })}
                  </span>
                )}
                {isYearDropdown && isSide === "left" && (
                  <span className="left-dropdown-month" ref={yearDropDownRef}>
                    {Array.from(
                      { length: moment().year() - 2000 + 1 },
                      (_, i) => moment().year() - i
                    ).map((year, index) => (
                      <span
                        className="pointer"
                        key={index}
                        onClick={() => handleYearSelect(year, "left")}
                      >
                        {year}
                      </span>
                    ))}
                  </span>
                )}

                <div className="d-flex align-items-center gap-4">
                  <span
                    className="position-relative d-flex pointer"
                    onClick={() => {
                      setIsSide("left");
                      setIsMonthDropdown(true);
                    }}
                  >
                    <span className="text-18-700 color-2e44">{monthName}</span>
                    <i
                      className="bi bi-caret-down-fill color-new-car"
                      style={{
                        rotate: "-45deg",
                        position: "absolute",
                        right: "-16px",
                        bottom: "-13%",
                        fontSize: "12px",
                      }}
                    />
                  </span>

                  <span
                    className="d-flex position-relative pointer"
                    onClick={() => {
                      setIsSide("left");
                      setIsYearDropdown(true);
                    }}
                  >
                    <span className="text-18-700 color-2e44">{monthYear}</span>
                    <i
                      className="bi bi-caret-down-fill color-new-car"
                      style={{
                        rotate: "-45deg",
                        position: "absolute",
                        right: "-16px",
                        bottom: "-13%",
                        fontSize: "12px",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex w-100">
                {headBar?.map((elm, index) => {
                  return (
                    <div key={index} className="week-title text-14-600">
                      {elm?.title}
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-wrap w-100 cmb-26">
                {Array.from(Array(blankBoxes)?.keys())?.map((_, index) => {
                  return <div key={index} className="month-day" />;
                })}
                {Array.from(Array(monthLastDay)?.keys())?.map((_, index) => {
                  const renderDay = index + 1;
                  const currentDateStr = moment(currentDate)
                    .date(renderDay)
                    .format("YYYY-MM-DD");

                  return (
                    <div
                      key={index}
                      className={`month-day hover-bg ${
                        isDateSelected(currentDateStr) ? "selected-date" : ""
                      }  ${isDateInRange(currentDateStr) ? "in-range" : ""}
                         ${
                           isHoveringBetweenDates(currentDateStr)
                             ? "hover-bg-new"
                             : ""
                         }`}
                      onMouseEnter={() => {
                        if (startDate && endDate) {
                          //nothing
                        } else if (startDate) {
                          handleMouseEnter(currentDateStr);
                        }
                      }}
                      onMouseLeave={() => setHoveredDate("")}
                    >
                      <span
                        className={`day-block pointer text-14-600 
                          `}
                        onClick={() => {
                          handleDayClick(currentDateStr);
                        }}
                      >
                        {renderDay}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-head-indicator">
                <div
                  className="d-flex pointer"
                  onClick={() => {
                    setCurrentDate(nextMonth);
                    setNextMonth(nextMonth.clone().add(1, "M"));
                  }}
                >
                  <i className="bi bi-chevron-right text-16-600 color-black" />
                </div>
                {isMonthDropdown && isSide === "right" && (
                  <span className="right-dropdown-month" ref={monthDropDownRef}>
                    {moment.months().map((month, index) => {
                      return (
                        <span
                          className="pointer"
                          key={index}
                          onClick={() => handleMonthSelect(index, "right")}
                        >
                          {month}
                        </span>
                      );
                    })}
                  </span>
                )}
                {isYearDropdown && isSide === "right" && (
                  <span className="right-dropdown-month" ref={yearDropDownRef}>
                    {Array.from(
                      { length: moment().year() - 2000 + 1 },
                      (_, i) => moment().year() - i
                    ).map((year, index) => (
                      <span
                        className="pointer"
                        key={index}
                        onClick={() => handleYearSelect(year, "right")}
                      >
                        {year}
                      </span>
                    ))}
                  </span>
                )}
                <div className="d-flex align-items-center gap-4">
                  <span
                    className="position-relative d-flex pointer"
                    onClick={() => {
                      setIsSide("right");
                      setIsMonthDropdown(true);
                    }}
                  >
                    <span className="text-18-700 color-2e44">{monthName2}</span>
                    <i
                      className="bi bi-caret-down-fill color-new-car"
                      style={{
                        rotate: "-45deg",
                        position: "absolute",
                        right: "-16px",
                        bottom: "-13%",
                        fontSize: "12px",
                      }}
                    />
                  </span>
                  <span
                    className="d-flex position-relative pointer"
                    onClick={() => {
                      setIsSide("right");
                      setIsYearDropdown(true);
                    }}
                  >
                    <span className="text-18-700 color-2e44">{monthYear2}</span>
                    <i
                      className="bi bi-caret-down-fill color-new-car"
                      style={{
                        rotate: "-45deg",
                        position: "absolute",
                        right: "-16px",
                        bottom: "-13%",
                        fontSize: "12px",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex w-100">
                {headBar?.map((elm, index) => {
                  return (
                    <div key={index} className="week-title text-14-600">
                      {elm?.title}
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-wrap w-100 cmb-26">
                {Array.from(Array(blankBoxes2)?.keys())?.map((_, index) => {
                  return <div key={index} className="month-day" />;
                })}
                {Array.from(Array(monthLastDay2)?.keys())?.map((_, index) => {
                  const renderDay = index + 1;

                  const currentDateStr2 = moment(nextMonth)
                    .date(renderDay)
                    .format("YYYY-MM-DD");

                  return (
                    <div
                      key={index}
                      className={`month-day hover-bg ${
                        isDateSelected(currentDateStr2) ? "selected-date" : ""
                      } ${isDateInRange(currentDateStr2) ? "in-range" : ""}
                       ${
                         isHoveringBetweenDates(currentDateStr2)
                           ? "hover-bg-new"
                           : ""
                       }`}
                      onMouseEnter={() => {
                        if (startDate && endDate) {
                          // nothing
                        } else if (startDate) {
                          handleMouseEnter(currentDateStr2);
                        }
                      }}
                      onMouseLeave={() => setHoveredDate("")}
                    >
                      <span
                        className="day-block pointer text-14-600"
                        onClick={() => {
                          handleDayClick(currentDateStr2);
                        }}
                      >
                        {renderDay}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isBothDates && (
        <div className="d-flex justify-content-center">
          <Button
            text={"Apply"}
            className="h-35"
            btnStyle="primary-dark"
            onClick={() => {
              setSelectedOption("custom");
              handleSetPayload("custom");
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default CalendarFilterLayout;
