import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Card from "components/Layout/Card";
import Button from "components/form/Button";
import TextInput from "components/form/TextInput";
import {
  addAccount,
  setAccountsDetails,
  updateAccount,
} from "store/slices/accountsSlice";
import { useDispatch, useSelector } from "react-redux";
import { throwSuccess } from "store/slices";
import {
  getPhoneNumberLength,
  numberOnlyFromInput,
  objectToFormData,
} from "utils/helpers";
import { errorMsgForMobile, icons } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { isEqual } from "lodash";

const AddAccount = () => {
  const reduxData = useSelector((state) => state.accounts);
  const { accountsDetails } = reduxData || {};
  const navigate = useNavigate();
  const params = useParams();

  const isEdit = params?.formType === "edit-account";

  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.lazy((_, obj) => {
      const { country_code, mobile_no_length } = obj?.parent || {};
      if (country_code) {
        return Yup.string()
          .required("Contact number is required.")
          .min(mobile_no_length, errorMsgForMobile(mobile_no_length)?.min)
          .max(mobile_no_length, errorMsgForMobile(mobile_no_length)?.max);
      } else {
        return Yup.string();
      }
    }),
    accounts: Yup.string().required("Accounts is required"),
  });

  const initialValues = {
    first_name: isEdit ? accountsDetails?.first_name : "",
    last_name: isEdit ? accountsDetails?.last_name : "",
    email: isEdit ? accountsDetails?.email_id : "",
    phone: isEdit ? accountsDetails?.phone_number : "",
    country_code: isEdit ? accountsDetails?.country_code : "IN",
    accounts: "accounts",
    mobile_no_length: isEdit
      ? getPhoneNumberLength(isEdit ? accountsDetails?.country_code : "IN")
      : 10,
  };

  const handleSave = async (values, { resetForm }) => {
    setIsBtnLoading(true);
    if (isEdit) {
      handleUpdateAccounts(values, { resetForm });
    } else {
      handleAddAccount(values, { resetForm });
    }
  };

  const handleAddAccount = async (values, { resetForm }) => {
    const response = await dispatch(addAccount(objectToFormData(values)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      resetForm();
      navigate("/admin/new-account-management");
    }
    setIsBtnLoading(false);
  };

  const handleUpdateAccounts = async (values, { resetForm }) => {
    const payload = { ...values, id: accountsDetails?.id };
    const response = await dispatch(updateAccount(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      dispatch(setAccountsDetails());
      resetForm();
      navigate("/admin/new-account-management");
    }
    setIsBtnLoading(false);
  };

  return (
    <div>
      <Card className="d-flex align-items-center p-2 unset-br mb-4">
        <span
          className="d-flex ps-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={icons.leftArrow} alt="back" className="h-21 me-3 pointer" />
          {`${isEdit ? "Edit" : "Add"} Accounts`}
        </span>
      </Card>
      <Card className="cpt-32 cpe-32 cpb-32 cps-32 cmb-16">
        {/* <h2 className="text-16-400-20  cmb-20">Accounts Details</h2>
        <div className="mt-4 mb-4 border w-100 "></div> */}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form>
              <div className="row mb-3">
                <div className="col-md-6 cmb-25">
                  <TextInput
                    name="first_name"
                    label="First Name"
                    placeholder="Enter first name"
                    isRequired
                    id={"first_name"}
                    value={values?.first_name}
                    onChange={handleChange}
                    error={errors?.first_name}
                  />
                </div>
                <div className="col-md-6 cmb-25">
                  <TextInput
                    name="last_name"
                    id="last_name"
                    label="Last Name"
                    placeholder="Enter last name"
                    isRequired
                    value={values?.last_name}
                    onChange={handleChange}
                    error={errors?.last_name}
                  />
                </div>

                <div className="col-md-6 cmb-25">
                  <TextInput
                    name="email"
                    id="email"
                    label="Email ID"
                    placeholder="name@mail.com"
                    isRequired
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={TextInput}
                    error={errors?.email}
                  />
                </div>
                <div className="col-md-6 cmb-25">
                  <TextInput
                    isPhoneNumber
                    id="phone"
                    label="Contact"
                    placeholder=""
                    isRequired
                    value={values?.phone}
                    onChange={(e) => {
                      if (e.target.id === "phone") {
                        handleChange(numberOnlyFromInput(e));
                      } else {
                        handleChange(e);
                        handleChange({
                          target: { id: "phone", value: "" },
                        });
                        setFieldValue("mobile_no_length", e.target.length);
                      }
                    }}
                    phoneNumberData={{
                      id: "country_code",
                      value: values?.country_code,
                    }}
                    error={errors?.phone}
                  />
                </div>

                <div className="col-md-12">
                  <TextInput
                    name="accounts"
                    id="accounts"
                    label="Accounts"
                    isRequired
                    value={values?.accounts}
                    onChange={handleChange}
                    error={errors?.accounts}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-3 cmt-50">
                <Button
                  text="Cancel"
                  btnStyle="primary-outline cps-22 cpe-22"
                  className="h-35"
                  onClick={resetForm}
                  isRounded
                />
                <Button
                  text="Submit"
                  btnStyle="primary-dark cps-25 cpe-25"
                  className="h-35"
                  btnLoading={isBtnLoading}
                  disabled={isEqual(initialValues, values)}
                  onClick={handleSubmit}
                  isRounded
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default AddAccount;
