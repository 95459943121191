import Dropdown from "components/form/Dropdown";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import {
  findOverallMax,
  getDataFromLocalStorage,
  getYearList,
  objectToFormData,
} from "utils/helpers";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import Button from "components/form/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCountryAnalytics } from "store/slices";
import moment from "moment";
import { orderBy } from "lodash";

const CountryAnalyticsChart = ({ isReport, commonYear }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paginateLoader, setPaginateLoader] = useState(false);
  const [isLoadingCA, setIsLoadingCA] = useState(false);
  // const [countryAnalyticData, setCountryAnalyticData] = useState({});
  const [newCountryChart, setNewCountryChart] = useState({
    countries: [],
    data: [
      { name: "Student Members", data: [] },
      { name: "Professional Members", data: [] },
      { name: "Institution Members", data: [] },
    ],
  });
  const [countryList, setCountryList] = useState({
    total: 0,
    list: null,
  });
  const [page, setPage] = useState({
    from: 0,
    to: 10,
  });
  const [year, setYear] = useState({
    memberYear: moment().format("YYYY"),
    countryWiseYear: moment().format("YYYY"),
  });

  const fetchCountryAnalytics = async (yearData) => {
    setIsLoadingCA(true);
    const response = await dispatch(
      getCountryAnalytics(objectToFormData(yearData))
    );
    // setCountryAnalyticData(response?.data);
    if (response?.data) {
      const countryListWithSort = orderBy(
        Object.values(response?.data?.county_wise_member)?.map((o) => {
          return {
            ...o,
            totalMember:
              o?.student_members +
              o?.professional_members +
              o?.institution_members,
          };
        }),
        "totalMember",
        "desc"
      );
      setCountryList((prev) => {
        return {
          ...prev,
          total: Object.values(response?.data?.county_wise_member)?.length || 0,
          list: countryListWithSort,
        };
      });
      setPage({ from: 0, to: 10 });
    }
    setIsLoadingCA(false);
  };

  // const { top_rated_institutions = [] } = countryAnalyticData || {};

  // For Country Wise Analytics
  const totalPage = Math.ceil(countryList?.total / 10);
  const currentPage = page.from / 10 + 1;

  const handleNextData = () => {
    if (currentPage < totalPage) {
      const newFrom = page?.to;
      const newTo = page?.to + 10;
      setPage({ from: newFrom, to: newTo });
    }
  };
  const handlePrevData = () => {
    if (currentPage > 1) {
      const newFrom = Math.max(0, page?.from - 10);
      const newTo = Math.max(10, page?.to - 10);
      setPage({ from: newFrom, to: newTo });
    }
  };

  useEffect(() => {
    if (isReport) {
      fetchCountryAnalytics({ year: year?.countryWiseYear });
    } else {
      if (["0", "6"].includes(getDataFromLocalStorage("user_type"))) {
        //new API
        fetchCountryAnalytics({
          year: commonYear
            ? commonYear?.countryWiseYear
            : year?.countryWiseYear,
        });
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonYear]);

  useEffect(() => {
    if (countryList?.list) {
      setPaginateLoader(true);
      setTimeout(() => {
        const newList = Object.values(countryList?.list)?.slice(
          page?.from,
          page?.to
        );
        const updatedChart = {
          countries: [],
          data: [
            { name: "Student Members", data: [] },
            { name: "Professional Members", data: [] },
            { name: "Institution Members", data: [] },
          ],
        };
        newList?.forEach((items) => {
          updatedChart?.countries?.push(items?.country_name);
          updatedChart?.data[0]?.data?.push(items?.student_members);
          updatedChart?.data[1]?.data?.push(items?.professional_members);
          updatedChart?.data[2]?.data?.push(items?.institution_members);
        });
        setNewCountryChart(updatedChart);
        setPaginateLoader(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList, page]);

  const overallMaxForCountry = findOverallMax(newCountryChart?.data, 0);
  let pathName = window.location.pathname;
  return (
    <>
      <div className="col-md-12 mt-3">
        {isLoadingCA || paginateLoader ? (
          <Card className="cpt-80 cpb-80 center-flex">
            <Loader size="md" />
          </Card>
        ) : (
          <Card className="cps-30 cpe-30 cpt-30 cpb-30">
            <div className="d-flex justify-content-between align-items-center text-wrap gap-2 mb-4">
              <div className="text-18-600 title-text text-nowrap">
                Country Wise Analytics
              </div>
              {!["/admin/dashboard", "/resource/dashboard"].includes(
                pathName
              ) && (
                <div>
                  <Dropdown
                    options={getYearList(10).map((o) => {
                      return { ...o, name: o.id };
                    })}
                    optionValue="name"
                    onChange={(e) => {
                      let oldData = {
                        year: e.target.value,
                      };
                      setYear({ ...year, countryWiseYear: e.target.value });
                      fetchCountryAnalytics(oldData);
                    }}
                    value={year?.countryWiseYear}
                    placeholder="Year"
                  />
                </div>
              )}
            </div>

            <div className="country-wise-chart">
              <ReactApexChart
                series={newCountryChart?.data}
                options={{
                  chart: {
                    type: "bar",
                    height: 400,
                    toolbar: {
                      show: false,
                      offsetX: 0,
                      offsetY: 5,
                    },
                  },

                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "55%",
                      endingShape: "rounded",
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  yaxis: {
                    min: 0,
                    max: overallMaxForCountry || 500,
                  },
                  xaxis: {
                    categories: newCountryChart?.countries,
                    labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: true,
                      hideOverlappingLabels: false,
                      maxHeight: 120,
                    },
                  },
                  fill: {
                    opacity: 1,
                  },
                  colors: ["#224BC8", "#796AD2", "#3BACDF"],
                }}
                type="bar"
                height={400}
              />

              <div className="country-chart-pagination d-flex align-items-center justify-content-between w-100">
                <Button
                  icon={<i className="bi bi-chevron-left" />}
                  btnStyle={currentPage === 1 ? "primary-gray" : "primary-dark"}
                  className="h-30"
                  onClick={handlePrevData}
                  disabled={currentPage === 1}
                />

                <Button
                  rightIcon={<i className="bi bi-chevron-right" />}
                  btnStyle={
                    currentPage === totalPage ? "primary-gray" : "primary-dark"
                  }
                  className="h-30"
                  onClick={handleNextData}
                  disabled={currentPage === totalPage}
                />
              </div>
            </div>
          </Card>
        )}
      </div>

      {/* <div className="col-md-4 mt-3 d-none">
        <Card className="cps-30 cpe-30 cpt-30 cpb-30 h-100">
          <div className="text-18-600 title-text">Top Rated Institutions</div>
          <div className="rated-list-container">
            {isLoadingCA ? (
              <div className="cpt-80 cpb-80 center-flex h-100">
                <Loader size="md" />
              </div>
            ) : (
              top_rated_institutions?.map((elm, index) => {
                return (
                  <div
                    className={`rated-list-block ${index >= 3 ? "d-none" : ""}`}
                    key={index}
                  >
                    <div className="text-15-600 color-raisin-black">
                      {elm?.name}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Card>
      </div> */}
    </>
  );
};

export default CountryAnalyticsChart;
