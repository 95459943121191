const Label = ({ label, required, className, htmlFor }) => (
  <div id="field-label-container d-flex">
    <label
      className={`text-16-400 color-raisin-black ${className ? className : ""}`}
      htmlFor={`${htmlFor ? htmlFor : label}`}
    >
      {label}
      {required && "*"}
    </label>
  </div>
);

export default Label;
