import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import SliderLayout from "../SliderLayout";
import Card from "components/Layout/Card";
import TextInput from "components/form/TextInput";
import Button from "components/form/Button";
import InputOTP from "components/form/InputOTP";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import {
  campaignVerifyOTP,
  sendOTP,
  throwError,
  throwSuccess,
} from "store/slices";
import { handleCampaignLog, objectToFormData } from "utils/helpers";
import Loader from "components/Layout/Loader";
import "./CampaignLogin.scss";

const CampaignLogin = () => {
  const dispatch = useDispatch();

  const [isOTPField, setIsOTPField] = useState(false);
  const [resetOTP, setResetOTP] = useState(true);
  const [sendOTPLoader, setSendOTPLoader] = useState(false);
  const [otp, setOTP] = useState("");
  const [loadButton, setLoadButton] = useState(false);
  const [isTimeExpire, setIsTimeExpire] = useState(false);
  const [time, setTime] = useState(300);
  const [otpError, setOtpError] = useState("");

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
  });

  const handleOtp = (newOtp) => {
    if (newOtp) {
      setOTP(newOtp);
    }
  };

  const handleVerifyOTP = async (value) => {
    setLoadButton(true);

    const response = await dispatch(campaignVerifyOTP(objectToFormData(value)));

    if (response?.status === 200) {
      setOtpError("");
      setOTP("");
      dispatch(throwSuccess(response?.message));
      if (
        localStorage.getItem("isCampaign", true) &&
        response?.status === 200
      ) {
        handleCampaignLog(
          { user_id: response?.data?.id, type: "login" },
          dispatch
        );
      }
    } else {
      dispatch(throwError({ message: response?.message }));
    }

    setLoadButton(false);
  };

  const handleSendOTP = async (value) => {
    setSendOTPLoader(true);
    const response = await dispatch(sendOTP(objectToFormData(value)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      if (isTimeExpire) {
        setTime(300);
        setOtpError("");
        setOTP("");
        setResetOTP(true);
        setIsTimeExpire(false);
      } else {
        setIsOTPField(true);
      }
    } else {
      dispatch(throwError({ message: response?.message }));
    }
    setSendOTPLoader(false);
  };

  useEffect(() => {
    if (isOTPField && time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else if (time === 0) {
      setIsTimeExpire(true);
      setOtpError("OTP was expired, please resend.");
    }
  }, [isOTPField, time]);

  const formatTime = (s) => {
    const minutes = Math.floor(s / 60);
    const second = s % 60;
    return `0${minutes}:${second < 10 ? `0${second}` : second}`;
  };

  return (
    <div id="Campaign-Login-container">
      <SliderLayout>
        <Card className="m-auto card-padding">
          <div className="text-center text-28-500 font-poppins">Sign In</div>
          <div className="text-center text-16-400 mt-3 mb-4">
            Stay updated on your professional world
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (isOTPField) {
                isTimeExpire
                  ? handleSendOTP(values)
                  : handleVerifyOTP({ ...values, otp: otp });
              } else {
                handleSendOTP(values);
              }
            }}
          >
            {(props) => {
              const { values, errors, handleSubmit, handleChange } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  {isOTPField ? (
                    <>
                      {isTimeExpire ? (
                        <div className="mb-4 color-new-car d-flex align-items-center justify-content-center">
                          <span
                            className="pointer color-new-car"
                            onClick={handleSubmit}
                          >
                            Resend OTP
                          </span>
                          {sendOTPLoader && <Loader className="ms-2" />}
                        </div>
                      ) : (
                        <div className="mb-4 text-center">
                          {`Resend OTP in ${formatTime(time)} min`}
                        </div>
                      )}
                      <div className="mb-4">
                        <InputOTP
                          isSquare
                          blockSize={4}
                          resetOTP={resetOTP}
                          onSubmit={handleOtp}
                          setResetOTP={setResetOTP}
                        />
                      </div>
                      {otpError && (
                        <div className="text-center color-red mb-4">
                          {otpError}
                        </div>
                      )}
                      <div className="mb-3">
                        <Button
                          text="Verify OTP"
                          btnStyle="primary-dark"
                          isRounded
                          onClick={handleSubmit}
                          disabled={
                            loadButton ||
                            !otp ||
                            (otp && otp.length !== 4) ||
                            otpError
                          }
                          btnLoading={loadButton}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-4">
                        <TextInput
                          id="email"
                          placeholder="Enter Email"
                          value={values?.email}
                          error={errors?.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-3">
                        <Button
                          text="Send OTP"
                          btnStyle="primary-dark"
                          isRounded
                          disabled={
                            !values?.email ||
                            errors?.email ||
                            isEqual(initialValues, values)
                          }
                          onClick={handleSubmit}
                          btnLoading={sendOTPLoader}
                        />
                      </div>
                    </>
                  )}
                </form>
              );
            }}
          </Formik>
        </Card>
      </SliderLayout>
    </div>
  );
};

export default CampaignLogin;
