import React, { useEffect, useState } from "react";
import UnSubscribedPopup from "./UnSubscribedPopup";
import { useDispatch, useSelector } from "react-redux";
import { unSubscribeEmail } from "store/slices";
import { objectToFormData } from "utils/helpers";
import WaveLoader from "components/Layout/WaveLoader";
import "./UnSubscribePage.scss";

const UnSubscribePage = () => {
  const reduxData = useSelector((state) => state.global);
  const { showUnsubscribedPopup } = reduxData || {};
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [showContent, setShowContent] = useState(false);

  const handleUnsubscribe = async (data) => {
    const response = await dispatch(
      unSubscribeEmail(objectToFormData({ email: data }))
    );

    setShowContent(response);
    if (response?.status === 200) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get("user_id");
    if (userId) {
      localStorage.isUnsubscribed = 1;
      handleUnsubscribe(userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="un-subscribePage-container bg-ghost-white">
      {isLoading ? (
        <WaveLoader />
      ) : (
        showUnsubscribedPopup && <UnSubscribedPopup showContent={showContent} />
      )}
    </div>
  );
};

export default UnSubscribePage;
