import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Button from "components/form/Button";
import { map } from "lodash";
import "./ReviewerPaperStatusFilter.scss";

const ReviewerPaperStatusFilter = ({
  id,
  optionKey,
  optionValue,
  options,
  isLoading,
  disabled,
  name,
  handleStatus,
  values,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optId = optionKey || "id";
  const optVal = optionValue || "label";
  const handleSelect = (items) => {
    setSelectedOptions(items);
  };

  const handleClear = () => {
    setSelectedOptions([]);
    handleStatus({
      target: {
        id: id,
        value: "",
        data: [],
      },
    });
  };

  const handleApply = () => {
    handleStatus({
      target: {
        id: id,
        value: map(selectedOptions, "name")?.join(", "),
        data: selectedOptions,
      },
    });
  };

  const CustomOption = (props) => {
    const { isSelected } = props;
    return (
      <div className="custom-list">
        <components.Option {...props}>
          <div className="">{props?.label}</div>

          {isSelected && <i className="bi bi-check2 color-new-car" />}
        </components.Option>
      </div>
    );
  };

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        <div
          className="cmb-17"
        >
          {props.children}
        </div>
        <div className="d-flex align-items-center justify-content-start cps-14 cpt-14 cpb-14 cpe-14 gap-3 border-top">
          <Button
            text="Apply"
            btnStyle="primary-dark"
            className="text-14-500-18 h-35 text-nowrap"
            onClick={handleApply}
          />
          <Button
            text="Clear"
            btnStyle="primary-outline"
            className="text-14-500-18 h-35 text-nowrap"
            onClick={handleClear}
          />
        </div>
      </components.Menu>
    );
  };

  useEffect(() => {
    if (values && values?.length > 0) {
      const idArray = values?.split(",")?.map((val) => val?.trim());
      const matchedOptions = options?.filter((o) => {
        return idArray?.includes(o[optId] + "");
      });
      setSelectedOptions(matchedOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [values, options, optId]);
  return (
    <div className="reviewer-paper-status-filter-container">
      <Select
        isMulti
        name={name}
        placeholder={"Select"}
        options={options}
        getOptionLabel={(option) => option[optVal]}
        getOptionValue={(option) => option[optId]}
        isDisabled={disabled}
        isLoading={isLoading}
        value={selectedOptions}
        components={{ Option: CustomOption, Menu: CustomMenu }}
        className="basic-multi-select"
        classNamePrefix="select"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={handleSelect}
      />
    </div>
  );
};

export default ReviewerPaperStatusFilter;
