import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import FileUpload from "components/form/FileUpload";
import RadioInput from "components/form/RadioInput";
import TextArea from "components/form/TextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  throwError,
  throwSuccess,
  updateEventsSubmittedAbstractStatus,
} from "store/slices";
import { icons } from "utils/constants";
import {
  formatDate,
  getFilenameFromUrl,
  objectToFormData,
} from "utils/helpers";

const PlagiarismState = ({
  data,
  paperData,
  handleSuccess,
  btnLoader,
  setBtnLoader,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [btnStatus, setBtnStatus] = useState("");
  const [isSubmittedPaperDetail, setIsSubmittedPaperDetail] = useState(false);
  const [isPlagiarismRevisionDetails, setIsPlagiarismRevisionDetails] =
    useState(null);
  const [plagiarismRevisionId, setPlagiarismRevisionId] = useState("");

  const initialValues = {
    plagiarism_comment: "",
    plagiarism_document: "",
    plagiarism_document_file_name: "",
    republish_date: "",
    is_republish_by_admin: "0",
  };

  const validationSchema = Yup.object().shape({
    // plagiarism_document: Yup.string().required("Plagiarism report is required"),
    is_republish_by_admin: Yup.string().required("Selection is required"),
  });

  const {
    revision_reasons,
    plagiarism_revision,
    revision_details,
    submittedPapersStatus,
  } = paperData || {};

  const handelSave = (values, btnStatus) => {
    setBtnLoader(btnStatus);

    let payloadData = {
      id: params.paperId,
      is_revision: plagiarism_revision?.length === 0 ? "0" : "1",
      revision_id:
        plagiarism_revision?.length === 0 ? "" : plagiarismRevisionId,
    };

    switch (btnStatus) {
      case "1":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
        };
        break;
      case "2":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
          republish_date: values?.republish_date,
        };
        break;
      case "3":
        payloadData = {
          ...payloadData,
          paper_submitted_status: "2",
          plagiarism_status: btnStatus,
          plagiarism_comment: values?.plagiarism_comment,
          plagiarism_document: values?.plagiarism_document,
          republish_date: values?.republish_date,
        };
        break;

      default:
        break;
    }
    handelUpdate(payloadData);
  };

  const handelUpdate = async (obj) => {
    const payload = objectToFormData(obj);
    let response = await dispatch(updateEventsSubmittedAbstractStatus(payload));
    if (response?.status === 200) {
      handleSuccess();
      dispatch(throwSuccess(response?.message));
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader("");
  };

  const {
    paper_submitted_status,
    plagiarism_comment: plagiarismComment,
    plagiarism_accepted_date: plagiarismAcceptedData,
    plagiarism_status: plagiarismStatus,
    process_to_next: processToNext,
    revision_status,
  } = data || {};
  const { is_plagiarism_done } = submittedPapersStatus || {};

  const status = +paper_submitted_status || 1;
  // const plagiarismAccepted = +plagiarismStatus === 1;

  useEffect(() => {
    if (plagiarism_revision?.length > 0) {
      setIsPlagiarismRevisionDetails(plagiarism_revision?.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.plagiarism_revision]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            // status >= 1 && processToNext === "0" ? "active" : "not-active"
            (status >= 1 && plagiarismStatus !== "") || processToNext === "0"
              ? "active"
              : "not-active"
          }`}
        >
          {is_plagiarism_done === "1" ? (
            /* {plagiarismAccepted ? ( */
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "2"
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 d-flex align-items-center gap-2 ${
              // status >= 1 && processToNext === "0"
              (status >= 1 && plagiarismStatus !== "") || processToNext === "0"
                ? "color-new-car"
                : "color-6866"
            }`}
          >
            Plagiarism{" "}
            <i className="bi bi-chevron-down" style={{ fontSize: "15px" }} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>

        <div className="cpt-14 cpb-14 w-100">
          {revision_details?.length === 0 ? (
            <>
              {/* {!plagiarismStatus && */}
              {plagiarismStatus !== "1" &&
                revision_reasons?.length > 0 &&
                revision_reasons?.map((revComment, index) => {
                  return (
                    <div
                      className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16"
                      key={index}
                    >
                      {`Raised Revision ${index + 1}`}
                    </div>
                  );
                })}
              {/* ["1", "3"]?.includes(plagiarismStatus) &&
                  plagiarismComment && (
                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                      
                      {titleCaseString(plagiarismComment)}
                    </div>
                  )  */}

              {plagiarismStatus && plagiarismStatus !== "1" && (
                <>
                  {plagiarismAcceptedData && (
                    <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                      <img src={icons.calendarCheck} alt="calendar" />
                      {formatDate(plagiarismAcceptedData)}
                    </div>
                  )}
                </>
              )}
              {plagiarismStatus === "1" && plagiarismComment && (
                <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                  Verified !!
                </div>
              )}
              {plagiarismStatus === "3" && plagiarismComment && (
                <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                  Rejected !!
                </div>
              )}

              {((status === 1 &&
                processToNext === "0" &&
                revision_status !== "2" &&
                plagiarismStatus !== "1") ||
                (status === 2 &&
                  processToNext === "0" &&
                  revision_status !== "2" &&
                  plagiarismStatus !== "1")) &&
              plagiarismStatus !== "3" ? (
                /* status > 2 ?  */
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    if (btnStatus === "2") {
                      if (!values?.republish_date) {
                        setFieldError(
                          "republish_date",
                          "Republish date is required for revision"
                        );
                        setSubmitting(false);
                        return;
                      } else {
                        handelSave(values, "2");
                      }
                    } else if (btnStatus === "3") {
                      handelSave(values, "3");
                    } else {
                      handelSave(values, "1");
                    }

                    setSubmitting(false);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      errors,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                    } = props;

                    const loaderID =
                      values?.is_republish_by_admin === "1" ? "3" : "1";

                    return (
                      <form>
                        <div className="text-14-300-17 color-1717 cmb-14">
                          Submitted file has any plagiarized :
                        </div>
                        <div className="d-flex align-items-center gap-3 cmb-15">
                          <RadioInput
                            label="Yes"
                            labelClassName="ps-2 text-16-300-200 color-black-olive"
                            name="is_republish_by_admin"
                            id="is_republish_by_admin"
                            value="1"
                            checked={values?.is_republish_by_admin === "1"}
                            onChange={handleChange}
                          />
                          <RadioInput
                            label="No"
                            labelClassName="ps-2 text-16-300-200 color-black-olive"
                            name="is_republish_by_admin"
                            id="is_republish_by_admin"
                            value="0"
                            checked={values?.is_republish_by_admin === "0"}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="cmb-16">
                          <TextArea
                            labelClass="text-15-500 mb-1"
                            placeholder="Give your comments..."
                            id="plagiarism_comment"
                            onChange={handleChange}
                            value={values?.plagiarism_comment}
                            rows={3}
                          />
                        </div>
                        <div className="cmb-16">
                          <FileUpload
                            label="Plagiarism Report"
                            labelClass="text-15-500 mb-1"
                            fileText={getFilenameFromUrl(
                              values?.plagiarism_document_file_name
                            )}
                            id="plagiarism_document"
                            value={values?.plagiarism_document}
                            onChange={(e) => {
                              setFieldValue(
                                "plagiarism_document",
                                e.target.value
                              );
                              setFieldValue(
                                "plagiarism_document_file_name",
                                e.target.fileName
                              );
                            }}
                            // error={errors?.plagiarism_document}
                          />
                        </div>

                        <div className="cmb-16">
                          <DatePicker
                            id="republish_date"
                            label={`Republish Date ${
                              values?.is_republish_by_admin === "1" ? "*" : ""
                            }`}
                            labelClass="text-15-500 mb-1"
                            placeholder="DD-MM-YYYY"
                            minDate={moment()}
                            onChange={(e) => {
                              setFieldValue("republish_date", e.target.value);
                            }}
                            value={values?.republish_date}
                            error={errors?.republish_date}
                          />
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <Button
                            text={
                              values?.is_republish_by_admin === "1"
                                ? "Reject"
                                : "Accept"
                            }
                            btnStyle="primary-dark"
                            className="h-35 cps-15 cpe-15"
                            btnLoading={btnLoader === loaderID}
                            // disabled={!values?.plagiarism_document}
                            // disabled={
                            //   values?.plagiarism_document
                            //     ? values?.is_republish_by_admin === "1"
                            //       ? values?.plagiarism_document &&
                            //         values?.republish_date
                            //         ? false
                            //         : true
                            //       : false
                            //     : true
                            // }
                            onClick={() => {
                              setBtnStatus(
                                values?.is_republish_by_admin === "1"
                                  ? "3"
                                  : "1"
                              );
                              handleSubmit();
                            }}
                          />

                          <Button
                            text="Revision"
                            btnStyle="primary-outline"
                            className="h-35 cps-10 cpe-10"
                            btnLoading={btnLoader === "2"}
                            // disabled={!values?.plagiarism_document}
                            onClick={() => {
                              setBtnStatus("2");
                              handleSubmit();
                            }}
                          />
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                !plagiarismStatus && <div className="w-100 cpt-20 cpb-20"></div>
              )}
            </>
          ) : (
            <>
              <div className="plagiarism-paper-block border br-5 w-100 cmb-8">
                <div
                  className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                  onClick={() => {
                    setIsSubmittedPaperDetail(!isSubmittedPaperDetail);
                  }}
                >
                  <span>Submitted Paper</span>
                  <i
                    className={`${
                      isSubmittedPaperDetail
                        ? "bi bi-chevron-up"
                        : "bi bi-chevron-down"
                    }`}
                    style={{ fontSize: "15px" }}
                  />
                </div>
                {isSubmittedPaperDetail && (
                  <div className="cps-24 cpt-12 cpe-24 cpb-12">
                    {revision_reasons?.length > 0 && (
                      <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                        {`Raised Revision 1`}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {plagiarism_revision?.map((elem, index) => {
                const { plagiarism_status, is_visible_form, plagiarism_date } =
                  elem;

                const isOpen = isPlagiarismRevisionDetails === index;
                const isForm =
                  plagiarism_status === "0" && is_visible_form === "1";
                return (
                  (plagiarism_status > "0" || is_visible_form === "1") && (
                    <React.Fragment key={index}>
                      <div
                        className="revision-block border br-5 w-100 cmb-8"
                        key={index + 1}
                      >
                        <div
                          className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                          onClick={() => {
                            setIsPlagiarismRevisionDetails(
                              isOpen ? null : index
                            );
                          }}
                        >
                          <span>Revision {index + 1}</span>
                          <i
                            className={`${
                              isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        <div className="cps-24 cpe-24">
                          {isOpen &&
                            (isForm ? (
                              <div className="cpt-12 cpb-12">
                                <Formik
                                  initialValues={initialValues}
                                  validationSchema={validationSchema}
                                  onSubmit={(
                                    values,
                                    { setSubmitting, setFieldError }
                                  ) => {
                                    if (btnStatus === "2") {
                                      if (!values?.republish_date) {
                                        setFieldError(
                                          "republish_date",
                                          "Republish date is required for revision"
                                        );
                                        setSubmitting(false);
                                        return;
                                      } else {
                                        handelSave(values, "2");
                                      }
                                    } else if (btnStatus === "3") {
                                      handelSave(values, "3");
                                    } else {
                                      handelSave(values, "1");
                                    }

                                    setSubmitting(false);
                                  }}
                                >
                                  {(props) => {
                                    const {
                                      values,
                                      errors,
                                      handleChange,
                                      setFieldValue,
                                      handleSubmit,
                                    } = props;

                                    const loaderID =
                                      values?.is_republish_by_admin === "1"
                                        ? "3"
                                        : "1";

                                    return (
                                      <form>
                                        <div className="text-14-300-17 color-1717 cmb-14">
                                          Submitted file has any plagiarized :
                                        </div>
                                        <div className="d-flex align-items-center gap-3 cmb-15">
                                          <RadioInput
                                            label="Yes"
                                            labelClassName="ps-2 text-16-300-200 color-black-olive"
                                            name="is_republish_by_admin"
                                            id="is_republish_by_admin"
                                            value="1"
                                            checked={
                                              values?.is_republish_by_admin ===
                                              "1"
                                            }
                                            onChange={handleChange}
                                          />
                                          <RadioInput
                                            label="No"
                                            labelClassName="ps-2 text-16-300-200 color-black-olive"
                                            name="is_republish_by_admin"
                                            id="is_republish_by_admin"
                                            value="0"
                                            checked={
                                              values?.is_republish_by_admin ===
                                              "0"
                                            }
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div className="cmb-16">
                                          <TextArea
                                            labelClass="text-15-500 mb-1"
                                            placeholder="Give your comments..."
                                            id="plagiarism_comment"
                                            onChange={handleChange}
                                            value={values?.plagiarism_comment}
                                            rows={3}
                                          />
                                        </div>
                                        <div className="cmb-16">
                                          <FileUpload
                                            label="Plagiarism Report"
                                            labelClass="text-15-500 mb-1"
                                            fileText={getFilenameFromUrl(
                                              values?.plagiarism_document_file_name
                                            )}
                                            id="plagiarism_document"
                                            value={values?.plagiarism_document}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "plagiarism_document",
                                                e.target.value
                                              );
                                              setFieldValue(
                                                "plagiarism_document_file_name",
                                                e.target.fileName
                                              );
                                            }}
                                            // error={errors?.plagiarism_document}
                                          />
                                        </div>

                                        <div className="cmb-16">
                                          <DatePicker
                                            id="republish_date"
                                            label={`Republish Date ${
                                              values?.is_republish_by_admin ===
                                              "1"
                                                ? "*"
                                                : ""
                                            }`}
                                            labelClass="text-15-500 mb-1"
                                            placeholder="DD-MM-YYYY"
                                            minDate={moment()}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "republish_date",
                                                e.target.value
                                              );
                                            }}
                                            value={values?.republish_date}
                                            error={errors?.republish_date}
                                          />
                                        </div>

                                        <div className="d-flex align-items-center gap-2">
                                          <Button
                                            text={
                                              values?.is_republish_by_admin ===
                                              "1"
                                                ? "Reject"
                                                : "Accept"
                                            }
                                            btnStyle="primary-dark"
                                            className="h-35 cps-15 cpe-15"
                                            btnLoading={btnLoader === loaderID}
                                            // disabled={
                                            //   !values?.plagiarism_document
                                            // }
                                            onClick={() => {
                                              setPlagiarismRevisionId(elem?.id);
                                              setBtnStatus(
                                                values?.is_republish_by_admin ===
                                                  "1"
                                                  ? "3"
                                                  : "1"
                                              );
                                              handleSubmit();
                                            }}
                                          />

                                          <Button
                                            text="Revision"
                                            btnStyle="primary-outline"
                                            className="h-35 cps-10 cpe-10"
                                            btnLoading={btnLoader === "2"}
                                            // disabled={
                                            //   !values?.plagiarism_document
                                            // }
                                            onClick={() => {
                                              setPlagiarismRevisionId(elem?.id);
                                              setBtnStatus("2");
                                              handleSubmit();
                                            }}
                                          />
                                        </div>
                                      </form>
                                    );
                                  }}
                                </Formik>
                              </div>
                            ) : (
                              <div className="cpt-12 cpb-12">
                                {plagiarism_date && (
                                  <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                                    <img
                                      src={icons.calendarCheck}
                                      alt="calendar"
                                    />
                                    {formatDate(plagiarism_date)}
                                  </div>
                                )}
                                {plagiarism_status === "2" && (
                                  <>
                                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                                      {`Raised Revision `}
                                    </div>
                                  </>
                                )}
                                {plagiarism_status === "1" && (
                                  <>
                                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                                      {`Verified !!`}
                                    </div>
                                  </>
                                )}
                                {plagiarism_status === "3" && (
                                  <>
                                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                                      {`Rejected !!`}
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PlagiarismState;
