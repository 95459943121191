import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import {
  fetchProfile,
  setAttendancePayload,
  setIsAttendanceConferencePopup,
  setIsAttendanceConfirmationPopup,
  takingAttendance,
  throwError,
  throwSuccess,
} from "store/slices";
import Button from "components/form/Button";
import "./AttendanceConfirmationPopup.scss";
import { objectToFormData } from "utils/helpers";

const AttendanceConfirmationPopup = () => {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.student);
  const { attendancePayload } = reduxData || {};
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmAttendance = async () => {
    setIsLoading(true);
    const response = await dispatch(
      takingAttendance(objectToFormData(attendancePayload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      dispatch(setIsAttendanceConferencePopup(false));
      dispatch(setIsAttendanceConfirmationPopup(false));
      dispatch(setAttendancePayload({}));
      dispatch(fetchProfile());
    } else {
      dispatch(throwError({ message: response?.message }));
    }
    setIsLoading(false);
  };

  return (
    <Modal
      onHide={() => {
        dispatch(setIsAttendanceConferencePopup(false));
        dispatch(setIsAttendanceConfirmationPopup(false));
        dispatch(setAttendancePayload({}));
      }}
      size="md"
      title={"Conference Attendance"}
    >
      <div className="attendance-confirmation-popup-container cps-25 cpe-25">
        <div className="text-16-300-20 color-black-olive cmt-25 cmb-25">
          Please confirm the attendance :
        </div>
        <div className="cpe-25 cmb-25 d-flex gap-3">
          <Button
            btnStyle={"primary-dark"}
            text="Yes, Present"
            className={"cps-20 cpe-20 h-35"}
            onClick={() => {
              handleConfirmAttendance();
            }}
            btnLoading={isLoading}
          />
          <Button
            btnStyle={"light-outline"}
            text="Cancel"
            className={"cps-20 cpe-20 h-35"}
            onClick={() => {
              dispatch(setIsAttendanceConferencePopup(false));
              dispatch(setIsAttendanceConfirmationPopup(false));
              dispatch(setAttendancePayload({}));
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AttendanceConfirmationPopup;
