// import DatePicker from "components/form/DatePicker";
import DateTimePicker from "components/form/DateTimePicker";
import TextInput from "components/form/TextInput";
import React from "react";

// Hall Ticket, Zoom link and Confirmation
const SevenStepOfFollowUps = ({ props }) => {
  const { handleChange, values } = props;
  const {
    conference_name,
    client_name,
    venue_name,
    street_address,
    city,
    state,
    postal_code,
    country,
    map_link,
    zoom_link,
    download_zoom,
    registration_venue_name,
    registration_street_address,
    registration_date_time,
    hall_ticket_link,
    support_email,
    support_phone,
    your_name,
    your_title,
    your_contact_information,
    your_website,
    // venue_map_link,
  } = values;

  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Venue Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="venue_name"
            onChange={handleChange}
            value={venue_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Street Address
        </div>
        <div className="col-lg-6">
          <TextInput
            id="street_address"
            onChange={handleChange}
            value={street_address}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">City</div>
        <div className="col-lg-6">
          <TextInput id="city" onChange={handleChange} value={city} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          State/Province
        </div>
        <div className="col-lg-6">
          <TextInput id="state" onChange={handleChange} value={state} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Postal Code
        </div>
        <div className="col-lg-6">
          <TextInput
            id="postal_code"
            onChange={handleChange}
            value={postal_code}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">Country</div>
        <div className="col-lg-6">
          <TextInput id="country" onChange={handleChange} value={country} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Google Maps Link
        </div>
        <div className="col-lg-6">
          <TextInput id="map_link" onChange={handleChange} value={map_link} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Access Zoom Link
        </div>
        <div className="col-lg-6">
          <TextInput id="zoom_link" onChange={handleChange} value={zoom_link} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Download Zoom Background
        </div>
        <div className="col-lg-6">
          <TextInput
            id="download_zoom"
            onChange={handleChange}
            value={download_zoom}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Registration Venue Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="registration_venue_name"
            onChange={handleChange}
            value={registration_venue_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Registration Street Address
        </div>
        <div className="col-lg-6">
          <TextInput
            id="registration_street_address"
            onChange={handleChange}
            value={registration_street_address}
          />
        </div>
      </div>
      {/* <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Google Maps Link
        </div>
        <div className="col-lg-6">
          <TextInput
            id="venue_map_link"
            onChange={handleChange}
            value={venue_map_link}
          />
        </div>
      </div> */}
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Registration Date and Time
        </div>
        <div className="col-lg-6">
          <DateTimePicker
            id="registration_date_time"
            onChange={handleChange}
            value={registration_date_time}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Hall Ticket/Zoom Link
        </div>
        <div className="col-lg-6">
          <TextInput
            id="hall_ticket_link"
            onChange={handleChange}
            value={hall_ticket_link}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Support Email
        </div>
        <div className="col-lg-6">
          <TextInput
            id="support_email"
            onChange={handleChange}
            value={support_email}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Support Phone Number
        </div>
        <div className="col-lg-6">
          <TextInput
            id="support_phone"
            onChange={handleChange}
            value={support_phone}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput id="your_name" onChange={handleChange} value={your_name} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
          />
        </div>
      </div>
    </>
  );
};

export default SevenStepOfFollowUps;
