import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import { objectToFormData, titleCaseString } from "utils/helpers";
import Button from "components/form/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { icons, limit } from "utils/constants";
import Table from "components/Layout/Table";
import DeletePopup from "components/Layout/DeletePopup";
import {
  deleteAccountsUser,
  exportAccountsList,
  fetchAllAccountsList,
  setAccountsDetails,
} from "store/slices/accountsSlice";
import "./AccountsAccountManagement.scss";

const AccountsAccountManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
  });
  const [searchPayload, setSearchPayload] = useState({
    name: "",
    member_id: "",
    email_id: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [accountsId, setAccountsId] = useState(null);

  const fetchList = async (obj) => {
    const response = await dispatch(
      fetchAllAccountsList(objectToFormData(obj))
    );

    setTableList(response?.data?.account_users || []);
    setFilterData({ ...obj, total: response?.data?.result_count || 0 });
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    fetchList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchList(newData);
  };

  useEffect(() => {
    fetchList({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: true,
      searchInputName: "name",
      title: <span className="text-nowrap">Accounts Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "member_id",
      title: <span className="text-nowrap">Accounts ID</span>,
    },
    {
      isSearch: true,
      searchInputName: "email_id",
      title: <span className="text-nowrap">Email ID</span>,
    },

    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];

  const rowData = [];
  tableList?.forEach((elem) => {
    let obj = [
      {
        value: (
          <span className="text-nowrap pointer color-new-car">
            {titleCaseString(elem?.name)}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{elem?.member_id}</span>,
      },
      {
        value: <span className="text-nowrap">{elem?.email_id}</span>,
      },
      {
        value: (
          <span className="action-icon-buttons">
            <Button
              onClick={() => {
                navigate(`/admin/account-management/details/${elem?.id}`);
              }}
              text="View"
              btnStyle="primary-light"
              className="text-14-500 mw-70 me-2"
              isSquare
            />
            <Button
              btnStyle="light-outline"
              icon={<img src={icons.edit} alt="edit" />}
              className="me-2"
              onClick={() => {
                dispatch(setAccountsDetails(elem));
                navigate("/admin/account-management/edit-account");
              }}
              isSquare
            />
            <Button
              btnStyle="light-outline"
              icon={<img src={icons.deleteIcon} alt="delete" />}
              onClick={() => {
                setAccountsId(elem?.id);
              }}
              isSquare
            />
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <>
      {accountsId && (
        <DeletePopup
          id={accountsId}
          onHide={() => {
            setAccountsId(null);
          }}
          handelSuccess={() => {
            setAccountsId(null);
            fetchList({ ...filterData, ...searchPayload });
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ id: accountsId });
            const response = await dispatch(deleteAccountsUser(forData));
            return response;
          }}
        />
      )}

      <Card className="accounts-account-management cps-20 cpe-20 cpb-20">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 cpt-28 cpb-28">
          <div className="table-title">
            Allocated Accounts ({filterData?.total})
          </div>
          <div className="d-flex gap-3">
            <ExportButton
              exportAPI={exportAccountsList}
              payload={objectToFormData({
                ...filterData,
                ...searchPayload,
              })}
            />
            <Button
              onClick={() => navigate("/admin/account-management/add-account")}
              text="+ Add Accounts"
              btnStyle="primary-outline text-nowrap"
              className="h-35 text-14-500"
              isSquare
            />
          </div>
        </div>
        <Table
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </Card>
    </>
  );
};

export default AccountsAccountManagement;
