import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Modal from "components/Layout/Modal";
import Profile from "components/Layout/Profile";
import Table from "components/Layout/Table";
import { omit } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { exportSingleUserLogs, fetchSingleLogData } from "store/slices";
import { icons, limit, moduleData } from "utils/constants";
import {
  formatSeconds,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";

const SessionDetailsPopup = ({ onHide, title, data }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [filterDates, setFilterDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    tab_type: "session",
  });
  const [searchPayload, setSearchPayload] = useState({
    start_date: "",
    end_date: "",
  });

  const getSingleSessionData = async (obj) => {
    setIsLoading(true);
    const payload = omit(obj, ["total"]);
    const response = await dispatch(
      fetchSingleLogData(data?.id, objectToFormData(payload))
    );
    let resList = [];
    let resResultCount = 0;
    if (response?.data?.data) {
      resList = response?.data?.data?.pageTimeListData || [];
      resResultCount = response?.data?.total_count || 0;
    }
    setFilterData({ ...obj, total: resResultCount });
    setSessionData(resList);
    setIsLoading(false);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getSingleSessionData(newData);
  };

  const handleDateRangeChange = (range) => {
    const [startDate, endDate] = range;
    let newDates = { start_date: startDate, end_date: endDate };
    setFilterDates(newDates);

    let newData = {
      ...filterData,
      ...searchPayload,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    };
    setSearchPayload({
      ...searchPayload,
      ...newData,
    });
    getSingleSessionData({
      ...filterData,
      ...newData,
    });
  };

  useEffect(() => {
    if (data?.id) {
      getSingleSessionData({ ...filterData, ...searchPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  const header = [
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">User id</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Name</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Email</div>,
    },
    // {
    //   isSearch: false,
    //   searchInputName: "search",
    //   title: <div className="text-nowrap">Module Route</div>,
    // },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Module</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Ideal Time</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Date</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">Start Time</div>,
    },
    {
      isSearch: false,
      searchInputName: "search",
      title: <div className="text-nowrap">End Time</div>,
    },
  ];
  const rowData = [];
  sessionData?.forEach((elem) => {
    const { idel_time, page_slug, start_time, end_time } = elem;
    const { id, profile_photo_path, first_name, last_name, email_id } =
      data || {};
    const moduleName = moduleData?.find((o) => o?.route?.includes(page_slug));

    let obj = [
      {
        value: <div className="text-start cps-5 text-nowrap">{id}</div>,
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap d-flex align-items-center gap-2">
            <span>
              <Profile
                text={`${first_name} ${last_name}`}
                size="s-30"
                url={profile_photo_path}
                isS3UserURL
                isRounded
              />
            </span>
            <span className="color-new-car">
              {titleCaseString(`${first_name} ${last_name}`)}
            </span>
          </div>
        ),
      },
      {
        value: <div className="text-start cps-5 text-nowrap">{email_id}</div>,
      },
      // {
      //   value: (
      //     <div className="text-start cps-5 text-nowrap">
      //       {page_slug ? page_slug : "-"}
      //     </div>
      //   ),
      // },
      {
        value: (
          <div className="text-start cps-5 text-nowrap d-flex flex-column">
            <span className="">{`Module Name: ${
              moduleName ? moduleName?.moduleName : "-"
            }`}</span>
            {moduleName?.subModuleName && (
              <span className="">{`Page: ${moduleName?.subModuleName}`}</span>
            )}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {idel_time ? formatSeconds(idel_time) : "-"}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(start_time).format("DD.MM.YYYY")}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(start_time).format("HH:mm:ss")}
          </div>
        ),
      },
      {
        value: (
          <div className="text-start cps-5 text-nowrap">
            {moment(end_time).format("HH:mm:ss")}
          </div>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <Modal onHide={onHide} title={title} size={"xl"}>
      <div>
        <Card className="cps-20 cpe-20 cpb-20">
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 cpt-28 cpb-28">
            <div className="table-title">{`All Session Logs`}</div>

            <div className="d-flex gap-2">
              <div id="date-picker-container">
                <div className="input-container">
                  <DatePicker
                    selected=""
                    onChange={handleDateRangeChange}
                    startDate={filterDates?.start_date}
                    endDate={filterDates?.end_date}
                    placeholderText="Select Date Range"
                    selectsRange
                    isClearable={
                      filterDates?.start_date || filterDates?.end_date
                        ? true
                        : false
                    }
                  />
                  {!filterDates?.start_date && (
                    <span className={`calender-icon w-fit`}>
                      <img src={icons.calendar} alt="calender" />
                    </span>
                  )}
                </div>
              </div>
              <ExportButton
                exportAPI={exportSingleUserLogs}
                payloadID={data?.id}
                payload={objectToFormData({
                  start_date: searchPayload?.start_date,
                  end_date: searchPayload?.start_date,
                  tab_type: "session",
                })}
                newHeight="h-45"
              />
            </div>
          </div>
          <div className="overflow-auto w-100">
            <Table
              header={header}
              rowData={rowData}
              isLoading={isLoading}
              filterData={filterData}
              changeOffset={handelChangePagination}
            />
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default SessionDetailsPopup;
