import { map, includes } from "lodash";
import Select, { components } from "react-select";
import CheckBox from "../CheckBox";
import Label from "../Label";
import Button from "../Button";
import "./MultipleSelectWithFixed.scss";

const MultipleSelectWithFixed = ({
  value,
  placeholder,
  options,
  error,
  id,
  optionKey,
  optionValue,
  onChange,
  isLoading,
  disabled,
  name,
  label,
  isRequired,
  labelClass,
  isApplyButton,
  onApplyClick,
}) => {
  const optId = optionKey || "id";
  const optVal = optionValue || "label";

  let fillValue = null;
  if (value) {
    const idArray = value?.split(",");
    fillValue = options?.filter((o) => includes(idArray, o[optId] + ""));
  }

  const handleDeselectOption = (e, actionMeta) => {
    if (!actionMeta?.option?.isFixed) {
      onChange({
        target: {
          id: id,
          value: map(e, "id")?.join(","),
          data: e,
        },
      });
    }
  };

  const handleRemoveValue = (e, actionMeta) => {
    if (!actionMeta?.removedValue?.isFixed) {
      onChange({
        target: {
          id: id,
          value: map(e, "id")?.join(","),
          data: e,
        },
      });
    }
  };

  const handleClear = () => {
    const fixedValues = options?.filter((v) => v?.isFixed);
    onChange({
      target: {
        id: id,
        value: map(fixedValues, "id")?.join(","),
        data: fixedValues,
      },
    });
  };

  const handleDefaultChange = (e) => {
    onChange({
      target: {
        id: id,
        value: map(e, "id")?.join(","),
        data: e,
      },
    });
  };

  const handlePopVal = (e) => {
    const fixedValues = options?.filter((v) => v?.isFixed);
    onChange({
      target: {
        id: id,
        value: map(fixedValues, "id")?.join(","),
        data: fixedValues,
      },
    });
  };

  const handleOnChange = (e, actionMeta) => {
    switch (actionMeta.action) {
      case "deselect-option":
        handleDeselectOption(e, actionMeta);
        break;
      case "remove-value":
        handleRemoveValue(e, actionMeta);
        break;
      case "clear":
        handleClear();
        break;
      case "pop-value":
        handlePopVal();
        break;
      default:
        handleDefaultChange(e);
        break;
    }
  };

  const Option = (props) => {
    let sLen = fillValue ? fillValue?.length : 0;

    return (
      <div>
        {props?.value === "ALL" ? (
          <div
            className={`d-flex align-items-center cps-12 pointer pt-2 pb-2 ${
              sLen === props?.options?.length - 1 ? "selected-blobk" : ""
            }`}
            onClick={() => {
              if (sLen === props?.options?.length - 1) {
                onChange({
                  target: {
                    id: id,
                    value: "",
                    data: props?.options,
                  },
                });
              } else {
                let allIds = map(props?.options, "id")?.join(",");
                let selectID = allIds?.replace("ALL,", "");
                onChange({
                  target: {
                    id: id,
                    value: selectID,
                    data: props?.options,
                  },
                });
              }
            }}
          >
            <div className="me-2 multiple-check">
              <CheckBox
                type="PRIMARY-ACTIVE"
                onClick={() => {}}
                isChecked={sLen === props?.options?.length - 1}
              />
            </div>
            <div> Select All</div>
          </div>
        ) : (
          <components.Option {...props}>
            <div className="d-flex align-items-center justify-content-between">
              <div> {props?.label}</div>
              <div className="me-2 multiple-check">
                <CheckBox
                  type="PRIMARY-ACTIVE"
                  onClick={() => {}}
                  isChecked={props?.isSelected}
                />
              </div>
            </div>
          </components.Option>
        )}
      </div>
    );
  };

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}

        {isApplyButton && (
          <div className="d-flex align-items-center justify-content-start cmb-10 cps-16 cpt-5 cpe-16">
            <Button
              text="Apply"
              btnStyle="primary-dark"
              className="text-12-500 h-auto text-nowrap"
              onClick={() => {
                onApplyClick(value);
              }}
            />
          </div>
        )}
      </components.Menu>
    );
  };

  const OrderOptions = (values) => {
    return values
      ?.filter((v) => v?.isFixed)
      ?.concat(values?.filter((v) => !v?.isFixed));
  };

  // const styles = {
  //   multiValue: (base, state) => {
  //     return state.data.isFixed ? { ...base, backgroundColor: "none" } : base;
  //   },
  //   multiValueLabel: (base, state) => {
  //     return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
  //   },
  //   multiValueRemove: (base, state) => {
  //     return state.data.isFixed ? { ...base, display: "none" } : base;
  //   },
  //   option: (base, state) => {
  //     return {
  //       ...base,
  //       backgroundColor: state.isSelected ? "none" : base.backgroundColor,
  //       color: "black",
  //       padding: "8px 12px",
  //     };
  //   },
  // };

  return (
    <div id="multipleSelect-container">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <Select
        isMulti
        onChange={handleOnChange}
        value={fillValue}
        name={name}
        // styles={styles}
        options={OrderOptions(options)}
        components={{
          Option,
          Menu,
        }}
        getOptionLabel={(option) => option[optVal]}
        getOptionValue={(option) => option[optId]}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={isLoading}
        className="basic-multi-select"
        classNamePrefix="select"
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isClearable={fillValue && fillValue?.some((v) => !v.isFixed)}
      />

      {error && (
        <span className="text-13-400 pt-1">
          <span style={{ color: "red" }}>{error}</span>
        </span>
      )}
    </div>
  );
};

export default MultipleSelectWithFixed;
