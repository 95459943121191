import React, { useEffect, useState } from "react";
import SeachInput from "components/form/SeachInput";
import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import { useDispatch, useSelector } from "react-redux";
import { limit } from "utils/constants";
import { objectToFormData, titleCaseString } from "utils/helpers";
import { fetchAttendancesList } from "store/slices";
import { useParams } from "react-router-dom";

const getAttendanceStatusLabelStyle = (label) => {
  if (label === 0) {
    return "color-C991";
  }
  if (label === 1) {
    return "color-a35b";
  }
  if (label === 2) {
    return "color-4646";
  }
};

const AttendancesList = () => {
  const params = useParams();
  console.log("params", params);
  const dispatch = useDispatch();
  const { membershipList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
  }));
  const [globalSearchVal, setGlobalSearchVal] = useState("");
  const [timer, setTimer] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [searchPayload, setSearchPayload] = useState({
    user_name: "",
    membership_id: "",
    membership_type: "",
    join_as: "",
    confer_name: "",
    attendance: "",
    paper_count: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    event_id: params?.eventId,
  });

  const getAttendancesList = async (obj) => {
    const response = await dispatch(
      fetchAttendancesList(null, objectToFormData(obj))
    );
    setTableList(response?.data?.registered_user || []);
    setFilterData({
      ...obj,
      total: response?.data?.result_count || 0,
    });
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getAttendancesList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getAttendancesList(newData);
  };

  const handleGlobalSearch = (e) => {
    setGlobalSearchVal(e.target.value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let newData = { ...filterData, ...searchPayload };
      newData = { ...newData, global_filter: e.target.value, offset: 0 };
      setIsLoading(true);
      setFilterData(newData);
      getAttendancesList(newData);
    }, 800);
    setTimer(time);
  };

  useEffect(() => {
    getAttendancesList({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,
      searchInputName: "",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "user_name",
      title: "Name",
    },
    {
      isSearch: true,
      searchInputName: "membership_id",
      title: <span className="text-nowrap">Membership Id</span>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      searchInputName: "membership_type",
      dropdownOptions: {
        options: membershipList,
        key: "name",
        value: "name",
      },
      title: <span className="text-nowrap">Membership Type</span>,
    },
    {
      isSearch: true,
      searchInputName: "join_as",
      title: "Join As",
    },
    {
      isSearch: true,
      searchInputName: "confer_name",
      title: <span className="text-nowrap">Conference Name</span>,
    },
    {
      isSearch: true,
      searchInputName: "attendance",
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          { id: 0, name: "Pending" },
          { id: 1, name: "Present" },
          { id: 2, name: "Absent" },
        ],
        key: "id",
        value: "name",
      },
      title: <span className="text-nowrap">Attendance</span>,
    },
    {
      isSearch: true,
      searchInputName: "paper_count",
      title: <span className="text-nowrap">Paper Count</span>,
    },
  ];
  const rowData = [];
  tableList?.forEach((elem, index) => {
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: <span className="text-nowrap">{elem?.user_name}</span>,
      },
      {
        value: <span className="text-nowrap">{elem?.membership_id}</span>,
      },
      {
        value: <span className="text-nowrap">{elem?.membership_type}</span>,
      },
      {
        value: (
          <span className="text-nowrap">{titleCaseString(elem?.join_as)}</span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {titleCaseString(elem?.confer_name)}
          </span>
        ),
      },
      {
        value: (
          <span
            className={`${getAttendanceStatusLabelStyle(
              elem?.attendance
            )} text-wrap`}
          >
            {elem?.attendance === 0
              ? "Pending"
              : elem?.attendance === 1
              ? "Present"
              : "Absent"}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{elem?.paper_count}</span>,
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <Card className="cps-20 cpe-20 cpb-20 mt-3">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28">
        <div className="table-title">All Attendances ({filterData?.total})</div>
        <div className="d-flex align-items-center flex-wrap gap-3">
          <div className="d-flex">
            <SeachInput
              placeholder="Search here"
              id="global_filter"
              value={globalSearchVal}
              onChange={handleGlobalSearch}
            />
          </div>
          <ExportButton
            newHeight={"h-45"}
            exportAPI={fetchAttendancesList}
            payload={objectToFormData({
              ...filterData,
              ...searchPayload,
              export_status: 1,
            })}
            payloadID={{ isExport: 1 }}
          />
        </div>
      </div>
      <Table
        isLoading={isLoading}
        header={header}
        rowData={rowData}
        filterData={filterData}
        searchPayload={searchPayload}
        searchInputChange={handelChangeSearch}
        changeOffset={handelChangePagination}
      />
    </Card>
  );
};

export default AttendancesList;
