import React, { useEffect, useState } from "react";
import Loader from "components/Layout/Loader";
import { icons } from "utils/constants";
import Card from "components/Layout/Card";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountsDetails } from "store/slices";
import { titleCaseString } from "utils/helpers";
import "./ViewAccountsDetails.scss";

const ViewAccountsDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [accountsDetail, setAccountsDetails] = useState({});
  const getDetails = async () => {
    const response = await dispatch(accountsDetails(params?.account_id));
    setAccountsDetails(response?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { first_name, last_name, email, phone_number, member_id } =
    accountsDetail;
  return (
    <div id="accounts-details-container">
      <div className="cpt-12 cpb-12 d-flex align-items-center justify-content-between cmb-12">
        <div className="d-flex align-items-center">
          <span
            className="d-flex"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img
              src={icons.leftArrow}
              alt="back"
              className="h-21 me-3 pointer"
            />
          </span>
          <span className="text-18-500 color-black-olive">View Details</span>
        </div>
      </div>
      <Card>
        <div className="cps-28 cpe-28 cpt-22 cpb-22 d-flex align-items-center justify-content-between">
          <div className="text-18-500 color-title-navy font-poppins">
            Accounts Details
          </div>
        </div>
        <hr className="unset-m unset-p" />
        {isLoading ? (
          <div className="pt-5 pb-5">
            <Loader size="md" />
          </div>
        ) : (
          <div className="row cps-28 cpe-28 cpt-22 cpb-22">
            <div className="row cmb-20">
              <div className="col-md-3 text-16-400 color-black-olive">
                Account Name
              </div>
              <div className="col-md-9 text-16-500 color-black-olive">
                {titleCaseString(`${first_name} ${last_name}`)}
              </div>
            </div>
            <div className="row cmb-20">
              <div className="col-md-3 text-16-400 color-black-olive">
                Account ID
              </div>
              <div className="col-md-9 text-16-500 color-black-olive">
                {member_id}
              </div>
            </div>
            <div className="row cmb-20">
              <div className="col-md-3 text-16-400 color-black-olive">
                Email ID
              </div>
              <div className="col-md-9 text-16-500 color-black-olive">
                {email}
              </div>
            </div>
            <div className="row cmb-20">
              <div className="col-md-3 text-16-400 color-black-olive">
                Contact Number
              </div>
              <div className="col-md-9 text-16-500 color-black-olive">
                {phone_number}
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ViewAccountsDetails;
