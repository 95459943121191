import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import FilterDropdown from "components/Layout/FilterDropdown";
import Table from "components/Layout/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  exportSubmittedAbstracts,
  fetchSubmittedAbstracts,
} from "store/slices";
import { limit } from "utils/constants";
import {
  getOtherStatus,
  getStatus,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import CommentAddView from "../CommentAddView";

const Overall = ({ eventId }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { memberType, moduleType } = params;
  const { membershipList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
  }));
  const [tableList, setTableList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPayload, setSearchPayload] = useState({
    author_name: "",
    member_id: "",
    member_type: "",
    paper_id: "",
    paper_title: "",
    conference: "",
    status: "",
    statusType: "",
    statuss: "",
    abstract_id_or_title: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    membership_plan_id: "",
    event_id: eventId || "",
  });

  const getEventAbstarcts = async (obj) => {
    let formData = new FormData();
    if (obj?.statusValue) {
      obj?.statusValue?.forEach((value) => {
        formData.append(
          `status[${obj?.statusType?.toLowerCase()}][${value}]`,
          value
        );
      });
    }
    const keysToOmit = ["statusType", "statuss", "statusValue", "status"];
    for (const key in obj) {
      if (!keysToOmit.includes(key)) {
        formData.append(key, obj[key]);
      }
    }
    const response = await dispatch(fetchSubmittedAbstracts(formData));
    setTableList(response?.data?.abstracts || []);
    setFilterData({
      ...obj,
      total: response?.data?.result_count || 0,
    });
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getEventAbstarcts(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getEventAbstarcts(newData);
  };

  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, membership_plan_id: val, offset: 0 };
    setFilterData(newData);
    getEventAbstarcts(newData);
  };

  const handleRedirect = (paperId) => {
    let newModuleType = "";

    if (!moduleType && window.location.pathname.includes("my-profile")) {
      newModuleType = "my-profile";
    } else {
      newModuleType = moduleType;
    }
    navigate(`/${memberType}/${newModuleType}/new-submitted-papers/${paperId}`);
  };

  useEffect(() => {
    getEventAbstarcts({ ...filterData, ...searchPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPayload]);

  let header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "author_name",
      title: "Author",
    },
    {
      isSearch: true,
      searchInputName: "member_id",
      title: <div>Member ID</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: { options: membershipList, key: "name", value: "name" },
      searchInputName: "member_type",
      title: <div className="text-nowrap">Member Type</div>,
    },
    {
      isSearch: true,
      searchInputName: "paper_id",
      title: "Paper ID",
    },
    {
      isSearch: true,
      searchInputName: "paper_title",
      title: "Paper Title",
    },
    {
      isSearch: true,
      searchInputName: "conference",
      title: "Conference Name",
    },
    {
      isSearch: true,
      isPaperStatusFilter: true,
      handlePaperStatusChange: (e) => {
        setSearchPayload((prev) => {
          return {
            ...prev,
            statusType: e.category,
            statuss: e.subCategory,
            statusValue: e.subCategoryValue,
          };
        });
      },
      searchInputName: "status",
      title: "Status",
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];

  const rowData = [];

  tableList?.forEach((elem, index) => {
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span className="text-14-500 color-new-car">{elem?.authorName}</span>
        ),
      },
      {
        value: <div>{elem?.memberId}</div>,
      },
      {
        value: <div>{elem?.membershipCategory}</div>,
      },
      {
        value: (
          <span
            onClick={() => {
              handleRedirect(elem?.id);
            }}
            className="pointer color-new-car"
          >
            {elem?.paperId}
          </span>
        ),
      },
      {
        value: <span>{titleCaseString(elem?.paperTitle)}</span>,
      },
      {
        value: elem?.conference,
      },
      {
        value:
          searchPayload?.statusType === "Reviewer"
            ? getOtherStatus(elem?.submitted_status)
            : getStatus(elem?.submitted_status),
      },
      {
        value: (
          <span className="action-icon-buttons">
            <>
              <i
                className="bi bi-eye pointer color-new-car"
                onClick={() => {
                  handleRedirect(elem?.id);
                }}
              />

              <CommentAddView
                icon={<i className="bi bi-chat-right-text position-relative" />}
                elem={elem}
                handelSuccess={() => {
                  getEventAbstarcts({ ...filterData, ...searchPayload });
                }}
              />
            </>
          </span>
        ),
      },
    ];
    if (eventId) {
      obj = obj.filter((_, i) => i !== 3);
    }
    rowData.push({ data: obj });
  });
  const access = {
    isFilter: !window.location.pathname.includes("my-projects"),
  };
  return (
    <Card className="p-2 unset-br">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28 flex-wrap gap-2">
        <div className="table-title">Overall ({filterData?.total})</div>
        <div className="d-flex align-items-center flex-wrap gap-3">
          {searchPayload?.statusType && (
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span className="text-14-500 color-0000">You Selected:</span>
              <span className="text-14-400 color-new-car b-new-car rounded h-35 d-flex align-items-center ps-2 pe-2 bg-new-car-light">
                {`${searchPayload?.statusType} : ${searchPayload?.statuss}`}
              </span>
            </div>
          )}
          <ExportButton
            exportAPI={exportSubmittedAbstracts}
            payload={objectToFormData({ ...searchPayload })}
          />
          {access?.isFilter && (
            <div className="d-flex">
              <FilterDropdown
                list={membershipList}
                handelChangeFilter={handelChangeFilter}
              />
            </div>
          )}
        </div>
      </div>
      <div className="overflow-auto">
        <Table
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
          isOtherPagination
        />
      </div>
    </Card>
  );
};

export default Overall;
