import Button from "components/form/Button";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import RadioInput from "components/form/RadioInput";
import { sendRemarks } from "store/slices/accountsSlice";
import { useDispatch } from "react-redux";
import { objectToFormData } from "utils/helpers";
import { throwError, throwSuccess } from "store/slices";
import "./RemarksConfirmation.scss";

const RemarksConfirmation = ({ remark, handleSuccess, paperData }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState(null);
  const toggleDropdown = () => {
    setShow(!show);
  };

  const { user_id, abstract_payment = {}, id } = paperData || {};

  const handleSendRemarkClick = async () => {
    setIsLoading(true);
    const payload = {
      id: id,
      remarks: remark,
      send_to: userType === "users" ? user_id : abstract_payment?.accountant_id,
    };
    const response = await dispatch(sendRemarks(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
      setUserType(null);
      setShow(false);
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setIsLoading(false);
  };

  return (
    <Dropdown
      drop={"start"}
      show={show}
      onToggle={toggleDropdown}
      id="remark-confirmation-container"
    >
      <div className="d-flex">
        <Button
          text="Send To"
          btnStyle={"primary-dark"}
          className={"h-35 cps-24 cpe-24"}
          disabled={remark?.trim()?.length === 0}
          onClick={toggleDropdown}
        />
      </div>

      <Dropdown.Menu align="end" className="radio-dropdown-menu-custom">
        <div className="radio-form-container">
          <div className="text-14-400-18 color-black-olive">Select One : </div>
          <RadioInput
            label="Accounts"
            name="accounts"
            id="accounts"
            onChange={() => {
              setUserType("accounts");
            }}
            checked={userType === "accounts"}
          />
          <RadioInput
            label="Client"
            name="client"
            id="client"
            onChange={() => {
              setUserType("users");
            }}
            checked={userType === "users"}
          />
          <div className="d-flex">
            <Button
              text="Send"
              btnLoading={isLoading}
              btnStyle={"primary-dark"}
              disabled={!userType}
              className={"h-35 cps-24 cpe-24"}
              onClick={handleSendRemarkClick}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RemarksConfirmation;
