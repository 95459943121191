import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep } from "lodash";
import Button from "components/form/Button";
import CheckBox from "components/form/CheckBox";
import SeachInput from "components/form/SeachInput";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import Profile from "components/Layout/Profile";
import DeletePopup from "components/Layout/DeletePopup";
import { getDataFromLocalStorage, objectToFormData } from "utils/helpers";
import {
  deletePost,
  fetchMyResearchItems,
  // fetchResearchItems,
  setRProfileID,
  storePostList,
} from "store/slices";
import { useParams } from "react-router-dom";
import { membershipType } from "utils/constants";

const ResearchItems = ({ userID, isMyProfile }) => {
  const reduxData = useSelector((state) => state.global);
  const { postCategoryList } = reduxData || {};
  const dispatch = useDispatch();
  const param = useParams();
  const { memberType, type } = param;
  const [postId, setPostId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState("");

  const [itemList, setItemList] = useState([]);
  const [suggestList, setSuggestList] = useState([]);

  const [filterData, setFilterData] = useState({
    limit: 5,
    search: "",
    total: 0,
    offset: 0,
    filter: "",
  });
  const [checkedItems, setCheckedItems] = useState([]);

  const handelPagination = (type) => {
    let oldData = cloneDeep(filterData);
    let offset = oldData?.offset;
    if (type === "NEXT") {
      offset = offset + filterData?.limit;
    }
    if (type === "PREV") {
      offset = offset - filterData?.limit <= 0 ? 0 : offset - filterData?.limit;
    }
    let newData = { ...oldData, offset: offset };
    setFilterData(newData);
    getPost(newData);
  };

  const handelFilter = (field) => {
    const oldData = cloneDeep(filterData);
    const newData = { ...oldData, filter: field };
    setFilterData(newData);
    getPost(newData);
  };

  const handelSearch = (e) => {
    const newData = { ...filterData, search: e.target.value };
    setFilterData(newData);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      getPost(newData);
    }, 800);
    setTimer(time);
  };

  const handleCheckboxClick = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    if (currentIndex === -1) {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, id]);
    } else if (currentIndex === 0) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems?.filter((checkId) => checkId !== id)
      );
      getPost({ limit: 5, search: "", total: 0, offset: 0, filter: "" });
    } else {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems?.filter((checkId) => checkId !== id)
      );
    }
  };

  const getPost = async (object) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        formData.append(`${key}`, JSON.stringify(object[key]));
      } else {
        formData.append(key, object[key]);
      }
    });

    const response = await dispatch(fetchMyResearchItems(formData));
    setFilterData({ ...object, total: response?.data?.count || 0 });
    setItemList(response?.data?.postList || []);
    setSuggestList(response?.data?.suggest_post || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getPost(filterData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { total, limit, offset, search: searchText } = filterData;

  const totalPage = Math.ceil(total / limit);
  const activePage = Math.floor(offset / limit + 1);
  let page1 = activePage;
  let page2 = activePage + 1;
  let page3 = activePage + 2;
  if (activePage >= totalPage - 2) {
    page1 = totalPage - 2;
    page2 = totalPage - 1;
    page3 = totalPage - 0;
  }

  let loginUserType = "";
  if (getDataFromLocalStorage("id")) {
    loginUserType =
      membershipType.find((o) => o.id === getDataFromLocalStorage("user_type"))
        ?.type || "";
  }

  return (
    <div className="row">
      {postId && (
        <DeletePopup
          title="Delete Post"
          message="Are you sure you want to delete this post?"
          id={postId}
          onHide={() => {
            setPostId(null);
          }}
          handelSuccess={() => {
            const oldPostList = [...itemList].filter((o) => o.id !== postId);
            dispatch(storePostList(oldPostList));
            setPostId(null);
          }}
          handelDelete={async () => {
            let forData = objectToFormData({ post_id: postId, key: "delete" });
            const response = await dispatch(deletePost(forData));
            return response;
          }}
        />
      )}
      <div className="col-md-4">
        <Card className="cps-16 cpe-16 cpt-20 pb-1 mb-3 h-100">
          {postCategoryList?.map((elem, index) => {
            const { id, name, post_count } = elem;
            const isChecked = checkedItems?.includes(id);
            return (
              <React.Fragment key={index}>
                <div className="d-flex align-items-center gap-2 text-14-400 color-black-olive mb-3">
                  <CheckBox
                    type="PRIMARY-ACTIVE"
                    isChecked={isChecked}
                    onClick={() => {
                      handleCheckboxClick(id);
                    }}
                  />
                  <div>{`${name.replace(/\(.*\)/, "")} (${post_count})`}</div>
                </div>
              </React.Fragment>
            );
          })}

          {checkedItems?.length > 0 && (
            <div className="d-flex">
              <Button
                text="Apply"
                btnStyle="primary-dark"
                className="text-12-500 h-auto text-nowrap"
                onClick={() => {
                  handelFilter(checkedItems);
                }}
              />
            </div>
          )}
        </Card>
      </div>
      <div className="col-md-8">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="text-20-500 color-title-navy font-poppins">
            My Research Items
          </div>
        </div>
        <Card className="cps-18 cpe-18 cpt-26 cpb-20">
          <div className="row cmb-22">
            <div className="col-md-6">
              <SeachInput
                placeholder="Search Research Items"
                value={searchText}
                onChange={handelSearch}
              />
            </div>
          </div>
          {isLoading ? (
            <div className="cpt-50 cpb-50">
              <Loader size="md" />
            </div>
          ) : (
            <>
              {itemList?.length === 0 ? (
                <div className="center-flex cpt-40 cpb-50 text-15-400">
                  No Data Found
                </div>
              ) : (
                itemList?.map((elem, index) => {
                  const {
                    id,
                    title,
                    sub_category_name,
                    category_name,
                    created_date,
                    total_reads,
                    total_likes,
                    total_shares,
                    total_comments,
                    user_details,
                    co_authors,
                    author_details,
                    create_by,
                  } = elem;

                  const isDelete = getDataFromLocalStorage("id") === +create_by;
                  const {
                    name,
                    profile_photo,
                    user_type,
                    id: userIDetailD,
                  } = user_details || {};
                  const authorName = author_details?.name || name || "";
                  const authorProfie =
                    author_details?.profile_photo || profile_photo || "";
                  const authorType =
                    author_details?.user_type || user_type || "";
                  const authorID =
                    author_details?.user_id || userIDetailD || "";
                  let isAnyCoAuthor = co_authors?.some((o) => o.is_ownership);
                  const isAuthorResearchProfile =
                    ["2", "5"].includes(authorType) && isMyProfile;
                  return (
                    <div key={index} className="border rounded mb-3">
                      <div className="cps-12 cpe-12 cpt-16 cpb-22 border-bottom">
                        <div
                          className="text-14-400 color-new-car mt-1 mb-2 pointer post-t-block w-fit"
                          onClick={() => {
                            if (loginUserType) {
                              localStorage.prevRoute = window.location.pathname;
                              window.open(
                                `/${loginUserType}/network-management/network/post/post-details/${id}`,
                                "_blank"
                              );
                              // navigate(
                              //   `/${loginUserType}/network-management/network/post/post-details/${id}`
                              // );
                            } else if (memberType) {
                              window.open(
                                `/${memberType}/${type}/network/post/post-details/${id}`,
                                "_blank"
                              );
                            } else {
                              window.open("/member/login", "_blank");
                            }
                          }}
                        >
                          <u>{title}</u>
                        </div>
                        {authorName && (
                          <>
                            <div className="text-13-500 color-raisin-black mb-1">
                              Author
                            </div>
                            <div className="primary-li d-flex gap-2">
                              <span>
                                <Profile
                                  isRounded
                                  text={authorName}
                                  size="s-18"
                                  url={authorProfie}
                                  isS3UserURL
                                />
                              </span>
                              <span
                                className={`text-13-400 text-nowrap ${
                                  isAuthorResearchProfile
                                    ? "color-new-car pointer hover-effect"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (isAuthorResearchProfile) {
                                    dispatch(setRProfileID(authorID));
                                  }
                                }}
                              >
                                {authorName}
                              </span>
                            </div>
                          </>
                        )}
                        {isAnyCoAuthor && (
                          <>
                            <div className="text-13-500 color-raisin-black mb-1 mt-1">
                              Co Author
                            </div>
                            <div className="d-flex align-items-center flex-wrap text-12-400 color-black-olive gap-3">
                              {co_authors?.map((el, index) => {
                                const isReasearchProfile =
                                  ["2", "5"].includes(el?.user_type) &&
                                  isMyProfile;
                                return (
                                  <div
                                    className="primary-li d-flex gap-2"
                                    key={index}
                                  >
                                    <span>
                                      <Profile
                                        isRounded
                                        text={el?.name}
                                        size="s-18"
                                        url={el?.profile_photo}
                                        isS3UserURL
                                      />
                                    </span>
                                    <span
                                      className={`text-13-400 text-nowrap ${
                                        isReasearchProfile
                                          ? "color-new-car pointer hover-effect"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (isReasearchProfile) {
                                          dispatch(setRProfileID(el?.id));
                                        }
                                      }}
                                    >
                                      {el?.name}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                        <div className="d-flex align-items-center gap-4 mt-2">
                          <Button
                            isSquare
                            text={sub_category_name || category_name}
                            btnStyle="primary-light"
                            className="text-12-400 h-auto text-nowrap"
                          />

                          <div className="text-12-400 color-black-olive">
                            {moment(created_date, "DD-MM-YYYY hh:mm A").format(
                              "MMMM YYYY"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="cps-12 cpe-12 pt-2 pb-2 d-flex align-items-center justify-content-between">
                        <div className="text-13-500 color-raisin-black d-flex align-items-center">
                          <span>{total_reads} Reads</span>
                          <span className="vr ms-2 me-2" />
                          <span>{total_likes} Likes</span>
                          <span className="vr ms-2 me-2" />
                          <span>{total_shares} Shares</span>
                          <span className="vr ms-2 me-2" />
                          <span>{total_comments} Comments</span>
                        </div>
                        {isMyProfile && isDelete && (
                          <div className="text-13-500 color-black-olive d-flex align-items-center">
                            <span className="vr ms-2 me-2" />
                            <span
                              className="pointer"
                              onClick={() => {
                                setPostId(id);
                              }}
                            >
                              Remove
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              )}

              {itemList?.length === 0 && suggestList?.length > 0 && (
                <>
                  <div className="text-16-700 color-raisin-black mb-3">
                    Suggested Research
                  </div>
                  {suggestList?.map((elem, index) => {
                    const {
                      id,
                      title,
                      sub_category_name,
                      category_name,
                      created_date,
                      total_reads,
                      total_likes,
                      total_shares,
                      total_comments,
                      user_details,
                      co_authors,
                      author_details,
                      create_by,
                    } = elem;

                    const isDelete =
                      getDataFromLocalStorage("id") === +create_by;
                    const {
                      name,
                      profile_photo,
                      user_type,
                      id: userIDetailD,
                    } = user_details || {};
                    const authorName = author_details?.name || name || "";
                    const authorProfie =
                      author_details?.profile_photo || profile_photo || "";
                    const authorType =
                      author_details?.user_type || user_type || "";
                    const authorID =
                      author_details?.user_id || userIDetailD || "";
                    let isAnyCoAuthor = co_authors?.some((o) => o.is_ownership);
                    const isAuthorResearchProfile =
                      ["2", "5"].includes(authorType) && isMyProfile;
                    return (
                      <div key={index} className="border rounded mb-3">
                        <div className="cps-12 cpe-12 cpt-16 cpb-22 border-bottom">
                          <div
                            className="text-14-400 color-new-car mt-1 mb-2 pointer post-t-block w-fit"
                            onClick={() => {
                              if (loginUserType) {
                                localStorage.prevRoute =
                                  window.location.pathname;
                                window.open(
                                  `/${loginUserType}/network-management/network/post/post-details/${id}`,
                                  "_blank"
                                );
                                // navigate(
                                //   `/${loginUserType}/network-management/network/post/post-details/${id}`
                                // );
                              } else if (memberType) {
                                window.open(
                                  `/${memberType}/${type}/network/post/post-details/${id}`,
                                  "_blank"
                                );
                              } else {
                                window.open("/member/login", "_blank");
                              }
                            }}
                          >
                            <u>{title}</u>
                          </div>
                          {authorName && (
                            <>
                              <div className="text-13-500 color-raisin-black mb-1">
                                Author
                              </div>
                              <div className="primary-li d-flex gap-2">
                                <span>
                                  <Profile
                                    isRounded
                                    text={authorName}
                                    size="s-18"
                                    url={authorProfie}
                                    isS3UserURL
                                  />
                                </span>
                                <span
                                  className={`text-13-400 text-nowrap ${
                                    isAuthorResearchProfile
                                      ? "color-new-car pointer hover-effect"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (isAuthorResearchProfile) {
                                      dispatch(setRProfileID(authorID));
                                    }
                                  }}
                                >
                                  {authorName}
                                </span>
                              </div>
                            </>
                          )}
                          {isAnyCoAuthor && (
                            <>
                              <div className="text-13-500 color-raisin-black mb-1 mt-1">
                                Co Author
                              </div>
                              <div className="d-flex align-items-center flex-wrap text-12-400 color-black-olive gap-3">
                                {co_authors?.map((el, index) => {
                                  const isReasearchProfile =
                                    ["2", "5"].includes(el?.user_type) &&
                                    isMyProfile;
                                  return (
                                    <div
                                      className="primary-li d-flex gap-2"
                                      key={index}
                                    >
                                      <span>
                                        <Profile
                                          isRounded
                                          text={el?.name}
                                          size="s-18"
                                          url={el?.profile_photo}
                                          isS3UserURL
                                        />
                                      </span>
                                      <span
                                        className={`text-13-400 text-nowrap ${
                                          isReasearchProfile
                                            ? "color-new-car pointer hover-effect"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          if (isReasearchProfile) {
                                            dispatch(setRProfileID(el?.id));
                                          }
                                        }}
                                      >
                                        {el?.name}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          <div className="d-flex align-items-center gap-4 mt-2">
                            <Button
                              isSquare
                              text={sub_category_name || category_name}
                              btnStyle="primary-light"
                              className="text-12-400 h-auto text-nowrap"
                            />

                            <div className="text-12-400 color-black-olive">
                              {moment(
                                created_date,
                                "DD-MM-YYYY hh:mm A"
                              ).format("MMMM YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="cps-12 cpe-12 pt-2 pb-2 d-flex align-items-center justify-content-between">
                          <div className="text-13-500 color-raisin-black d-flex align-items-center">
                            <span>{total_reads} Reads</span>
                            <span className="vr ms-2 me-2" />
                            <span>{total_likes} Likes</span>
                            <span className="vr ms-2 me-2" />
                            <span>{total_shares} Shares</span>
                            <span className="vr ms-2 me-2" />
                            <span>{total_comments} Comments</span>
                          </div>
                          {isMyProfile && isDelete && (
                            <div className="text-13-500 color-black-olive d-flex align-items-center">
                              <span className="vr ms-2 me-2" />
                              <span
                                className="pointer"
                                onClick={() => {
                                  setPostId(id);
                                }}
                              >
                                Remove
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
          {/* pagination code */}
          {!isLoading && total > 5 && (
            <div className="d-flex align-items-center justify-content-center pt-3 text-14-400 color-black-olive">
              <span
                className={`ms-3 me-3 d-flex ${
                  activePage === 1 ? "" : "color-new-car pointer"
                }`}
                onClick={() => {
                  if (activePage !== 1) {
                    handelPagination("PREV");
                  }
                }}
              >
                <i className="bi bi-chevron-left d-flex" />
              </span>
              <span
                className={`me-3 ${
                  activePage === 1 ? "" : "color-new-car pointer"
                }`}
                onClick={() => {
                  if (activePage !== 1) {
                    handelPagination("PREV");
                  }
                }}
              >
                Prev
              </span>
              {activePage > 1 && totalPage > 3 && (
                <>
                  <span className="ms-3 me-3">1</span>
                  <span className="ms-3 me-3">.....</span>
                </>
              )}
              <span
                className={`ms-3 me-3 ${
                  page1 === activePage ? "color-new-car" : ""
                }`}
              >
                {page1}
              </span>
              <span
                className={`ms-3 me-3 ${
                  page2 === activePage ? "color-new-car" : ""
                }`}
              >
                {page2}
              </span>
              <span
                className={`ms-3 me-3 ${
                  page3 === activePage ? "color-new-car" : ""
                }`}
              >
                {page3}
              </span>
              {totalPage >= 5 && page3 !== totalPage && (
                <>
                  <span className="ms-3 me-3">.....</span>
                  <span className="ms-3 me-3">{totalPage}</span>
                </>
              )}

              <span
                className={`ms-3 ${
                  activePage === totalPage ? "" : "color-new-car pointer"
                }`}
                onClick={() => {
                  if (activePage !== totalPage) {
                    handelPagination("NEXT");
                  }
                }}
              >
                Next
              </span>
              <span
                className={`ms-3 me-3 d-flex ${
                  activePage === totalPage ? "" : "color-new-car pointer"
                }`}
                onClick={() => {
                  if (activePage !== totalPage) {
                    handelPagination("NEXT");
                  }
                }}
              >
                <i className="bi bi-chevron-right d-flex" />
              </span>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
export default ResearchItems;
