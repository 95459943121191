import Button from "components/form/Button";
import Modal from "components/Layout/Modal";
import download from "downloadjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { certificatePath, icons } from "utils/constants";
import {
  bytesToBase64,
  downloadFile,
  generatePreSignedUrl,
  getCertificateData,
  getCertificatePdf,
  urlToUnitArray,
} from "utils/helpers";

const UserAttendanceState = ({ data, paperData, handleSuccess }) => {
  const dispatch = useDispatch();
  const [viewSample, setViewSample] = useState("");
  const [s3URL, setS3URL] = useState("");
  const { status } = data;

  const {
    attendanceStatus,
    registration_pass,
    pass_scanning_date,
    isCertificate,
  } = paperData || {};
  const { img } = isCertificate || {};
  const urlType = img?.split(".")?.pop();

  const isActiveState = status >= 5 && registration_pass;
  const isAttendanceStepIsDone = attendanceStatus === 1 && status === 6;

  const handleDownload = async (data) => {
    let certificateData = getCertificateData(data?.filed_data, {
      certificate_number: data?.certificate_number || "",
      paper_title: data?.paper_title || "",
      create_at: data?.create_at || "",
    });

    const pdfFileData = await urlToUnitArray(s3URL);

    const pdfBytes = await getCertificatePdf(pdfFileData, certificateData);
    download(pdfBytes, data?.img, "application/pdf");
  };

  const handleView = async (data) => {
    let certificateData = getCertificateData(data?.filed_data, {
      certificate_number: data?.certificate_number || "",
      paper_title: data?.paper_title || "",
      create_at: data?.create_at || "",
    });
    const pdfFileData = await urlToUnitArray(s3URL);
    const pdfBytes = await getCertificatePdf(pdfFileData, certificateData);
    var b64 = bytesToBase64(pdfBytes);
    setViewSample(`data:application/pdf;base64,${b64}`);
  };

  const fetchS3URL = async (data) => {
    let returnURL = "";
    const response = await generatePreSignedUrl(data?.img, certificatePath);
    returnURL = response;

    setS3URL(returnURL);
  };

  useEffect(() => {
    if (paperData?.isCertificate) {
      fetchS3URL(paperData?.isCertificate);
    }
  }, [paperData?.isCertificate]);

  return (
    <>
      {viewSample && (
        <Modal
          fullscreen
          largeClose
          onHide={() => {
            setViewSample("");
          }}
        >
          <iframe
            frameBorder="0"
            className="w-100"
            src={`${viewSample}#toolbar=0&navpanes=0`}
            title="description"
            style={{
              width: "100%",
              height: "99%",
            }}
          />
        </Modal>
      )}
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${isActiveState ? "active" : "not-active"}`}
        >
          {isAttendanceStepIsDone ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "6"
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveState ? "color-new-car" : "color-6866"
            }`}
          >
            Attendance{" "}
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-content-start ${
          isActiveState ? "" : "d-none"
        }`}
      >
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cmt-16 follow-up-details-block">
          <div className="d-flex justify-content-start align-items-center">
            <div className="follow-step-block">
              <span className="inner-block"></span>
            </div>
            <div>
              <div
                style={{ maxHeight: "20px" }}
                className={`${
                  true ? "color-new-car" : "color-6866"
                } text-16-500-20`}
              >
                Conference Attendance
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div className="follow-up-sparator-block">
              <span className="hr-line" />
            </div>
            <div className="cmb-19">
              {+attendanceStatus === 0 && (
                <>
                  <div className="cps-20 cpe-20 cpt-8 cpb-8 cmt-14 bg-f4fc color-E751 br-40 text-12-400-15">
                    Pending
                  </div>
                </>
              )}
              {+attendanceStatus === 1 && (
                <>
                  <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
                    {pass_scanning_date &&
                      moment(pass_scanning_date).format(
                        "DD-MMMM-YYYY"
                      )}
                  </div>
                  <div className="cps-20 cpe-20 text-center cpt-8 cpb-8 bg-effed color-a35b br-40 text-12-400-15">
                    Present
                  </div>
                </>
              )}
              {+attendanceStatus === 2 && (
                <>
                  <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
                    {pass_scanning_date &&
                      moment(pass_scanning_date).format(
                        "DD-MMMM-YYYY"
                      )}
                  </div>
                  <div className="cps-20 cpe-20 cpt-8 cpb-8 bg-f4fc color-E751 br-40 text-12-400-15">
                    Absent
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-center">
            <div className="follow-step-block">
              <span className="inner-block"></span>
            </div>
            <div>
              <div
                style={{ maxHeight: "20px" }}
                className={`${
                  +attendanceStatus === 1 ? "color-new-car" : "color-6866"
                } text-16-500-20`}
              >
                Certificate
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start ">
            <div className="follow-up-sparator-block">
              <span className="hr-line" />
            </div>
            <div className="cmb-19">
              <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
                {pass_scanning_date &&
                  moment(pass_scanning_date).format(
                    "DD-MMMM-YYYY"
                  )}
              </div>
              {isCertificate && (
                <div className="d-flex align-items-center gap-3">
                  <Button
                    text="Download Certificate"
                    btnStyle="primary-dark"
                    className="cps-20 cpe-20 gap-2 h-35 text-12-400-15"
                    onClick={() => {
                      if (urlType === "pdf") {
                        handleDownload(isCertificate);
                      } else {
                        dispatch(downloadFile(isCertificate?.img));
                      }
                    }}
                  />
                  <Button
                    text="View Certificate"
                    btnStyle="primary-outline"
                    className="cps-20 cpe-20 gap-2 h-35 text-12-400-15"
                    onClick={() => {
                      handleView(isCertificate);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAttendanceState;
