import React from "react";
import Modal from "../Modal";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/form/TextInput";
import {
  convertString,
  numberOnlyFromInput,
  titleCaseString,
} from "utils/helpers";
import Location from "components/form/Location";
import Button from "components/form/Button";
import { isEqual } from "lodash";
import { errorMsgForMobile } from "utils/constants";
import { useDispatch } from "react-redux";
import { throwError } from "store/slices";

const AddCoAuthorPopup = ({
  onHide,
  setCoAuthorData,
  id,
  existingList,
  existingAuthor,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    coAuthors: [
      {
        name: "",
        country: "",
        email_id: "",
        whatsapp_number: "",
        country_code: "IN",
        mobile_no_length: 10,
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    coAuthors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required."),
        email_id: Yup.string()
          .required("Email is required.")
          .email("Email must be a valid email"),
        whatsapp_number: Yup.lazy((_, obj) => {
          const { country_code, mobile_no_length } = obj?.parent || {};
          if (country_code) {
            return Yup.string()
              .required("Phone number is required.")
              .min(mobile_no_length, errorMsgForMobile(mobile_no_length)?.min)
              .max(mobile_no_length, errorMsgForMobile(mobile_no_length)?.max);
          } else {
            return Yup.string();
          }
        }),
        country: Yup.string().required("Country is required."),
      })
    ),
  });

  const handleAddCoAuthor = (data) => {
    const duplicateEmails = data?.coAuthors?.filter(
      (coAuthor) =>
        coAuthor?.email_id === existingAuthor?.email ||
        existingList?.some((existing) => existing?.email === coAuthor?.email_id)
    );

    if (duplicateEmails?.length > 0) {
      const isExistingAuthor =
        duplicateEmails[0]?.email_id === existingAuthor?.email;
      dispatch(
        throwError({
          message: `Co-author with email ${
            duplicateEmails[0]?.email_id
          } is already ${isExistingAuthor ? "the Author" : "selected."}`,
        })
      );
      return;
    }
    setCoAuthorData({ target: { id: id, value: data?.coAuthors } });
    // onHide();
  };
  return (
    <Modal
      titleBesideClose="Add Co-Authors"
      onHide={onHide}
      isTitleBorder
      isTitleVerticalCenter
    >
      <div className="cps-25 cpe-25 cpt-30 cpb-30">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddCoAuthor}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
          }) => (
            <form>
              <FieldArray
                name="coAuthors"
                render={(arrayHelpers) => (
                  <div>
                    {values?.coAuthors?.map((coAuth, index) => (
                      <div className="row cmb-25" key={index}>
                        <div className="col-md-6 cmb-25">
                          <TextInput
                            label={`Co-Author ${index + 1}*`}
                            placeholder="Name"
                            id={`coAuthors.${index}.name`}
                            value={coAuth?.name}
                            error={errors?.coAuthors?.[index]?.name}
                            onChange={(e) => {
                              setFieldValue(
                                `coAuthors.${index}.name`,
                                titleCaseString(e.target.value)
                              );
                            }}
                          />
                        </div>

                        <div className="col-md-6 cmb-25">
                          <Location
                            type="country"
                            data={{
                              label: "Country*",
                              id: `coAuthors.${index}.country`,
                              placeholder: "Country*",
                              value: coAuth?.country,
                              error: errors?.coAuthors?.[index]?.country,
                              onChange: (e) => {
                                setFieldValue(
                                  `coAuthors.${index}.country`,
                                  e?.target?.data?.country
                                );
                                handleChange(e);
                              },
                            }}
                          />
                        </div>

                        <div className="col-md-6 cmb-25">
                          <TextInput
                            label="Email*"
                            placeholder="Email"
                            id={`coAuthors.${index}.email_id`}
                            value={coAuth?.email_id}
                            error={errors?.coAuthors?.[index]?.email_id}
                            onChange={(e) => {
                              setFieldValue(
                                `coAuthors.${index}.email_id`,
                                convertString(1, e.target.value)
                              );
                            }}
                          />
                        </div>

                        <div className="col-md-6 cmb-25">
                          <TextInput
                            isPhoneNumber
                            label="Phone Number*"
                            placeholder="Phone Number"
                            id={`coAuthors.${index}.whatsapp_number`}
                            value={coAuth?.whatsapp_number}
                            error={errors?.coAuthors?.[index]?.whatsapp_number}
                            phoneNumberData={{
                              id: `coAuthors.${index}.country_code`,
                              value: coAuth?.country_code,
                            }}
                            onChange={(e) => {
                              if (
                                e.target.id ===
                                `coAuthors.${index}.whatsapp_number`
                              ) {
                                const cleanedEvent = numberOnlyFromInput(e);
                                setFieldValue(
                                  `coAuthors.${index}.whatsapp_number`,
                                  cleanedEvent?.target.value
                                );
                              } else {
                                setFieldValue(
                                  `coAuthors.${index}.whatsapp_number`,
                                  ""
                                );
                                setFieldValue(
                                  `coAuthors.${index}.mobile_no_length`,
                                  e.target.length
                                );
                                setFieldValue(
                                  `coAuthors.${index}.country_code`,
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>

                        <div className={`d-flex justify-content-end gap-3`}>
                          {index === values?.coAuthors?.length - 1 && (
                            <Button
                              text="+ Add Co-Author"
                              btnStyle="primary-outline"
                              className="h-35 text-14-500"
                              onClick={() =>
                                arrayHelpers.push({
                                  name: "",
                                  country: "",
                                  email_id: "",
                                  whatsapp_number: "",
                                  country_code: "IN",
                                  mobile_no_length: 10,
                                })
                              }
                            />
                          )}
                          {values?.coAuthors?.length > 1 && (
                            <Button
                              btnStyle="danger-outline"
                              icon={<i className="bi bi-trash" />}
                              className="cpt-5 cpb-5 cps-10 cpe-10 h-35"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="d-flex justify-content-center gap-4">
                      <Button
                        text="Cancel"
                        btnStyle="light-outline"
                        className="cps-30 cpe-30"
                        onClick={resetForm}
                      />
                      <Button
                        text={"Invite"}
                        btnStyle="primary-dark"
                        className="cps-30 cpe-30"
                        onClick={handleSubmit}
                        disabled={isEqual(initialValues, values)}
                      />
                    </div>
                  </div>
                )}
              />
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddCoAuthorPopup;
